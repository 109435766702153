import React, { Component, useState } from "react";
import ScrantonStrangler from "./assets/ScrantonStrangler.gif";
import ChocolateFactory from "./assets/ChocolateFactory.png";
import GoldenTicket from "./assets/GoldenTicket.gif";
import ShortLivedBranches from "./assets/ShortLivedBranches.gif";
import Ew from "./assets/Ew.gif";
export default function MicroservicesChocolateFactory() {
  return (
    <div>
      <article id="f0aa6323-619f-4a5b-bc4d-3ce0a0779252" className="page sans">
        <div className="page-body">
          <p id="a7c14b81-a825-4d75-9981-1c1f416ceaa9" className="">
            A sweet (pun intended) article about what I learned from the book
            "Monolith to Microservices" regarding cohesion, coupling, and
            refactoring methods, demonstrated with real-life examples from a
            chocolate factory.
          </p>
          <p id="99943dd3-a9a0-44f3-9e41-77f7659842c5" className=""></p>
          <h4 id="56a1639b-5ee5-4315-b050-84755dc8ed9b" className="">
            <strong>
              <strong>
                Prologue - How Did I Get the Idea for This Article?
              </strong>
            </strong>
          </h4>
          <p id="a58afcc3-14c3-4ff8-82ca-2d5470b9eaed" className="">
            In 2021, I read the book "
            <a href="https://samnewman.io/books/monolith-to-microservices/">
              Monolith to Microservices
            </a>
            " by Sam Newman (2019). The book explains how to transition from{" "}
            <strong>monolithic systems to microservices architecture.</strong> I
            read it because, in my team back then, we worked on a huge monolith,
            and I had a task to write the first new microservice. I had only
            heard the buzzword "Microservice" before but never really understood
            its real significance until I read the book.
          </p>
          <p id="3260dec7-5237-4e24-967d-894e405cb13b" className="">
            Since then, I've had the idea of passing on the insights I gained
            from reading this (245 pages long!) book, clearly and easily.
          </p>
          <p id="00310ed4-52e3-4d09-afbe-5b3462e57deb" className="">
            I wanted to allegorize this architectural problem with{" "}
            <strong>real-life examples</strong>. After all, software engineering
            is just like other engineering industries, except for the fact that
            the solutions we build are so easy to manufacture and deliver.
          </p>
          <p id="bcce1ff5-4a80-4e99-bae9-003152e7901d" className="">
            The problem was that the internet is full of examples of how
            Microservices architecture is just like a{" "}
            <strong>modern car factory</strong> and how high coupling is just
            like how a <strong>submarine </strong>is built, and well… As a
            stereotypical girl, this couldn’t be further from my world of
            associations!
          </p>
          <p id="6482aa97-90fe-445b-8789-2f0397183ba6" className="">
            So I realized it could be demonstrated with something much more
            appealing - a Chocolate Factory!, inspired by one of my favorite
            movies ever - Charlie and the Chocolate Factory (The 2005 version
            with Johnny Depp, obviously).
          </p>
          <img
            src={GoldenTicket}
            className="modal-pic"
            style={{ width: "100%", marginBottom: 30 }}
          />
          <p id="12cce6a2-bf5c-4d7f-9a3a-206e088de1ae" className=""></p>
          <h4 id="99e327f0-54e8-4d97-a316-534a89450bd0" className="">
            <strong>
              <strong>Willy Wonka and the Chocolate Monolith</strong>
            </strong>
          </h4>
          <p id="a1c93020-6f4f-4a67-8cfc-90d65ecbc4db" className="">
            When Willy Wonka just founded his Chocolate initiative, he did all
            the work himself. He bought the chocolate, made the candy bars,
            crafted the coffee toffee candies, wrapped them, and probably also
            took care of packing and selling.
          </p>
          <p id="737d0b30-1f5f-483a-af16-68a57a3e465d" className="">
            It all worked for him, and the sales were nice, so the initiative
            turned into a little chocolate shop with a few employees. Not a long
            time passed before Willy Wonka's business became a successful
            company, so he decided to open his factory.
          </p>
          <p id="f1254a8e-c2c0-4aa5-a1f2-aef3eaa13037" className="">
            However, as time passed, some things started to squeak. Wonka
            noticed that the<strong> time to market </strong>is longer than
            expected, that there are <strong>bottlenecks </strong>in the
            process, and don’t get me started on those lazy Oompa-Loompas. In
            addition, it’s not as easy as it was to{" "}
            <strong>sandbox new candy technologies</strong>, for example,
            exchanging the cocoa beans manufacturer or composing a new gum that
            tastes like a whole meal (I’m still craving this one!).
          </p>
          <p id="8dd3b130-3c22-4096-b321-425d371067e0" className="">
            So Wonka decides to make a change, break the Monolith, and
            transition his factory to a{" "}
            <strong>Microservices architecture.</strong>
          </p>
          <p id="077f2bde-23e0-485a-88a5-0edbb103e577" className="">
            Wisely, Wonka did not choose this architecture right from the
            beginning. When launching a new start-up, you often{" "}
            <strong>don’t know yet the domains</strong> and the different
            processes that will drive your business; therefore, it’s better to
            wait a bit <strong>until you’re done pivoting</strong> and the
            product structure is stabilized.
          </p>
          <p id="955157e1-8359-4624-8612-b469d2b6454a" className=""></p>
          <h4 id="90037504-2b75-46c6-93ed-de5f5ceec7ca" className="">
            <strong>
              <strong>Let’s Move to Code</strong>
            </strong>
          </h4>
          <p id="1a82e3f4-0f14-4c38-a67e-03425cb11874" className="">
            Assuming our factory is virtual, it probably looks something like
            this, with a few threads running independently.
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="26930a5e-c9f7-4cf5-88c8-a22332f29d21" className="code">
            <code className="language-C#">
              class ChocolateStore {"{"}
              {"\n"}
              {"\t"}void RunStore() {"{"}
              {"\n"}
              {"\t"}
              {"  "}while(true) {"{"}
              {"\n"}
              {"\t"}
              {"    "}// Take care of orders{"\n"}
              {"\t"}
              {"      "}var order = pullOrder();{"\n"}
              {"\t"}
              {"\t"}
              {"  "}var chocolateBars =
              chocolateBarsStorage.Read(order.barsCount);
              {"\n"}
              {"\t"}
              {"\t"}
              {"  "}var toffees = toffeeStorage.Read(order.toffeeCount);{"\n"}
              {"\t"}
              {"\t"}
              {"  "}Ship(order.address, chocolateBars, toffees){"\n"}
              {"\t"}
              {"  "}
              {"}"}
              {"\n"}
              {"\t"}
              {"}"}
              {"\n"}
              {"}"}
              {"\n"}
              {"\n"}class ProduceChocolateBars {"{"}
              {"\n"}
              {"\t"}void ProduceBars() {"{"}
              {"\n"}
              {"\t"}
              {"  "}while(true) {"{"}
              {"\n"}
              {"\t"}
              {"    "}var order = pullOrder();{"\n"}
              {"\t"}
              {"\t"}
              {"  "}var chocolate = chocolateStorage.Read(5);{"\n"}
              {"\t"}
              {"\t"}
              {"  "}var chocolateBars = new ChocolateBars(chocolate);{"\n"}
              {"\t"}
              {"\t"}
              {"  "}chocolateBarsStorage.Save(chocolateBars);{"\n"}
              {"\t"}
              {"  "}
              {"}"}
              {"\n"}
              {"\t"}
              {"}"}
              {"\n"}
              {"}"}
            </code>
          </pre>
          <p id="1a099bb4-6f39-428f-9ba7-4a26af5b4b3f" className="">
            We can identify a few problems here:
          </p>
          <ul
            id="4f00e85a-3f55-44e4-84a1-3becf229ab15"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              If we wanted to try a new cocoa beans provider or a roasting
              technique, this would be almost impossible, as every{" "}
              <code>CandyProducer</code> is reaching directly to the chocolate
              storage. Meaning, there’s a <strong>high coupling</strong> between
              the production and the storage.
            </li>
          </ul>
          <ul
            id="391b7d54-d417-4849-bfb0-f856658d874d"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              If the <code>chocolateBarsStorage</code> has high traffic all of a
              sudden, it will slow down the entire order cycle. Meaning,{" "}
              <strong>it’s not scalable</strong>.
            </li>
          </ul>

          <img src={Ew} className="modal-pic" style={{ marginBottom: 30 }} />
          <p id="70e50698-4bdd-4e8a-89bf-a4334eab7317" className=""></p>
          <h4 id="17bb0390-d284-4dd6-a271-8be901a4f9bd" className="">
            <strong>
              <strong>Planning the Factory Refactor</strong>
            </strong>
          </h4>
          <p id="2749938d-76d8-477e-a56d-6c46866da8b4" className="">
            The first step after deciding to split a monolith is to decide on a{" "}
            <strong>metric</strong>, a number we will use to prove that the
            transition worked and was worth the effort.
          </p>
          <p id="c9973e7b-6f64-472e-a048-089e35756334" className="">
            In Wonka’s case, it can be the factory’s{" "}
            <strong>annual revenues</strong>, the amount of new candies invented
            per year, or even employee satisfaction grade. The important thing
            is to <strong>focus </strong>on the metric and the goal we would
            love to improve the most.
          </p>
          <p id="cb3ab5a4-94b1-4c43-8bac-7825fddfe2c8" className="">
            The second step is to start and untie the knots and understand the
            different domains of the business. Each such domain will move to be
            a new department in the factory (or a new microservice in our case).
          </p>
          <p id="885246a1-c6c6-4436-82de-fe8687515b1c" className="">
            Eventually, we want to hold a map of all the business domains and
            the relations between them. To achieve that, we can use something
            the book calls an “<strong>Event Storm</strong>”, meaning, listing
            all “events” we can think of that happen within our business.
          </p>
          <p id="a51ef4e2-4ec0-43e8-a82f-b302da25973f" className="">
            For example:
          </p>
          <ul
            id="667a3c81-c430-4756-b0cd-58e2beff22fd"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              An order is made from a store
            </li>
          </ul>
          <ul
            id="4a304558-b2a9-436f-ac32-c1cc7b6ce309"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              A chocolate bar is finished wrapping
            </li>
          </ul>
          <ul
            id="65926f04-98f4-4586-ad61-78834bdb8c85"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              A truck is shipped to a store
            </li>
          </ul>
          <p id="7ef59e1e-8b35-4790-99bb-fa65a2c9083e" className=""></p>
          <p id="e1d6626f-279b-4f90-9753-161a158fce37" className="">
            After we have that list, we can start and draw our map. In our case,
            it should look roughly like this:
          </p>

          <img
            src={ChocolateFactory}
            className="modal-pic"
            style={{ width: "100%", marginBottom: 30 }}
          />
          <p id="a24cc19b-a8b7-4d2b-ae94-da2a6da3fd69" className="">
            Each such circle is a different domain, that can potentially become
            a separate service.
          </p>
          <p id="96c92f1e-83c1-408f-9110-36765751f56d" className="">
            However, just because there are different domains,{" "}
            <strong>doesn’t mean everything should be separated </strong>
            immediately—quite the contrary. We would like to gradually change
            the structure to examine the change and improve as we go,
            refactoring one piece at a time.
          </p>
          <p id="0749cdc3-f0ab-47fc-a511-5f589f878f1b" className="">
            At each step, we will assess which part will give us the{" "}
            <strong>best ROI </strong>for transitioning it into a service. that
            would be a combination of 2 factors: A low amount of incoming edges,
            which reflects that <strong>no other part relies on them</strong>{" "}
            (like in the Inventing Room), and a high frequency of changes or
            issues, or
            <strong> high motivation</strong> for a logic replacement. For
            example, if we know that we want to{" "}
            <strong>change the way we take orders</strong>, the{" "}
            <strong>Order Management service </strong>can be a good fit.
          </p>
          <p id="6fa9f1cb-89fa-4708-8b70-ee19c40b99bb" className=""></p>
          <h4 id="f2dcd5b0-738b-4e01-b47c-01214dd81545" className="">
            Migration Methods
          </h4>
          <p id="a9c93e76-21f7-44c2-a789-0897ed1e3b05" className="">
            Now that we decided on the first service we are going to split, we
            need to decide on our strategy. There are a few refactoring methods
            that are useful in our case.
          </p>
          <p id="ab96b2d5-992f-4ab8-9f34-53a16c529e0d" className="">
            <strong>Strangler Fig</strong>
          </p>
          <p id="187b0bf0-66f7-49ea-ad6c-d2e503eddcaf" className="">
            According to this method, we will write a new piece of code, and the
            old and new code will live side by side for a while, as the new code
            will start to handle more and more of the traffic as time passes,
            allowing us to <strong>gradually deploy</strong> our change.
          </p>
          <p id="529787d8-3504-4b45-9af4-416123f6bb58" className="">
            <span style={{ borderBottom: "0.05em solid" }}>How it’s done?</span>
          </p>
          <p id="fe121b27-4a8a-459c-a7e6-1817e09cc06f" className="">
            First of all, we will create a new service, in this case for our
            Order Management. We can even{" "}
            <strong>deploy the new service right away </strong>- we don’t need
            to implement anything yet because no one is aware of this service!
          </p>
          <p id="34e4a414-d493-4d8b-bcf8-a55a4a2e97e3" className="">
            Now we can continue to identify the service’s interface, just
            returning <code>501 NOT IMPLEMENTED</code> on each endpoint. In our
            service, we only have one endpoint in our interface: Place order.
          </p>
          <p id="924bf7a0-3b8e-482d-bce7-0829d92f26de" className="">
            As you notice, it’s all about <strong>short-lived branches</strong>{" "}
            in this method, which I like (will elaborate more in the end).
          </p>
          <p id="2dd86340-3b4a-430c-9a5e-7091e1822994" className="">
            After we implement the logic of placing orders, we can start
            referring our customers to use the new ordering service instead of
            the old one. To utilize that we will use a <strong>Proxy</strong>,
            which upon an environment variable/feature flag will refer all calls
            to the old service, to the new one.
          </p>
          <p id="3a4fd36a-5901-4d9d-b2b3-112777d874da" className="">
            Here's an example of an Nginx proxy configuration we can use:
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="2ad88e3e-c5c2-45d0-9073-f269d92e9492" className="code">
            <code className="language-C#">
              location /order-management/ {"{"}
              {"\n"}
              {"    "}proxy_pass http://order-management/orders/;{"\n"}
              {"}"}
            </code>
          </pre>
          <p id="0b7044d2-ac7c-40c6-9c71-d2c53e5f75c3" className=""></p>
          <p id="eb671733-f491-43e0-ba0e-36fbbb6464a9" className="">
            <strong>Bonus fact:</strong> The name Strangler Fig comes from the
            Strangler Fig plant, which wraps around a tree, and strangles trees
            slowly until it kills their hosts 😲. Sounds like material for a
            horror movie.
          </p>
          <img
            src={ScrantonStrangler}
            className="modal-pic"
            style={{ marginBottom: 30 }}
          />
          <p id="7be95403-c8b4-49fd-9093-5f08ae80f435" className="">
            <strong>Branch by abstraction</strong>
          </p>
          <p id="86679226-0474-475c-86e4-7e609a65682e" className="">
            This method is adjusted to cases where the monolith is dependent on
            the service we are transitioning, which is the case we will face
            most of the time.
          </p>
          <p id="94b8e56f-b5d9-4874-9e8a-50619034b7a8" className="">
            Let’s take for example the{" "}
            <strong>Chocolate creation service</strong>.
          </p>
          <p id="feea67e1-6f1d-4b3b-ab94-1252e80af23d" className="">
            Currently, in our code, whenever some process needs chocolate, they
            just go ahead and take chocolate from the storage.
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="10ef02a9-61c0-4ed4-ac40-b8a1b2ad8523" className="code">
            <code className="language-C#">
              var chocolateBars = chocolateBarsStorage.Read(5);
            </code>
          </pre>
          <p id="ee22cc3e-a26a-4dae-89c4-587f6a260d7a" className="">
            This makes the task of building a “Chocolate creation” service seem
            almost impossible.
          </p>
          <p id="30c79c4f-d53f-4d74-9c87-5d3ca1612d22" className="">
            So we’ll start by <strong>identifying all of the consumers </strong>
            of chocolate, and{" "}
            <strong>replacing them with an abstraction</strong>.
          </p>
          <p id="4cd5c196-19e9-465e-b3d3-22e2c14e0559" className="">
            By an abstraction I mean, an interface that will give us the same
            results, but the consumers won’t be aware of its inner
            implementation. For example:
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="e052cd69-771c-4445-a3e2-55181455a8cd" className="code">
            <code className="language-C#">
              interface IChocolateProvider {"{"}
              {"\n"}
              {"   "}List&lt;Chocolate&gt; GetChocolate(int amount);{"\n"}
              {"}"}
            </code>
          </pre>
          <p id="38a20165-8160-4bff-b990-997a5e8d5339" className="">
            Now we can implement the abstraction. One implementation will act
            exactly like the code is acting now, and the other one will refer to
            our new logic, which is <strong>not implemented yet.</strong>
          </p>
          <p id="8ccd9612-6dc7-4636-8bf4-aa728fdb0b30" className="">
            We will also use a factory method (oh boy, too many factories today)
            to return us the correct implementation.
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="8bac2134-95b1-4bd8-9bbe-66f71070a47a" className="code">
            <code className="language-C#">
              {"\n"}class OldChocolateProvider: IChocolateProvider {"{"}
              {"\n"}
              {"    "}List&lt;Chocolate&gt; GetChocolate(int amount) {"{"}
              {"\n"}
              {"         "}return chocolateBarsStorage.Read(amount);{"\n"}
              {"    "}
              {"}"}
              {"\n"}
              {"}"}
              {"\n"}
              {"\n"}class NewChocolateProvider: IChocolateProvider {"{"}
              {"\n"}
              {"\t"}List&lt;Chocolate&gt; GetChocolate(int amount) {"{"}
              {"\n"}
              {"        "}throw new NotImplementedError();{"\n"}
              {"    "}
              {"}"}
              {"\n"}
              {"}"}
              {"\n"}
              {"\n"}// Factory:{"\n"}IChocolateProvider GetChocolateProvider(){" "}
              {"{"}
              {"\n"}
              {"    "}return new OldChocolateProvider(){"\n"}
              {"}"}
            </code>
          </pre>
          <p id="e08f20be-540f-4837-8e33-fa8a1cac1cbe" className="">
            The third part will be to replace all usages of chocolate, to use
            the abstraction.
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="25f8384a-32d1-43e2-9b98-bb0686a4273b" className="code">
            <code className="language-C#">
              var chocolateProvider = GetChocolateProvider(){"\n"}var
              chocolateBars = chocolateProvider.GetChocolate(5);
            </code>
          </pre>
          <p id="6b94d1e9-9cce-4ae0-9c6a-e6aca51db828" className=""></p>
          <p id="5f24c664-6862-4de7-b872-44204e9caf32" className="">
            This is the hardest part of this refactoring method, and now that
            the code is well-refactored, we are free to start implementing our
            new service, keeping again on short-lived branches.
          </p>
          <p id="a3dafeef-3e1e-4ec5-bc01-8c433c1873b5" className="">
            Only when we are done implementing our service, we can start
            referring calls to our new implementation, changing only one place -
            the <code>GetChocolateProvider</code>! We can make it rely on a
            feature flag, a configuration, etc.
          </p>
          <p id="ec0a946b-aaf8-4246-b0df-2c8e36ac30b9" className="">
            And of course, don’t forget to remove the old implementation once
            you are done with the process, to keep your code nice and clean.
          </p>
          <p id="cd4f9f90-225e-410b-86c6-eea4f6ae01c1" className=""></p>
          <h4 id="6cabf123-0174-4390-8e82-464c14287e19" className="">
            A word on short-lived branches
          </h4>
          <p id="f9569d90-b7e1-4768-83cd-c53955dc5e29" className="">
            It’s tempting to develop a feature in a huge branch and merge it
            only when the feature is done. But it has multiple advantages for
            keeping your branches small, even at the price of having TODOs and
            TBDs in your codebase.
          </p>
          <p id="6cbb7b20-f7a0-46ca-8168-e7c21b7ae3a3" className="">
            First, it’s helping with <strong>code reviews</strong>. It’s very
            intuitive that the smaller the change, the easier it is to review.
            Secondly, it reduces the{" "}
            <strong>chances of huge merge conflicts,</strong> in case you are
            working in an existing code base. Lastly, you get to receive{" "}
            <strong>early feedback,</strong> both from reviewers and from CI,
            tests, and automation, allowing you to improve along the way and get
            the best result eventually.
          </p>
          <img
            src={ShortLivedBranches}
            className="modal-pic"
            style={{ marginBottom: 30 }}
          />
          <p id="fc9eb245-4c91-42ae-9e6f-6c16d3c6cf51" className=""></p>
          <h4 id="4c024d55-330a-4e52-842b-46c442bf12f9" className="">
            To summarize
          </h4>
          <p id="e143b227-892d-42d2-9856-43e2190c6fa3" className="">
            I hope you liked this article and the unique perspective on the
            microservices transition!
          </p>
          <p id="7749a016-6b9d-44f9-b872-87a82d28e2bb" className="">
            I still haven’t decided if there will be a part B for this article
            that focuses on<strong> how to split Databases </strong>as part of
            the Microservices transition. If you feel like it can help you out -
            don’t hesitate to let me know
          </p>
          <p id="560560ee-5650-4562-a507-8f4dd8a9c870" className=""></p>
          <p id="a89854ee-1496-4516-9574-3453c3e67c35" className=""></p>
        </div>
      </article>
    </div>
  );
}
