import React, { Component, useState } from "react";
import TodoDaily from "./assets/todo-daily.png";
export default function Productivity() {
  return (
    <div>
      <article id="f7cdbbb6-e7d6-43fc-8520-2993e8d347e3" className="page sans">
        <div className="page-body">
          <p id="01cf4794-e068-4457-b5cb-a35a4a67211d" className="">
            My team like to say I have 9 days a week because I sometimes get so
            much work done. Well, they don’t always say that. Sometimes they say
            I have a secret twin sister doing missions for me. You get the
            picture…
          </p>
          <p id="19891bde-35cd-4f39-b00d-5dadab2539fc" className="">
            While I don’t have any of those, I have some nice productivity
            tricks I gathered in years of working half-time during university,
            and after I switched to managing. These experiences forced me to
            gain healthy work habits that help me until today.
          </p>
          <p id="32480ab9-e7bd-442d-81b1-cbf79d855832" className="">
            In this article, I’ll share all of those tips with you (Well, not
            all. Gotta keep some of the secret ingredients!).
          </p>
          <h4 id="fcd000ca-1cce-4322-9db8-c4d6b0c9331c" className="">
            🧠 1. Maintain a “Second Brain”
          </h4>
          <p id="9141641b-0b58-4740-b62c-724c74456545" className="">
            The sooner you understand that your memory and{" "}
            <strong>mental capacity </strong>are <strong>limited</strong>, the
            better. Think of a “second brain” as an up-to-date snapshot of your
            status at work.
          </p>
          <p id="9a26b640-501e-4e3f-8ed6-0f8d87f3f43e" className="">
            I got used to this method while working part-time during university.
            I only worked 2 days a week, so the next week when I came to work I
            had to remember in exactly which point I stopped (It’s a bit like a
            Context Switch, isn’t it?) to make these 2 days really efficient.
          </p>
          <p id="ed5e10f5-a0bf-4cc8-ab0f-b33f8a07e4b9" className=""></p>
          <p id="9419c68d-d891-418b-8ac0-966bc254fb3c" className="">
            <span style={{ borderBottom: "0.05em solid" }}>
              <strong>How-to:</strong>
            </span>
          </p>
          <p id="3d339c61-d444-43a9-a792-3006ccf21819" className="">
            To maintain a second brain,{" "}
            <strong>Choose a comfortable Notes/Notebook app </strong>(I love{" "}
            <a href="https://www.notion.so/">
              <span style={{ borderBottom: "0.05em solid" }}>Notion</span>
            </a>
            ) and make it your source of truth for everything you do at work.
            For example, it could contain-
          </p>
          <ul
            id="420a9d2a-a06e-4cd8-b494-6a632a6fbb16"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              The things you’d like to get done{" "}
              <span style={{ borderBottom: "0.05em solid" }}>today</span>
            </li>
          </ul>
          <ul
            id="2c551fed-9069-4fa7-ab0e-b660a069685e"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              A page for every feature you’re working on, with your progress
              status and open questions
            </li>
          </ul>
          <ul
            id="dc2dee74-ac88-4215-9bce-c5d5ee0899df"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Things you want to say on 1:1s and don’t want to forget
            </li>
          </ul>
          <ul
            id="dec42ee9-16ca-44ab-901b-82ea787261ef"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Cheat sheets (like, shell commands you like)
            </li>
          </ul>
          <ul
            id="7fe7ebf0-6ac1-4324-9d88-cb71fe856a28"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              If you’re a manager, a page for each employee + a summary of your
              1:1s
            </li>
          </ul>
          <p id="530f766d-f89d-4aea-aff1-5f178b244133" className="">
            And More!
          </p>
          <p id="22586458-dd38-4358-beaf-2c9427fb771c" className=""></p>
          <p id="b8da46a8-26d4-442e-8c32-71898bf99161" className="">
            What I love about the second brain, is that as soon as things are
            written down, you don’t need to memorize anything, so this habit has
            a great calming effect. Everything is under control. You got this.
          </p>
          <p id="135cb884-7723-4419-9f12-96d992ccc7db" className=""></p>
          <h4 id="38a499c6-9def-4bd0-8d37-5ebe0f60d1ca" className="">
            🚥 2. Do the most important thing every time
          </h4>
          <p id="4c481233-9c71-4a80-9dfa-9547db92388b" className="">
            Working efficiently, especially in management, is understanding that{" "}
            <strong>there are some things you don’t like to do</strong>, and
            these things naturally get pushed by you because you determine your
            schedule. It’s dangerous because sometimes these are very{" "}
            <strong>important things for the company</strong> or other people.{" "}
          </p>
          <p id="0cfea233-02b0-4287-9d14-70e14e203952" className="">
            These things are different for each one: some don’t like to write
            yearly reviews, the other hates building presentations, and the
            other hates handling performance issues, and would rather code new
            features all day, instead.
          </p>
          <p id="50dd99b4-7076-43a3-af91-5838d191af1a" className="">
            So this part is all about constantly prioritizing tasks according to
            what really matters. It takes some practice but gets easier as it
            goes.
          </p>
          <p id="112325d8-957c-4281-b165-c4890346f6af" className=""></p>
          <p id="7798442a-0044-43fb-8d1f-67385b42b0ba" className="">
            <span style={{ borderBottom: "0.05em solid" }}>
              <strong>How-to:</strong>
            </span>
          </p>
          <p id="1654a7f8-941b-4788-9d4f-520db99d5876" className="">
            In your notes app, maintain 3 to-do lists:
          </p>
          <ul
            id="f120ff71-392f-4424-a12b-2b5e8c59ea9d"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Urgent </strong>(e.g check why the automation fails)
            </li>
          </ul>
          <ul
            id="e458f8bc-8c74-4ab5-9b41-afd360e07071"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Medium </strong>(e.g separate epic to stories, finish a
              task)
            </li>
          </ul>
          <ul
            id="0f990745-6e67-4253-818e-2bd9ae054c12"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Nice to have</strong> (e.g document something, think about
              how to refactor something, remind someone to give you an answer)
            </li>
          </ul>
          <p id="4d36fc0a-e094-4d9b-92cd-24a643fb897b" className="">
            Every time a new task arrives, whether from Slack, or you just had a
            cool idea, or your boss asked you - unless it’s 2 minutes of work,
            put it in one of those lists.
          </p>
          <p id="0bc1aee1-467c-4129-90fa-32f5dac5783a" className="">
            When you start your day, you know exactly what to do.{" "}
            <strong>Whatever is at the top! </strong>If you are blocked by
            someone, waiting, or just have some spare time, you can take tasks
            from the lower priority list. This way you’ll always have something
            to do, and eventually you’ll get to anything, in the right order.
          </p>
          <p id="c9d89f93-282a-4e34-aa6e-a8ce38639c60" className="">
            Like any new habit, it takes time to get used to it and write down
            every task. But when your list is well maintained, this is{" "}
            <strong>when your time is best used</strong>.
          </p>
          <img
            src={TodoDaily}
            className="modal-pic"
            style={{ width: "100%", marginBottom: 30 }}
          />
          <p id="55922524-e519-4ffd-897a-f79e1ff7b0cd" className=""></p>
          <h4 id="3a59eb37-db33-46ae-ad4a-b2fb87eab23f" className="">
            🗨️ 3. Manage your Slack, don’t let it manage you
          </h4>
          <p id="9e2aca0b-7545-47b6-b59e-d6e3188f2143" className="">
            Every instant messaging app is a bit different, but I’ll talk here
            about <strong>Slack</strong>, as it’s one of the most common ones in
            workplaces, and it’s the tool I’m working with.{" "}
          </p>
          <p id="e5d8a900-f62e-4c23-8ba0-e9229b3d67a5" className="">
            The fact that communicating in Slack is so slick and easy, also
            makes it a walking disorder of attention due to the endless amount
            of messages, mentions, and notifications.
          </p>
          <p id="2f430edf-c228-40b8-bc45-19914d78e891" className=""></p>
          <p id="e5471e42-eed6-436a-b00c-0ff14950ba70" className="">
            <span style={{ borderBottom: "0.05em solid" }}>
              <strong>How-to:</strong>
            </span>
          </p>
          <p id="af8d2d67-480a-4182-92a4-a2410d4d78e0" className="">
            Do you start your day by reading all Slack messages?{" "}
            <strong>Don’t</strong>.
          </p>
          <p id="7a1fe0b0-1664-4b33-99c8-0dc8551fe995" className="">
            Slacking never ends, and you might find yourself still answering
            messages half a day, not getting anything done, especially if you’re
            collaborating with a lot of people which happens a lot in management
            and customer-oriented positions.
          </p>
          <p id="c7978f1e-8f2c-4a2c-840d-82d08ce14204" className="">
            Instead-
          </p>
          <ul
            id="3dc6ff7c-fa2f-4f88-9858-9eca6ded273f"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Start the day with a quick win</strong>, something to feel
              productive about, 30 minutes up to one hour. Review someone’s
              code. Close a 1sp task. Answer the company poll. It doesn’t matter
              what, as long as 1 hour is enough. Get your dopamine working!
            </li>
          </ul>
          <ul
            id="bcf6d694-8b52-417b-ad49-05aa8f8154b3"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Prioritize Slack messages</strong>. When you already are
              answering, Go by this order: DM → Channels → Replies you’re
              Mentioned in (A bit robotic, but works). People who DMed you are
              the ones that need you most urgently. Channels are the ones that
              are the most easy to finish reading, as the messages there usually
              don’t refer to you directly. Replies you are mentioned in are the
              most complex to handle, as you will need to read a whole thread
              until you understand what’s required from you, so you want to give
              this your full attention. This takes the highest amount of time.
            </li>
          </ul>
          <ul
            id="55829581-8a05-4210-9213-ab55aefd98e5"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Mark as unread</strong>. If you read a message and can’t
              handle it within a minute, “Mark as unread” is your best friend.
              Some people also love to use the “Save for Later”, but I’m not a
              fan, as I tend to not get back to those “Later”s if they’re not in
              my Notion.
            </li>
          </ul>
          <p id="4d655227-447c-494d-ac58-0df5b0dd9321" className=""></p>
          <h4 id="a737359e-ddf9-4b83-bc9b-1ea3cf54d0f2" className="">
            Well, easier said than done…
          </h4>
          <p id="64f3ef24-d781-4913-aa20-253f5def3a9b" className="">
            It’s not completely fair, what I wrote here, as I suggest you adopt
            at once habits that it took me years to develop.
          </p>
          <p id="87629a54-d3bd-4331-9dab-09a216d8aa12" className="">
            However, I hope that you do find value in at least one of these tips
            and that they’ll help you be more productive and in control. If you
            need to implement a new habit, I recommend reading the wonderful
            Atomic Habits (I also wrote about it{" "}
            <a href="https://omer-shlifka.com/feed/em-must-reads">
              <span style={{ borderBottom: "0.05em solid" }}>here</span>
            </a>
            ), which provides down-to-earth methods for embedding new habits
            into your daily routine.
          </p>
          <p id="3e18f839-a44f-4e95-a413-d3b26d8f01cf" className="">
            If you have any other work-life hacks, tips, or tricks, please let
            me know!
          </p>
        </div>
      </article>
    </div>
  );
}
