import React, { Component, useState } from "react";
import Sloth1 from "./assets/sloth1.png";
import Sloth2 from "./assets/sloth2.png";
import Sloth3 from "./assets/sloth3.png";
export default function ECUI() {
  return (
    <div>
      <article id="33a464ed-7259-4d46-8d5d-3a1655f2fe59" className="page sans">
        <div className="page-body">
          <p id="bed2f2ef-4da2-48b4-b3a4-83cf527c8266" className="">
            We, software engineers, are lazy, and thank god for that because it
            sometimes leads to some great solutions. It’s a special kind of
            laziness because we are willing to work hard on something to ensure
            that in the future we won’t do any job that is repetitive, robotic,
            or boring. In other words, we automate <em>everything</em>.
          </p>
          <p id="89b7a913-7f11-47e6-a71e-dd07b3022496" className="">
            <span style={{ borderBottom: "0.05em solid" }}>
              So why not start with our development process?{" "}
            </span>
          </p>
          <p id="d9d433fc-6a75-4f1d-bb36-de4305dd2bfe" className="">
            In this article I’ll show you a pattern, a method I used several
            times at work and it accelerated my features dev velocity by
            creating a “UI factory”, a machine that produces new UI components
            in no time. I call it “<strong>Externally-configured UI</strong>”,
            or in short, ECUI.
          </p>
          <p id="0bb5950d-13a6-4ab9-be8b-5fca68210a60" className="">
            *The examples will be demonstrated using ReactJS, but the method
            itself is infrastructure agnostic.
          </p>
          <figure style={{ textAlign: "center", margin: 30 }}>
            <img style={{ width: "80%" }} src={Sloth1} />
          </figure>
          <h4 id="201c01e9-9d63-4f75-95fb-4bbeccdbdeb4" className="">
            What is Externally-configured UI in comparison to declarative and
            imperative UI
          </h4>
          <p id="da06db0f-129f-4c40-a739-e1ff4a3b76bc" className="">
            There are 2 paradigms in software development in general and in UI
            specifically: <strong>Imperative </strong>
            <strong>programming </strong>and{" "}
            <strong>declarative programming</strong>.
          </p>
          <p id="96e11bd4-9e11-4fb7-9c75-f17cbee11f95" className="">
            In UI frameworks that are <strong>imperative</strong>, you change
            the <strong>state </strong>of the view by modifying an{" "}
            <strong>object </strong>representing it. This approach is common in
            more traditional frameworks like WinForms (what nostalgia!) and in
            vanilla JS. For example-
          </p>
          <pre id="f5f09fef-0633-47c1-8ba7-2d28791fb337" className="code">
            <code>
              MessageBox box = new Box();{"\n"}box.title = "alert";{"\n"}
              box.Show();
            </code>
          </pre>
          <p id="d7d69950-d4f6-4b73-9637-79051337333a" className="">
            In new modern UI infrastructures (ReactJS for example) the UI is
            written <strong>declaratively</strong>, meaning you{" "}
            <strong>declare the view structure</strong>, and its state is
            changing based on the received data. For example-
          </p>
          <pre id="a0247fa8-931e-44ce-b548-02c7820c478d" className="code">
            <code>
              return &lt;MessageBox open={"{"}isOpen{"}"} titleaa={"{"}title
              {"}"} /&gt;
            </code>
          </pre>
          <p id="100d28f7-10f3-49b4-ae27-d7bdebfedc62" className="">
            <strong>Externally-configured UI</strong> is using{" "}
            <strong>declarative UI</strong> where{" "}
            <strong>the declarations are not part of the UI codebase </strong>
            and
            <strong> </strong>are <strong>agnostic </strong>to the frontend
            infrastructure. In other words, they are{" "}
            <strong>externally configured</strong>.
          </p>
          <p id="a606b2fa-f7b9-487f-a3d7-073644d2489e" className="">
            The UI itself is only responsible for rendering the configured
            declarations, so new additions will be added with{" "}
            <strong>no code</strong>.
          </p>
          <h4 id="f5a87c20-3d6e-479b-9e57-162a973ca7c2" className="">
            An example
          </h4>
          <p id="efa9efb4-64d2-489b-bdca-483fc83184c7" className="">
            Imagine you are required to develop a <strong>feedback form</strong>{" "}
            for your web application, in order to receive feedback from your
            customers.
          </p>
          <p id="34653f24-d6b6-476e-be04-743fef3c7e99" className="">
            You get an exact specification from your product manager: the form
            consists of 2 questions:
          </p>
          <ol
            type={1}
            id="a1c20dfd-1450-4fc8-9e65-03a206c563f2"
            className="numbered-list"
            start={1}
          >
            <li>How well is our service? (rank from 1 to 5)</li>
          </ol>
          <ol
            type={1}
            id="b3522070-3f86-4c98-8f6e-103ac52432ce"
            className="numbered-list"
            start={2}
          >
            <li>Please describe (free text)</li>
          </ol>
          <p id="6f2cf6b3-149f-43fe-8127-4cc273ec0803" className="">
            <span style={{ borderBottom: "0.05em solid" }}>
              How would you reach that kind of problem?
            </span>
          </p>
          <p id="35910b69-2450-4b67-9f99-5e0299ed6aa3" className="">
            Well, you would probably first develop the{" "}
            <strong>building blocks</strong>, which are ranking input and free
            text input. Then you will build the form itself, and add the
            building blocks inside (Yay composition!).
          </p>
          <p id="ee41b549-cc0e-4820-a16c-fcc53961176c" className="">
            At this point, your form probably looks something like that-
          </p>
          <pre id="9400c0c3-2cef-411c-ab9b-e3cdb848f23c" className="code">
            <code>
              const FeedbackForm() {"{"}
              {"\n"}
              {"\t"}return (&lt;form&gt;{"\n"}
              {"\t"}
              {"\t"}&lt;FormField title="How would you rank our service?"&gt;
              {"\n"}
              {"\t"}
              {"\t"}
              {"\t"}&lt;StarsRanking max={"{"}10{"}"} /&gt;{"\n"}
              {"\t"}
              {"\t"}&lt;/FormField&gt;{"\n"}
              {"\t"}
              {"\t"}&lt;FormField title="More comments?"&gt;{"\n"}
              {"\t"}
              {"\t"}
              {"\t"}&lt;TextArea max={"{"}8{"}"} /&gt;{"\n"}
              {"\t"}
              {"\t"}&lt;/FormField&gt;{"\n"}
              {"\t"}
              {"\t"}&lt;input type="submit"&gt;{"\n"}
              {"\t"}
              {"\t"}
              {"\t"}Submit{"\n"}
              {"\t"}
              {"\t"}&lt;/input&gt;{"\n"}
              {"\t"}&lt;/form&gt;){"\n"}
              {"}"}
            </code>
          </pre>
          <p id="f05f748c-c5d8-4bf7-af7c-955843b0fc91" className="">
            It may seem like a non-issue, but you’re not done yet!
          </p>
          <p id="6bcc19bb-1f49-4f84-9600-1550c02f0597" className="">
            Because for every request to add an input, or change the order of
            inputs, you or one of your team need to do a manual change in the
            codebase. to open a pull request. to wait for CI. It’s becoming
            time-consuming, and most importantly, <strong>boring</strong>. we
            don’t like boring.
          </p>
          <p id="d0a74a53-df4c-4dfb-ada0-9f237f0faa88" className="">
            <span style={{ borderBottom: "0.05em solid" }}>
              So what do we do
            </span>
            ?{" "}
          </p>
          <p id="b37a806f-c8c2-4fc8-8659-e0885bd60512" className="">
            We basically make our form render the inputs
            <strong> based on an outside resource</strong>, describing the{" "}
            <strong>metadata of each input</strong> that should be rendered in
            the form.{" "}
          </p>
          <pre id="54003646-238f-4021-b974-6e376aa26daf" className="code">
            <code>
              // declarations.json - this is what shapes the form{"\n"}[{"{"}
              {"\n"}
              {"\t"}"type": "stars",{"\n"}
              {"\t"}"title": "Please rank your experience from 1 to 5",{"\n"}
              {"\t"}"name": "experience",{"\n"}
              {"\t"}"max": 10{"\n"}
              {"}"}, {"{"}
              {"\n"}
              {"\t"}"type": "freeText"{"\n"}
              {"\t"}"title": "Any other feedback?"{"\n"}
              {"\t"}"name": "feedback"{"\n"}
              {"}"}]
            </code>
          </pre>
          <p id="6129d711-8c84-47b6-9119-d50e76477894" className="">
            We also provide our product managers with the ability to change that
            resource. In other words,
            <strong> we externalize the configuration of the UI. </strong>Now we
            are free to go back to doing the real challenging work!
          </p>
          <h5 id="aef78caa-cce7-401f-979c-49e974b52099" className="">
            In conclusion, Externally-configured UI is good for-
          </h5>
          <ul
            id="aadc7ef8-7658-41eb-93a9-62802cf3c995"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Speed</strong>: It helps you run fast, and contributes to
              the business needs because additions and modifications are done
              without the need to write code.
            </li>
          </ul>
          <ul
            id="58444e1d-260b-4c4f-b78d-0bb5a2ed9364"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Removes bottlenecks</strong>: Changing configurations
              requires no special expertise in any technology, which allows
              developers from other teams to practically change the UI even if
              they are backend oriented, for example. Moreover, non-technical
              people can change the configuration too if we’ll make it friendly
              enough. This helps to reduce bottlenecks between teams.{" "}
            </li>
          </ul>
          <ul
            id="5eb0ad74-4e68-4f6a-b810-c501b9faecd3"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Happy engineers</strong>: Since different personas can
              also handle the configuration, even the non-technical ones, that
              means that the R&amp;D can focus on the more complicated tasks
              they love so much.
            </li>
          </ul>
          <figure style={{ textAlign: "center", margin: 30 }}>
            <img style={{ width: "80%" }} src={Sloth2} />
          </figure>
          <h4 id="a78b2c34-bd17-4f68-8dfc-aab34b34ddb7" className="">
            So how does it work?
          </h4>
          <p id="d5ab8a5f-b38b-466b-a477-c062efb5eee1" className="">
            Let’s try to build our <strong>user feedback form</strong> by
            familiarizing ourselves with the 3 parts of ECUI:{" "}
            <strong>Declarations</strong>, <strong>Views, </strong>and a{" "}
            <strong>factory</strong>.
          </p>
          <h5 id="e042e892-81b4-4f1e-bfa0-f639257c389e" className="">
            <strong>The Declarations</strong>
          </h5>
          <p id="f91eab36-2811-4897-8ef4-1af6b67b8dbe" className="">
            <strong>The declarations</strong> contain your UI declarations. They
            should contain the minimal amount of data possible to render the UI
            correctly.
          </p>
          <div className="indented">
            <ul
              id="36683a5d-2c69-4d58-b3a7-8d48c4a617d9"
              className="bulleted-list"
            >
              <li style={{ listStyleType: "disc" }}>
                The schema of the declarations should be very simple, as it
                needs to be modified sometimes by less technical workers. Ask
                yourself who will edit the declarations and choose the format
                accordingly (I love JSON because it’s convenient to work with in
                JS &amp; TS but also easy to understand).{" "}
              </li>
            </ul>
            <ul
              id="3de7c364-986b-4964-87c4-d544de653f3e"
              className="bulleted-list"
            >
              <li style={{ listStyleType: "disc" }}>
                The declarations can sit anywhere you want: I recommend a file
                in VCS in order to keep version history and to allow a CI/CD
                process, but you can also store it in any remote storage that
                provides editing, such as a database, S3 bucket, etc.
              </li>
            </ul>
            <ul
              id="87ae6989-2414-492e-a977-c516f4cb7f4a"
              className="bulleted-list"
            >
              <li style={{ listStyleType: "disc" }}>
                Make sure to include some basic tests and schema validation on
                the declarations file, to avoid misconfigurations that will make
                the UI break. (for example, so you won’t use an input type the
                UI doesn’t know how to render yet, that you didn’t put the same
                input twice, etc.)
              </li>
            </ul>
          </div>
          <p />
          <h5 id="14778a0b-4a66-4ec4-bda4-8cf0027525f0" className="">
            <strong>The views</strong>
          </h5>
          <p id="8eaae875-737b-4ef1-b5be-8c1f6be12a7d" className="">
            The components composing the UI, the parts you used to constantly
            copy-paste.
          </p>
          <p id="bb947585-a547-485f-8a34-31901c83fb53" className="">
            The views/components may share common properties, like name and
            title, and some of them have unique properties. Design the
            components in such a way, that if a <strong>common </strong>property
            is added, it will be added in all of the components at once.
          </p>
          <div className="indented">
            <pre id="eca78264-db8e-4a29-b607-8cc6be38577d" className="code">
              <code>
                &lt;Textarea name='' title='' /&gt;{"\n"}&lt;StarsRanking
                name='' title='' max=10 /&gt;
              </code>
            </pre>
          </div>
          <p />
          <h5 id="dadd4ea2-243e-48ba-af81-13b3925bedaf" className="">
            <strong>The Factory</strong>
          </h5>
          <p id="81d94738-09ef-4116-bc7d-2c69f50b3439" className="">
            The factory is in charge of<strong> rendering the UI </strong>
            according to the received declarations.
          </p>
          <p id="54c0eac1-f2af-496d-8a0d-791844ba733e" className="">
            It consists of a <strong>mapper </strong>and a{" "}
            <strong>view, </strong>
            that is using the mapper, to render the declarations.
          </p>
          <pre id="f37f680a-95f6-4ced-b203-a26a42feb812" className="code">
            <code>
              const mapper = {"{"}
              {"\n"}
              {"\t"}stars: StarsRanking,{"\n"}
              {"\t"}textarea: Textarea{"\n"}
              {"}"}
              {"\n"}
              {"\n"}const FeedbackForm() {"{"}
              {"\n"}
              {"\t"}const declarations = useDeclarations() // Using an ajax
              request
              {"\n"}
              {"\t"}const fields = declarations.map(declaration =&gt; {"{"}
              {"\n"}
              {"\t"}
              {"\t"}const Component = mapper[declaration.type]{"\n"}
              {"\t"}
              {"\t"}return {"\n"}
              {"\t"}
              {"\t"}
              {"\t"}(&lt;FormField&gt;{"\n"}
              {"\t"}
              {"\t"}
              {"\t"}
              {"\t"}&lt;Component {"{"}...declaration{"}"} /&gt;{"\n"}
              {"\t"}
              {"\t"}
              {"\t"}&lt;/FormField&gt;){"\n"}
              {"\t"}
              {"}"}){"\n"}
              {"\t"}return &lt;form&gt;{"\n"}
              {"\t"}
              {"\t"}
              {"{"}fields{"}"}
              {"\n"}
              {"\t"}
              {"\t"}&lt;input type="submit"&gt;{"\n"}
              {"\t"}
              {"\t"}
              {"\t"}Submit{"\n"}
              {"\t"}
              {"\t"}&lt;/input&gt;{"\n"}
              {"\t"}&lt;/form&gt;{"\n"}
              {"}"}
            </code>
          </pre>
          <p id="8dd89118-1a20-44e9-b11a-d43529a2dc09" className="">
            And Voila! Not bad for a sloth.
          </p>
          <h5 id="fe6971b5-2ec1-4dcd-98e7-d29e7d5ac0a4" className="">
            Which features are suitable for Externally-configured UI?
          </h5>
          <p id="bdb50eb9-4206-4221-a0d4-9a09576be729" className="">
            If you think about using ECUI for a feature, Ask yourself 2
            questions:
          </p>
          <ul
            id="40f9c299-4195-4375-a41e-c86727cc0a4d"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Do I <strong>repeatedly </strong>need to modify a certain feature?
            </li>
          </ul>
          <ul
            id="c67814f3-3200-4e35-bb14-a4a8982cbdf1"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Are the modification actions somewhat <strong>robotic </strong>and{" "}
              <strong>easy to do</strong>?<strong> </strong>meaning, I don’t
              need to develop new views and components for them, but rather
              copy-paste, and change data/icons/texts? Clue: if your brain
              wheels don’t move even a bit, this might be the case.
            </li>
          </ul>
          <p id="a841ba97-f656-4eab-b65d-7b4b7e776283" className="">
            If you answered “Yes” to both of these questions, ECUI might be your
            best friend!
          </p>
          <h4 id="b8d6da0c-e8db-4c71-bf56-35536651b036" className="">
            Cons of Externally-configured UI
          </h4>
          <p id="fa1f6288-a18d-49f6-a3a5-cb1aa5a30d67" className="">
            If I already convinced you that ECUI is the best, just keep in mind
            that if you don’t do it carefully, it can become a{" "}
            <strong>huge mess</strong>.
          </p>
          <p id="b217a5ec-3bd8-4b5f-b707-817de5640e02" className="">
            In order to do it carefully, you need to define precisely{" "}
            <strong>who </strong>are the editors of the declarations, what{" "}
            <strong>qualifies </strong>them to be editors, what{" "}
            <strong>protects </strong>the declarations from breaking the UI, and
            what is the <strong>process </strong>of such modification
            (declarations review? CI? CD? etc.) That’s a lot of questions!
          </p>
          <p id="77bfed76-920a-4c70-a2c2-bff2ad17e468" className="">
            In addition, there is an initial cost for developing the UI factory
            itself, so you need to think well if it’s a problem that bothers you
            enough to automate it with configuration.
          </p>
          <p id="d6f72b8c-b5fb-4b73-93a4-8c26c7817d46" className="">
            However, if the problem is bothering you and taking lots of your
            time for you and your team, I assure you it will pay off shortly.
          </p>
          <h4 id="1ff30a67-d85f-4ff6-a12d-1f65743d2ab9" className="">
            Summary
          </h4>
          <p id="abbaabe9-39fa-405a-b12c-a0b6b3592023" className="">
            Hope you enjoyed the article and that in the future it saves some
            precious time for you, your team, and your company.
          </p>
          <p id="abbaabe9-39fa-405a-b12c-a0b6b3592023" className="">
            Feel free to reach out with any questions or thoughts.
          </p>
          <p id="626b9276-cf80-49d3-929c-50c84e873396" className="">
            So… let’s get back to working hard, so we can go back to being lazy
            again!
          </p>
          <figure style={{ textAlign: "center", margin: 30 }}>
            <img style={{ width: "80%" }} src={Sloth3} />
          </figure>
        </div>
      </article>
    </div>
  );
}
