import React, { Component, useState } from "react";
import Bluffing from "./assets/bluffing.gif";
import IDont from "./assets/YouKeep.jpg";
import StopSayingThat from "./assets/stopsayingthat.gif";
import YouGuessedWrong from "./assets/youGuessedWrong.gif";

export default function TermsWeUseWrong() {
  return (
    <div>
      <article id="bf6eadd6-3a14-4f30-870a-dd8d67031b80" className="page sans">
        <div className="page-body">
          <p id="fdee225d-7efe-4b40-91b4-b5e5dbf3e23e" className="">
            The cult movie "The Princess Bride” besides being one of my favorite
            movies of all time, is a movie that is full of iconic quotes. The
            most famous one is, "
            <strong>
              My name is Inigo Montoya. You killed my father. Prepare to die.
            </strong>
            " But one of my favorites is this one: After Vizzini keeps
            commenting "Inconceivable!" on everything, Inigo tells him: "You
            keep using that word. I don’t think it means what you think it
            means".
          </p>
          <p id="b7174502-669b-4e1d-bf35-37fb21ce2d70" className="">
            I believe we all do it once in a while. Throwing a term into a
            conversation, a term we think we understand because we heard it many
            times before, and just move on with our lives. Sometimes we even
            feel good about ourselves that we managed to use that term in a
            conversation. But how often do we stop and ask ourselves
            <strong> </strong>if we <strong>understand what those terms</strong>{" "}
            <strong>mean</strong>?
          </p>
          <h4 id="c35458db-83cd-4d82-9d10-f7c0f6ea81ce" className="">
            Let’s use technical terms the right way
          </h4>
          <p id="97bdfe09-6f6b-4262-abc4-82b91393cacb" className="">
            The first reason to use technical terms the right way is to maintain
            our reputation as professional individual contributors.
          </p>
          <p id="796018da-107a-4eee-8946-9f181d106275" className="">
            In addition, I believe that a thorough understanding of everything
            you do will also help you do your work better. Intuitive, but still
            worth mentioning.
          </p>
          <img src={IDont} className="modal-pic" style={{ marginBottom: 30 }} />
          <p id="b34aac59-1322-4ff8-b320-099ac5032eb7" className=""></p>
          <h4 id="dafce1a3-7be6-47ad-97cb-a4ebe11253e3" className="">
            1. Async (Specifically in JS and Python)
          </h4>
          <p id="f166537f-0b58-48a7-9ee5-3f7c3d3eb8ea" className="">
            This is a very common mistake. It’s especially confusing because
            async-await is implemented differently in each programming language.
            Let’s see how to use it properly.
          </p>
          <p id="c459fba2-4aa8-4bde-a33e-d451b43d15dd" className="">
            <strong>How people sometimes refer to it</strong>: Parallelism /
            Multi-processing / Multi-threading
          </p>
          <p id="0340cf00-de93-4307-a192-35bd983181fa" className="">
            <strong>How to not use it in a sentence</strong>: “We moved our
            Python microservices to be based on async-await, so it can run
            multiple threads and do the work faster”.
          </p>
          <img
            src={Bluffing}
            className="modal-pic"
            style={{ marginBottom: 30 }}
          />
          <p id="f777948a-e1b4-4106-b147-e25ef122d478" className="">
            <strong>What it really means</strong>: In single-threaded languages,
            like JS and Python*, async is used so we won’t{" "}
            <strong>block the execution</strong> of our program until{" "}
            <strong>long I/O-bound tasks finish</strong>, for example: sending
            HTTP request, reading from a file or DB, and more. Moreover, if our
            program needs to wait for CPU-bound tasks, async-await will not be
            the right choice and will cause our program to run slower, because
            it’s still one thread, so we won’t be saving any time.
          </p>
          <p id="c332d105-929a-4ad1-8b05-18c279152f28" className="">
            It’s a bit counterintuitive, but
            <strong>
              {" "}
              waiting for I/O-bound tasks does not require our program to run on
              multiple threads
            </strong>
            , and of course, not on multiple CPU cores. Instead, those languages
            manage an <strong>event loop</strong>, and multi-tasking between the
            events, while utilizing the OS to run the I/O-bound tasks in
            parallel.
          </p>
          <p id="a7acb837-9c35-4021-844b-56fca076ab4e" className="">
            I love this example of making a lazy dinner, to understand the
            difference between the different terms:
          </p>
          <ul
            id="61d2e463-7b0a-4cdb-94cc-9dca42b771c5"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Synchronous</strong>: I will order a pizza. Will sit in my
              chair until the pizza is delivered. Then will make a salad and
              open a bottle of wine (cause I’m a classy girl).
            </li>
          </ul>
          <ul
            id="01c6180f-57b0-40b1-8737-f9bf8359bee6"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Multi-threading</strong>: I’ll make a pizza but once in a
              few seconds I’ll change to making a salad and open a bottle of
              wine, get back to the pizza making, etc, until everything is done.
            </li>
          </ul>
          <ul
            id="20c9f9dc-8e42-4c16-beda-b66681d7b937"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Multi-processing</strong>: My friend will bake a pizza
              while I’ll make a salad and open a bottle of wine.{" "}
            </li>
          </ul>
          <ul
            id="a922059d-eb5e-4c80-8ff5-1aebaf13280a"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Async</strong>: I will order a Pizza. While the Pizza is
              delivered, I will make a salad and open a bottle of wine.
            </li>
          </ul>
          <p id="f20b23d7-8662-4cc0-8851-4d6aa94f9a6a" className="">
            Be smart, order the pizza when possible 😉.
          </p>
          <p id="cd83f48d-e859-47a0-bf95-3a995850b1e0" className="">
            * Technically Python has threads, but the GIL makes sure only one
            thread can run on each process every time.
          </p>
          <p id="3e594d56-fa78-4ac1-8a92-33347d00a340" className=""></p>
          <h4 id="a14f1d75-fde4-438e-b728-b9858ea528f9" className="">
            2. REST
          </h4>
          <p id="b6968654-852a-430d-9101-ae93474912ae" className="">
            <strong>How people sometimes refer to it</strong>: HTTP API
          </p>
          <p id="b73f0089-eec8-46fe-8018-deb7f0fedcf6" className="">
            <strong>How to not use it in a sentence</strong>: “In my last role I
            created a RESTful API for managing trees. For example{" "}
            <code>
              POST trees/123/addNodeToTree {"{"}id: “456”{"}"}
            </code>
            ” / “Let’s just add a REST API for that GraphQL endpoint”.
          </p>
          <img
            src={YouGuessedWrong}
            className="modal-pic"
            style={{ marginBottom: 30 }}
          />
          <p id="00bf1b3f-d68b-4b00-b829-a70fa74ddd70" className="">
            <strong>What it really means</strong>: As I also wrote{" "}
            <a href="https://omer-shlifka.com/feed/design-customer-api-2">
              here
            </a>
            , REST (Representational state transfer🤢) is a set of rules and
            constraints for designing an API. It’s not a protocol per se, but
            more of a rule of thumb. It can utilize every network protocol,
            although it’s most common to be used with HTTP.
          </p>
          <p id="43aba5e4-3b5a-490f-8c7f-c831027e73d7" className="">
            The idea behind REST is to manage <strong>resources</strong>. A
            resource is everything the user is handling (creating, watching,
            editing, removing). For example in our shows API, a TV show is an
            asset, but also an actor can be an asset, a producer, a movie, etc.
          </p>
          <p id="e1093524-e146-4762-856e-92de3b3e8899" className="">
            The real REST specification is so strict and extreme, so it’s fair
            to assume that most of the HTTP APIs you worked on, that you thought
            of as REST, aren’t that at all.
          </p>
          <h4 id="c55bb5c1-503b-4f1e-822a-8ac5fdf06850" className="">
            3. Race Condition
          </h4>
          <p id="94835745-9d0e-4af0-8855-ab7aae006899" className="">
            <strong>How people sometimes refer to it</strong>: Unexpected result
            on each execution
          </p>
          <p id="d3544cb1-a9ac-4ed0-9f78-1d73bf6537d1" className="">
            <strong>How to not use it in a sentence</strong>: “This is a really
            weird UI bug, it sometimes happens and sometimes not. Probably a
            race condition.”.
          </p>
          <img
            src={StopSayingThat}
            className="modal-pic"
            style={{ marginBottom: 30 }}
          />
          <p id="fed27366-242b-46b0-a1c2-96b362d22d70" className="">
            <strong>What it really means</strong>: Race condition is when
            multiple threads modify the same resource at the same time and cause
            unexpected behavior. For example, in the lazy dinner example, if my
            friend puts the pizza in the oven but at the same time I will turn
            the oven off, we won’t have anything to eat.{" "}
          </p>
          <p id="727adc18-ef76-47e8-90d3-acbb9b9c005d" className="">
            For the same reason mentioned above, you can put this option off the
            table if you are using JS. Try to understand the real root cause
            behind the issue, it can be different network request times for each
            execution, and a lot of other stuff, but it’s not a race condition.
          </p>
          <h4 id="eafb8596-7092-4a80-a0b5-8f2b11d17215" className="">
            4. AI
          </h4>
          <p id="1a2d60ba-c6e8-4e20-86ae-fb77f23a2d7e" className="">
            Oh man, don’t even get me started.
          </p>
          <h4 id="b0122d0a-32a1-4a72-932b-f40a98f36bbc" className="">
            Knowledge is power, use it well
          </h4>
          <p id="61ca0e15-00ba-4e18-ac47-6c61c22b4ab8" className="">
            I’m sure that now you will constantly hear other people misuse those
            terms I mentioned above, and you may get an urgent need to patronize
            them with your knowledge. Use this knowledge for the good and help
            everyone around you be better at what they do. Empathy is key.
          </p>
          <p id="aa7d0fa3-3bf3-4ee3-b07e-06f6fd860a4e" className="">
            And of course, if you have ideas for other such misusages, write
            them here in the comments! ⬇️
          </p>
        </div>
      </article>
    </div>
  );
}
