import React, { Component, useState } from "react";
import { Navbar, Container, Nav, Badge, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import SSO1 from "./assets/sso/1.png";
import SSO2 from "./assets/sso/2.png";
import SSO3 from "./assets/sso/3.png";
import SSO4 from "./assets/sso/4.png";
import SSO5 from "./assets/sso/5.png";
import SSO6 from "./assets/sso/6.png";
import Shipuzim from "./assets/perks/shipuzzim.gif";
import AboutMe from "./assets/perks/about2.gif";
import IB from "./assets/perks/ib.gif";
import IO from "./assets/perks/io.gif";
import IP from "./assets/perks/ip.gif";
import IG from "./assets/perks/ig.gif";
import Coding from "./assets/feed/coding.png";
import DashboardsImg from "./assets/dashboards.png";
import Bfs from "./assets/feed/bfs.PNG";
import Dfs from "./assets/feed/dfs.PNG";
import { ReactAWSS3 } from "./ReactAWSS3";
import CleanCode from "./CleanCode";
import MCChecklist from "./MCChecklist";
import { ApiDesign, ApiDesign2 } from "./ApiDesign";
import MustReads from "./3MustReads";
import MicroservicesChocolateFactory from "./MicroservicesChocolateFactory";
import PublicSpeakingTips from "./PublicSpeakingTips";
import MagicalMysteryTour from "./MagicalMysteryTour";
import Productivity from "./Productivity";
import Scrum from "./Scrum";
import ECUI from "./ECUI";
import "./App.css";
import TermsWeUseWrong from "./TermsWeUseWrong";

function ModalImg({ src }) {
  return (
    <div style={{ display: "flex", placeContent: "center" }}>
      <img src={src} className="modal-pic" />
    </div>
  );
}

const Badges = {
  Productivity: (
    <Badge style={{ backgroundColor: "#a371be" }} bg="">
      Productivity
    </Badge>
  ),
  UXUI: (
    <Badge style={{ backgroundColor: "#d28056" }} bg="">
      UX/UI
    </Badge>
  ),
  Software: <Badge bg="primary">Software</Badge>,
  Management: <Badge bg="success">Management</Badge>,
  Frontend: <Badge bg="warning">Frontend</Badge>,
  Backend: (
    <Badge style={{ backgroundColor: "#e79f61" }} bg="">
      Backend
    </Badge>
  ),
  Architecture: <Badge bg="info">Architecture</Badge>,
  Security: <Badge bg="danger">Security</Badge>,
  SoftSkills: <Badge bg="info">Soft Skills</Badge>,
  Personal: (
    <Badge style={{ backgroundColor: "#269590" }} bg="">
      Personal Story
    </Badge>
  ),
};

function SSOImg({ src }) {
  return (
    <div>
      SSO (Single Sign-on) is not rocket science, but learning it can be a bit
      overwhelming. <br />
      It involves various terms such as Idp, SP, RP, and authentication methods
      like OIDC and SAML, along with somewhat complex flows (hi, OAuth!).
      <br /> The available information on the internet regarding this topic is
      often wordy and verbose.
      <br /> I realized that it could be explained in a simpler and clearer way.
      <br /> So I created a nice infographic, which also allows me to practice
      my Figma skills, win-win :)
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 24,
          placeContent: "center",
        }}
      >
        <img src={SSO1} style={{ width: "100%" }} />
        <img src={SSO2} style={{ width: "100%" }} />2 main methods for
        implementing SSO-
        <img src={SSO3} style={{ width: "100%" }} />
        <img src={SSO4} style={{ width: "100%" }} />
        These are examples for SP-initiated SSO, There’s also Idp-initiated SSO,
        where the first request is made directly to the Idp
        <img src={SSO5} style={{ width: "100%" }} />
        <img src={SSO6} style={{ width: "100%" }} />
      </div>
    </div>
  );
}

function Dashboards101({ src }) {
  return (
    <div>
      <h4>Everybody looooves dashboards.</h4>
      <div>
        In the world of Big Data, dashboards help us grasp complex information
        in seconds, making them popular among both customers and management, and
        a must-have in every business web app. However, planning such a
        dashboard system isn't simple; its architecture involves performant
        backend endpoints, data-engineering superpowers, and beautiful frontend
        charts. <br />
        But don’t worry—I've created this '101' guide so you can skip directly
        to the building phase!
      </div>
      <img src={DashboardsImg} style={{ width: "100%" }} />
    </div>
  );
}

function FigmaForEngineers({ src }) {
  return (
    <div>
      Have you always dreamed of designing your own features and bringing your
      ideas and inspiration to life? <br />
      As developers, we have a huge advantage when it comes to using Figma for
      UI design.
      <br /> We are already used to thinking in terms of component reuse,
      robustness, and coupling. <br />
      <br />
      In this session, you will learn how to harness some of the principles we
      know from SOLID and Clean code to create beautiful UI components in the
      most popular design app in the high-tech industry today.
      <br />
      <br />
      (My talk from the annual{" "}
      <a href="https://www.summit2024.reversim.com/" target="_blank">
        Reversim Summit
      </a>
      , taking place in Tel Aviv. The lecture is held in Hebrew)
      <br />
      <br />
      <iframe
        src="https://www.youtube.com/embed/uHj5e7Hmfeo?si=qeAX6riT1cL-pPNs"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  );
}

function BFS() {
  return (
    <div>
      <h5>
        Or: how can you become a better developer via understanding if you are a
        bfs-person or a dfs-person
      </h5>
      In Computer Science, <b>Breadth-first search</b> (<b>BFS</b>) and{" "}
      <b>Depth-first search (DFS)</b> are algorithms for searching/scanning
      a tree data structure for a specific node. they both start with the tree
      root and eventually covering all nodes in the tree, the difference is in
      how they scan the nodes, in what order.
      <br />
      <br />
      <b>BFS</b> explores all nodes by depths levels (first all nodes at depth
      1, then depth 2, and so on) until the required node was found. <b>DFS</b>{" "}
      explore all nodes at a specific route to a leaf. when this route is
      exahausted, we move to the next route to a leaf (backtracking).
      <ModalImg src={Bfs} className="modal-pic" />
      <ModalImg src={Dfs} className="modal-pic" />
      <br />
      <br />
      At some point I've found out that these two concepts of searching, also
      matching <b>2 approaches of developers</b> when they get a development
      task.
      <br />
      <br />
      One persona (DFS) starts a development task. in the middle, he (or she)
      notices he doesn't like the convention they are using for variables in the
      codebase. <b>on the way</b> of changing that, he notices another potential
      bug and fixes it too, on the way of fixing this bug, he finds out that
      some functions are not well-documented, and decides to document them, and
      so on and so forth.
      <br />
      <br />
      The BFS one, on the other hand, is getting a task, and doing like a
      "medical surgery" to change the exact place that needs to be fixed and
      nothing more, doing the <b>minimal effort possible</b> that should be done
      on order to mark the task as "Done" and go home.
      <br />
      <br />
      What persona you think describes you the most? (My answer is in the end)
      <br />
      <br />
      How do you think it affects your work, in terms of: reputation,
      code-quality, fast-delivery?
      <br />
      <br />
      Which approach you think is better?
      <br />
      <br />
      In life, like in life, things aren't black and white. each of us is
      sometimes this and sometimes that, and none of the approach is generally
      better than the other, but, each of them{" "}
      <b>matching different situations</b>. BFS-coding makes you <b>deliver</b>{" "}
      much faster, therefore important on urgent tasks, on start-ups where
      things have to get done quickly and the hell with the infrastructure.
      DFS-coding makes you work very thoroughly, which can be used in
      infrastructural tasks, like general refactoring in the code. And it also
      kinda makes you look like a superman to your team, that's also nice and
      good for your reputation.
      <br />
      <br />
      BTW, I'm totally tend to be a <b>BFS-person</b> myself, but I think I've
      found a good compromise. If during the development I see something that
      bothers me, I write it down in my notes. eventually I get to all of these
      "side-jobs", but in the right time for me, and without delaying urgent
      tasks.
      <br />
      <br />
      In conclusion, I hope you understood the different between being a{" "}
      <b>BFS-person</b> and a <b>DFS person</b>. your awareness to this subject
      alone, will help you better navigate between your tasks, and hopefully
      make you a better developer or a developer's team lead.
      <br />
      <br />
      Have a nice coding!
    </div>
  );
}

function Perks() {
  return (
    <div>
      <h5>
        My (possibly) amusing anecdotes on how the programming world looks from
        a child's eye, in Israel of 2005, together with some
        original-retro-Photoshop craft by me, and, what should you do when you
        meet a child with an extraordinary passion for technology?{" "}
      </h5>
      Back in 2005, I started my way in programming with studying some basic Web
      technologies. you know, HTML, CSS, JS, the usuals. This sounds about
      right, since I am now a software engineer. Only, I was 11 years old back
      then.
      <br />
      <br />
      <ModalImg src={AboutMe} className="modal-pic" />
      <br />
      It's funny to see that besides my favorite color, not a lot has changed
      (It's turquise now, by the way)
      <br />
      <br />
      <h4>CD, The early days</h4>
      Oh, these were funny days, where my CI/CD process included manually
      uploading bunch of files to a hosting site, and version rollout included
      putting a "under construction" sign until I'm done with the process.
      <br />
      <ModalImg src={Shipuzim} className="modal-pic" />
      <br />
      <br />
      <h4>You're probably wondering how I got here</h4>
      I'm not sure what led me to this point where I study programming all by
      myself when other kids played with dolls. None of my family worked in the
      high-tech industry. I was just a child who liked very much to create new
      stuff, to draw, to paint, to build. and I guess I figured out at some
      point that programming IS some sort of creation itself.
      <br />
      <br />
      <h4>Sababa</h4>
      My sharp grandfather, however, is a surprisingly technological man. when
      other elderly were afraid of the internet, he surfed there freely. In my
      website I used to have a "Guest book", where people can leave me messages.
      There was one secret fan of mine, that was identified as "Sababa" (Sababa
      is the equivalent of "cool/alright!"), always left nice messages about how
      cool the website is. It took me years to figure out is was him all along
      ("Saba ba" also means "grandpa's here"!)
      <br />
      <br />
      <h4>Magic</h4>I didn't know any English by then, and didn't have someone
      to tutor me. So I sticked to the one website that had Hebrew materials,
      and went over the tutorials there. One of the tutorials was for{" "}
      <code>XML</code>. I remember there were examples for stuff like{" "}
      <code>
        {"<"}Apple {"/>"}
      </code>
      ,{" "}
      <code>
        {"<"}Circle color="red" {"/>"}
      </code>
      , and I was sure it was some kind of a <b>magic language</b>, where you
      could write <b>anything</b>, open in a browser and it will come true!
      well, just like the letter from Hogwarts never came, so did this
      <br />
      <br />
      <h4>First name dropping ever</h4>
      It's funny about CSS; I wasn't familiar with this concept in the
      beginning. I remember wanting to have my website in 4 different colors,
      and the user is able to choose in what color to see the website in. The
      rational thing to do in my opinion was to{" "}
      <i>duplicate the whole website 4 times</i>, each time with different color
      tags (you remember the html color in tags?{" "}
      <code>
        {"<"}body bgcolor{"/>"}
      </code>
      ,{" "}
      <code>
        {"<"}bgsound{"/>"}
      </code>
      ) . There was one kid I knew that knew how to code. I once told him about
      my new feature, all excited. He rolled his eyes, and said, "But why aren't
      you just using CSS?" oh, the shame.
      <table>
        <tr>
          <td>
            <img src={IB} />
          </td>
          <td>
            <img src={IG} />
          </td>
        </tr>
        <tr>
          <td>
            <img src={IO} />
          </td>
          <td>
            <img src={IP} />
          </td>
        </tr>
      </table>
      <br />
      <br />
      When I think about it now, I don't regret this <b>years</b> spent in front
      of the computer as a kid, it definitely helped me later to be accepted to
      the excellent 8200 unit in the military and from there to Microsoft etc.
      However, I do wonder: what would have happen if I had a mentor, a tutor,
      someone to guide me at this time? where could have I reach?
      <br />
      <br />
      So my message to you is - a. of course expose children you know to this
      wonderful platform, that is coding. and b. more importantly, if you
      identify around you a kid who is a crazy enthusiast about something,
      anything, programming, dinosaurs, dancing, you name it. Encourage him or
      she, keep it <b>fun</b> for them (please don't ruin it with university
      classes.. ) and try to provide him the best tools to carry on. Who knows,
      it might pay off for them later on in their lives.
    </div>
  );
}

export const posts = [
  {
    id: "introverts-guide-to-tech-presentations",
    title: "The introvert's guide to giving great tech presentations",
    content: <PublicSpeakingTips />,
    cover: Coding,
    date: "Sep 07 2024",
    badges: [Badges.SoftSkills],
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "dashboards-101",
    title: "Web Dashboards 101",
    content: <Dashboards101 />,
    cover: Coding,
    date: "Apr 25 2024",
    badges: [
      Badges.Software,
      Badges.Architecture,
      Badges.Backend,
      Badges.Frontend,
    ],
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "misused-backend-terms",
    title:
      '"You keep using that word. I don’t think it means what you think it means" - Backend terms we are all using erroneously',
    content: <TermsWeUseWrong />,
    cover: Coding,
    date: "Mar 23 2024",
    badges: [Badges.Backend],
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "figma-for-engineers",
    title: "Figma for Engineers - My Talk in Reversim Summit 2024",
    content: <FigmaForEngineers />,
    cover: Coding,
    date: "Mar 23 2024",
    badges: [Badges.Software, Badges.UXUI],
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "get-things-done",
    title:
      "Everything Everywhere All at Once: Productivity Tips to Help You Get Everything Done",
    content: <Productivity />,
    cover: Coding,
    date: "Mar 1 2024",
    badges: [Badges.Productivity],
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "react-product-tour",
    title: "The Magical Mystery (Product) Tour",
    content: <MagicalMysteryTour />,
    cover: Coding,
    date: "Feb 3 2024",
    badges: [Badges.Software, Badges.Frontend],
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "microservices-chocolate-factory",
    title: "Microservices and the Chocolate Factory",
    content: <MicroservicesChocolateFactory />,
    cover: Coding,
    badges: [Badges.Software, Badges.Architecture],
    date: "Jan 2 2024",
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "mc-full-checklist",
    title: "The Complete Management Console checklist",
    content: <MCChecklist />,
    cover: Coding,
    date: "Dec 03 2023",
    badges: [Badges.Software],
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "em-must-reads",
    title: "3 must-reads for the new Engineering Manager",
    content: <MustReads />,
    badges: [Badges.Management],
    cover: Coding,
    date: "Nov 11 2023",
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "sso",
    title: "SOS, I need SSO!",
    content: <SSOImg />,
    date: "Sep 30 2023",
    badges: [Badges.Software, Badges.Backend, Badges.Security],
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "clean-code",
    title:
      "Level up your coding and review skills: The Clean Code principles you must own",
    content: <CleanCode />,
    badges: [Badges.Software],
    cover: Coding,
    date: "Jul 21 2023",
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "externally-configured-user-interface",
    title:
      "Running at the speed of light: Externally-configured UI and why you need it",
    content: <ECUI />,
    badges: [Badges.Software, Badges.Frontend, Badges.Backend],
    cover: Coding,
    date: "Dec 10 2022",
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "scrum-master-lessons-learned",
    title: "5 things I wish I knew when started mastering Scrum Ceremonies",
    content: <Scrum />,
    badges: [Badges.Management, Badges.Software],
    cover: Coding,
    date: "Oct 28 2022",
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "react-aws-s3",
    title: "Upload files from ReactJS to AWS S3 in no time",
    content: <ReactAWSS3 />,
    date: "Mar 12 2022",
    badges: [Badges.Software, Badges.Frontend],
    hideCover: true,
    feed: true,
  },
  {
    id: "design-customer-api-2",
    title:
      "How to design a user-facing API interface (Or: why REST isn't the answer to everything) 2/2",
    content: <ApiDesign2 />,
    badges: [Badges.Software, Badges.Backend],
    date: "Feb 25 2022",
    hideCover: true,
    feed: true,
  },
  {
    id: "design-customer-api",
    title:
      "How to design a user-facing API interface (Or: why REST isn't the answer to everything) 1/2",
    content: <ApiDesign />,
    badges: [Badges.Software, Badges.Backend],
    date: "Feb 25 2022",
    homepage: true,
    hideCover: true,
    feed: true,
  },
  {
    id: "the-perks-of-being-a-kid-programmer",
    title: "The perks of being a kid-programmer",
    hideCover: true,
    badges: [Badges.Personal],
    content: <Perks />,
    date: "December 11, 2021",
    homepage: true,
    feed: true,
  },
  {
    id: "bfs-dfs",
    title: "BFS persons are from March, DFS from Venus",
    cover: Coding,
    hideCover: true,
    content: <BFS />,
    badges: [Badges.Productivity],
    date: "Nov 2021",
    feed: true,
  },
];
