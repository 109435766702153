import React, { Component, useState } from "react";
import OneConcept from "./assets/one-concept.png";
import NoBullets from "./assets/no-bullets.png";
import ActionItems from "./assets/action-items.png";
import MeSpeaking from "./assets/me-speaking.png";
export default function PublicSpeakingTips() {
  return (
    <div>
      <article id="918569f2-8a74-4c85-becc-19dbc42e018d" className="page sans">
        <div className="page-body">
          <p id="9a95667c-d4f4-443a-98d1-25edef63b927" className="">
            Talking in front of an audience is not natural for me. Back in
            school, when I wanted to participate in class, I remember my face
            turning red just from thinking about it, my heart beating fast, and
            in most cases, I eventually didn’t even participate. This is a
            familiar story for every shy child.
          </p>
          <p id="94b0bcad-73ed-4cd4-9d4d-82da072c0923" className="">
            However, I refused to let the introvert in me determine my life’s
            course, and occasionally put myself in situations where I had to
            speak in front of people. I became an officer during my military
            service, presented in my workplace many times, and a few months ago,
            I even lectured, for the first time in my life,{" "}
            <a href="https://www.youtube.com/watch?v=uHj5e7Hmfeo">
              <span style={{ borderBottom: "0.05em solid" }}>
                in a very big tech conference in Tel-Aviv
              </span>
            </a>
            .
          </p>
          <p id="8c2798ff-9584-4f92-a906-e7b3eff7917b" className="">
            I have come a very long way: workshops, courses, and practices, and
            the good news is: you can get so much better at public speaking if
            you want to.{" "}
          </p>
          <h4 id="5e801d78-def1-48a0-9c2e-ac6ee2a9e51e" className="">
            I do have an unfair advantage - I ❤️ Building Presentation Slides
          </h4>
          <p id="bc92b1e7-53b9-43dd-97ff-ee229e91e4d7" className="">
            This odd passion of mine goes way back, to when I was 7 years old
            (yep, this is not a mistake). It was at that age when, my mother
            taught me how to use Microsoft Office software - she was a teacher,
            learned how to it them at this time, and this was her way of
            practicing. So, I got obsessed with PowerPoint and started to build
            presentations on any topic in the world. My masterpiece was, what I
            called, “the 100 slides Presentation”, which was an experimental art
            mix of endless animations and sounds, like only Office 98 could
            provide. SO much better than Barbies!
          </p>
          <p id="d427ac6e-2172-4a17-a7b3-d11d819e0162" className="">
            I understand that not everyone likes to create slides like I do, but
            it’s necessary to embrace the importance of a good set of
            presentation slides.
          </p>
          <p id="8ce5fd3a-1336-4220-8829-6f7d9492d02e" className="">
            A good slide is your best friend when presenting, especially if
            you’re still not giving speeches in your sleep: it keeps you and the
            audience focused, reminds you what you wanted to talk about, and is
            appealing and funny, yet informative at the same time.{" "}
            <strong>It gives you confidence</strong>. Therefore I believe it’s
            crucial to discuss building the correct presentation.
          </p>
          <p id="dd1a6e71-7d24-4112-8960-633f82e8cdb8" className="">
            However, consider well when it’s required to make presentation
            slides. If it’s an informal meeting, or if the meeting is more a
            discussion than tranferring knowledge, then you can skip the
            slides-making part.{" "}
          </p>
          <h4 id="8d18d3b7-0883-4237-ad42-9b899607bccd" className="">
            Tips for every part of the way
          </h4>
          <p id="116a5b1e-f661-4eef-a3f9-9f0518400839" className="">
            The rest of this article is kind of a “cheat sheet” for helping you
            give a good presentation, from tips I gathered in all workshops and
            courses I’ve been through the past years.
          </p>
          <p id="52e72640-ff9f-4e05-9080-3645f5efa8d0" className="">
            I divided the presentation-making plan into 2 parts: “
            <strong>Before</strong>” and “<strong>During</strong>”. “
            <strong>Before</strong>” is the part where you consolidate and build
            your presentation’s content. The “<strong>During</strong>” is all
            the things you should consider while you are presenting your
            content.
          </p>
          <h3 id="4515080a-2b3c-4ee9-8b6f-82081c401400" className="">
            Before
          </h3>
          <p id="8fa74e5b-6511-4543-a596-4027f8a5cd84" className="">
            <strong>Before you start working on the content</strong>
          </p>
          <p id="db41aaca-02a3-4df2-9406-3bf968fe1483" className="">
            <strong>“What’s in it for me” - </strong>Very famous concept in
            public speaking. You should consider the 1-3 concepts you want your
            audience to take from your lecture. In other words, what are the
            audience takeaways? how will your lecture help them? Why should they
            listen to you?
          </p>
          <p id="a28470dc-a7be-4dc2-96c1-9ca8f429fedf" className=""></p>
          <p id="f392ee21-3f55-4034-986a-960bb0aff5a5" className="">
            <strong>Presentation structure</strong>
          </p>
          <ol
            type={1}
            id="3920bbc5-852c-464f-b0cc-5615471c4567"
            className="numbered-list"
            start={1}
          >
            <li>
              <strong>Strong beginning</strong>: a “hook” is very important in
              slides, it can include a personal story, it can be something funny
              like a joke or an anecdote, and it can be anything else you find
              captivating. Its target is to attract the audience’s attention
              right at the beginning, like bait.
            </li>
          </ol>
          <ol
            type={1}
            id="4a088121-df2a-426f-8756-efafa6a01e50"
            className="numbered-list"
            start={2}
          >
            <li>
              Discuss the <strong>problem </strong>(AKA “The Motivation”)
              <strong> </strong>before you talk about the solution
            </li>
          </ol>
          <ol
            type={1}
            id="62f606c6-3707-4a63-bbf2-44bdbac91cd5"
            className="numbered-list"
            start={3}
          >
            <li>
              Every few slides, summarize <strong>where are we </strong>standing
              &amp; what have we learned so far. Generally, assume that your
              audience has an issue with concentrating (because they do. we all
              do).
            </li>
          </ol>
          <ol
            type={1}
            id="8a1b6474-6a82-4b7d-82de-f29c4eb5dc67"
            className="numbered-list"
            start={4}
          >
            <li>
              Last slides: Repeat the “what’s in it for me” and mention how we
              achieved it in the lecture<strong>.</strong>
            </li>
          </ol>
          <ol
            type={1}
            id="5f0d4932-227d-4460-bd5f-6fa99d2efdbf"
            className="numbered-list"
            start={5}
          >
            <li>
              <strong>Live demos</strong> / Live coding are stressful and{" "}
              <strong>tend to fail in real time</strong>. Use a recording where
              possible.
            </li>
          </ol>
          <p id="50885cc9-47e5-43cc-84d0-f7308bded353" className=""></p>
          <p id="0521e8f8-5928-4763-8451-127a6b7bab05" className="">
            <strong>Slides design</strong>
          </p>
          <ol
            type={1}
            id="f1ca6303-20ed-422c-b9d6-e7eb261cdeb9"
            className="numbered-list"
            start={1}
          >
            <li>
              <strong>One concept </strong>per slide - No bullets, if possible.
              I know, you are used to writing a long list of bullets. But they
              are distracting and not helping you or the audience. A picture and
              a huge title are better. Or, if you must, a 1-2 word bullets that
              you show one at a time. I love using icons as bullets as
              demonstrated below.
            </li>
          </ol>
          <ol
            type={1}
            id="fe1b7667-1ca2-40b2-91e0-8a849e66c027"
            className="numbered-list"
            start={2}
          >
            <li>
              <strong>The slides’ design </strong>should be{" "}
              <strong>consistent </strong>and <strong>not distracting</strong>
            </li>
          </ol>
          <ol
            type={1}
            id="07fe363c-f548-4c53-9e41-df21736dbf2f"
            className="numbered-list"
            start={3}
          >
            <li>
              <strong>Highlights </strong>keep the audience focused. If there’s
              a lot of data in one slide (graph, architecture, screen
              recording), <strong>highlight only what matters</strong>.
            </li>
          </ol>
          <ol
            type={1}
            id="de844991-c8cb-4e78-9805-095c2211683a"
            className="numbered-list"
            start={4}
          >
            <li>
              <strong>Show a “progress bar”</strong>: Show the agenda of the
              presentation at the beginning and during the lecture. Help people
              get back on the train in case they missed a few sentences.
            </li>
          </ol>
          <ol
            type={1}
            id="aa0fd40a-fc36-4083-8b60-7c52cd1f306c"
            className="numbered-list"
            start={5}
          >
            <li>
              <strong>Code</strong>: if applicable, show it as huge as possible,
              with highlights on every line you present. Use Syntax
              highlighting.
            </li>
          </ol>
          <ol
            type={1}
            id="e35c5b07-fcd7-4f87-ad62-5530e050bc45"
            className="numbered-list"
            start={6}
          >
            <li>
              <strong>Displaying data</strong>: Think of{" "}
              <strong>one significant number </strong>you can show instead of a
              chart. Charts are not as effective in slides.
            </li>
          </ol>
          <p id="92b14a8b-368a-4027-a4d2-a0ba7f421fe3" className=""></p>
          <p id="a15d23fb-7408-416c-ab18-aaf7539024b8" className="">
            Example for “One concept per slide”
          </p>
          <figure id="1e14b67d-df8c-434f-b5e0-340ccb283241" className="image">
            <a href="Untitled.png">
              <img src={OneConcept} style={{ width: "100%" }} />
            </a>
          </figure>
          <p id="8511c8f4-3554-4895-8159-76f474ac3f16" className=""></p>
          <p id="337cf9d2-69a5-484e-ab2d-e8f0dd7d0840" className="">
            Example for “Bullets with no bullets”
          </p>
          <figure id="7b861430-4e2c-4eaf-b181-5397b7f04d6f" className="image">
            <a href="Untitled%201.png">
              <img src={NoBullets} style={{ width: "100%" }} />
            </a>
          </figure>
          <p id="b11ceba1-2758-4f3a-9197-21314b00f0c0" className=""></p>
          <p id="6fff13c9-6dfe-4cac-bcd4-8272cb14c94a" className="">
            <strong>Audience Engagement</strong>
          </p>
          <p id="69ab0eaf-3bab-4d8e-8e44-74e3491f3d8c" className="">
            This part can be either in the “Before” or “During”, but I decided
            to include it here as the planning of the engagement happens before
            the lecture starts, in the planning phase.
          </p>
          <ol
            type={1}
            id="7ef8cd03-f38e-451e-a4f8-da2af1be2496"
            className="numbered-list"
            start={1}
          >
            <li>
              <strong>A gauge of hands</strong>: This is a good tool to know
              your audience and to keep them engaged during the presentation.
              “How many people here already used X?” etc. Don’t push it though,
              1-2 gauge is enough.
            </li>
          </ol>
          <ol
            type={1}
            id="fcb42ee9-b5ce-4299-863d-6753fae59edf"
            className="numbered-list"
            start={2}
          >
            <li>
              <strong>Action items</strong>: Give the audience{" "}
              <strong>action items </strong>they can do afterward. This will
              increase the chance they will take something from the lecture.
            </li>
          </ol>
          <ol
            type={1}
            id="14a20184-61f5-4ae1-8353-38db511920f3"
            className="numbered-list"
            start={3}
          >
            <li>
              <strong>Summarize and send later</strong>: During the lecture, the
              audience shouldn’t be focused on memorizing / writing, because
              they won’t listen. You can create a QR code that leads to your
              slides in a drive so people can scan it and get to your content.
            </li>
          </ol>
          <p id="5d96bad6-f8f8-4070-815e-5e4df8320310" className=""></p>
          <p id="048c6009-8c68-4fd7-935b-fad2d55553ec" className="">
            Example for action items + QR
          </p>
          <figure id="732656e8-d8f6-4e5c-b77e-d574e2770c26" className="image">
            <a href="Untitled%202.png">
              <img src={ActionItems} style={{ width: "100%" }} />
            </a>
          </figure>
          <h3 id="11221b0c-d169-4981-8331-ab278a966d80" className="">
            During
          </h3>
          <p id="84c5fb28-d8f1-4e3e-a1ab-b198894037cb" className="">
            <strong>A few minutes before your lecture starts</strong>
          </p>
          <ol
            type={1}
            id="5c177571-7150-4b0c-a5d7-2bd972f76925"
            className="numbered-list"
            start={1}
          >
            <li>
              Go <strong>warm yourself up</strong>, especially if you sat all
              day (which is common if you’re in tech). Bounce and stretch like
              before an exercise. You can’t expect to be energized without this!
              Also, it helps a lot with the pressure.
            </li>
          </ol>
          <ol
            type={1}
            id="06225bdc-4b2f-49cf-b4b6-c1dcc3afbb58"
            className="numbered-list"
            start={2}
          >
            <li>
              <strong>Memorize/rehearse the first few lines </strong>of the
              presentation by heart. It will help get you into the flow of the
              lecture more easily.
            </li>
          </ol>
          <p id="5ee66803-c6eb-4ed8-9150-0214da06d9fb" className=""></p>
          <p id="7b5783d7-b222-46d2-8c83-fad6048f529c" className="">
            <strong>
              When you are on stage, one second before starting to talk
            </strong>
          </p>
          <p id="53c86031-e788-4b4e-a934-cc91b6605fa2" className="">
            This is the most nerve-wracking moment, so this part is more like a
            meditation than actual tips and is surprisingly similar to handling
            a panic attack.
          </p>
          <ol
            type={1}
            id="80c9f8a2-2439-43ba-b22f-119c9178e426"
            className="numbered-list"
            start={1}
          >
            <li>
              Think about what you physically feel right now. do you feel your
              heartbeat? Are you sweaty? are you trembling? Understand that this
              is welcome and good. Adrenaline gives you this extra boost of
              energy and improves your performance overall.
            </li>
          </ol>
          <ol
            type={1}
            id="5ec8b0af-8333-4827-9ca5-82d4044de053"
            className="numbered-list"
            start={2}
          >
            <li>
              Relax your shoulders. Relax the tension in your eyes. Acknowledge
              your feet. Feel your breath.
            </li>
          </ol>
          <ol
            type={1}
            id="e0614eba-70eb-45e2-82ed-ee7d73e8c36f"
            className="numbered-list"
            start={3}
          >
            <li>Repeat the few lines you memorized in your head.</li>
          </ol>
          <p id="9630a1be-de67-4fb8-a2ca-ee64358d48a5" className=""></p>
          <p id="df2ba5fa-0a56-4152-a03e-e025e68754fb" className="">
            <strong>Body language - during the lecture</strong>
          </p>
          <ol
            type={1}
            id="718cfc70-5c7e-4809-ab34-90caf20c05c3"
            className="numbered-list"
            start={1}
          >
            <li>
              Show <strong>enthusiasm</strong>! If you do this, everything else
              is pretty much forgivable
            </li>
          </ol>
          <ol
            type={1}
            id="ae5a5c8d-3af5-4d9a-befa-431919e06100"
            className="numbered-list"
            start={2}
          >
            <li>
              <strong>Pause </strong>after an important statement. It gives the
              audience a second to absorb, and for you to breathe. This is
              subtle, don’t pause for too long or it will become weird.
            </li>
          </ol>
          <ol
            type={1}
            id="62465dea-d670-49ff-968a-87e243b02be2"
            className="numbered-list"
            start={3}
          >
            <li>
              Look at the faces in the crowd, especially of people that make you
              feel confident and relaxed.
            </li>
          </ol>
          <ol
            type={1}
            id="65eade7b-d7bc-4e28-a8cd-f157157f7c1e"
            className="numbered-list"
            start={4}
          >
            <li>
              <strong>Smile!</strong>
            </li>
          </ol>
          <p id="1be44512-0227-4a09-9e53-279bc7dba6cc" className=""></p>
          <p id="1367ae27-3b8f-4320-9b8d-8e29d09a29be" className="">
            This is me! do you feel the enthusiasm?
          </p>
          <div>
            <img src={MeSpeaking} style={{ width: "100%" }} />
          </div>
          <p></p>
          <h4 id="fbd74f12-459b-48e9-85e3-fc95be113c3f" className="">
            <strong>General tips</strong>
          </h4>
          <ol
            type={1}
            id="f4d03a43-5622-4146-afd1-b5511bcb7b76"
            className="numbered-list"
            start={1}
          >
            <li>
              Practice makes perfect. if in doubt, practice your lecture again
              until you’re confident enough that you know all the material
              perfectly.
            </li>
          </ol>
          <ol
            type={1}
            id="c79140ee-a65f-43eb-8297-127b0d8f268b"
            className="numbered-list"
            start={2}
          >
            <li>
              Something I once heard in a lecture and liked: “Talking to an
              audience is like covering with a thick blanket at night. At first,
              it’s cold and you warm the blanket more than it warms you, but
              eventually, you get heat back from it”. This is the love and
              appreciation you get from the audience after you teach them
              something new or inspiring.
            </li>
          </ol>
          <h3 id="2cbe68ee-b32a-4426-b853-76c220b3c1d3" className="">
            Summary
          </h3>
          <p id="d2034f34-31fd-4d71-9530-b37235cb4dfe" className="">
            I hope you gathered some good tips that help you pass your next
            presentation easily and comfortably. At first, when you try to apply
            them, it’s going to feel like when you first learned how to drive: “
            <em>
              How am I supposed to look at the road, use the turn signals, shift
              a gear, and change a lane safely all at the same time?!
            </em>
            ”. But as you do it over and over again, it starts to become natural
            for you.
          </p>
          <p id="1f88744c-ae58-40b4-95d5-6c6b3b07d95d" className="">
            I’m sure I also have a lot more to learn myself on this topic, I’m
            not a TED speaker or anything. But I believe this was a good point
            to pass some of my knowledge to those who need it.
          </p>
          <p id="7db08c5a-2fe2-45b3-8b43-2459d66c7b4c" className="">
            Feel free to consult with me always, and good luck to you with your
            next presentations!
          </p>
          <p id="f3c0d10e-066d-42d3-851b-e68e88896b71" className=""></p>
        </div>
      </article>
    </div>
  );
}
