import Idf from "./assets/about/idf.png";
import Microsoft from "./assets/about/microsoft.png";
import TAU from "./assets/about/tau.png";
import Island from "./assets/about/Island.jpg";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

export function About() {
  return (
    <Fade bottom cascade>
      <div>
        <h4>Omer Shlifka Gurvitz, tel-aviv</h4>
        <h5>
          Software developer ·{" "}
          <span style={{ color: "#29a09a" }}>Engineeeing manager</span>{" "}
        </h5>
        <div style={{ marginBottom: 20 }}>
          I am a software engineer from Tel-Aviv, Israel
        </div>
        <div className="resume">
          <div>
            <a
              href="https://www.linkedin.com/company/island-io/"
              target="_blank"
            >
              <img width={100} src={Island} />
            </a>
          </div>
          <div>
            <h5>2022-Present</h5>
            <h4>Engineering manager </h4>
            <h5>2021-2022</h5>
            <h4>software engineer </h4>
            <h5>
              <a
                href="https://www.linkedin.com/company/island-io/"
                target="_blank"
              >
                Island
              </a>{" "}
            </h5>
            <a href="https://www.island.io/" target="_blank">
              <u>Work will never be the same.</u>
            </a>
          </div>
        </div>

        <div className="resume">
          <div>
            <img width={100} src={Microsoft} />
          </div>
          <div>
            <h5>2017-2021</h5>
            <h4>full stack developer </h4>
            <h5>microsoft | microsoft Cloud app security (adallom) </h5>
            Working in such a huge corp exposed me to developing in the highest
            scale and standards possible. <br />
            <small>
              ReactJS | AngularJS | Python | Django | Flask | Mongodb | Azure
            </small>
          </div>
        </div>

        <div className="resume">
          <div>
            <img width={100} src={TAU} />
          </div>
          <div>
            <h5>2018-2020</h5>
            <h4>b.sc computer science</h4>
            <h5>tel aviv university </h5>
            I am so grateful for studying in this non-compromising institute,
            making my brain wheels squeek like never before. <br />
          </div>
        </div>

        <div className="resume">
          <div>
            <img width={100} src={Idf} />
          </div>
          <div>
            <h5>2013-2017</h5>
            <h4>software engineer & team lead </h4>
            <h5>
              Israely Ingelligence Corps |{" "}
              <a href="https://en.wikipedia.org/wiki/Unit_8200" target="_blank">
                Unit 8200
              </a>{" "}
            </h5>
            Discharged as a Leutenant.
          </div>
        </div>
      </div>
    </Fade>
  );
}
