import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { auth, googleProvider, db } from "./firebaseConfig";
import {
  collection,
  addDoc,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore/lite";
import Heart from "react-animated-heart";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import { posts } from "./posts";
import Fade from "react-reveal/Fade";

async function getLikes(postId, email) {
  const q = query(collection(db, "likes"), where("author", "==", email));
  let likeResult = false;
  let refResult = null;

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const likeData = doc.data();
    if (postId === likeData.post) {
      likeResult = likeData.isLiked;
      refResult = doc.ref;
      return;
    }
  });

  return [likeResult, refResult];
}

function PostSummary({ post, summary }) {
  const [comment, setComment] = useState("");
  const [commentState, setCommentState] = useState(false);
  const [isLiked, setLike] = useState(false);
  const [showForm, toggleForm] = useState(false);
  const [currentUser, setUser] = useState(auth.currentUser);

  useEffect(async () => {
    if (!currentUser) return;

    const [liked, _] = await getLikes(post.id, currentUser.email);
    setLike(liked);
  }, [currentUser]);

  const signInWithGoogle = async (callback) => {
    try {
      await signInWithPopup(auth, googleProvider);
      setUser(auth.currentUser);
      callback(auth.currentUser);
    } catch (err) {
      console.error(err);
    }
  };

  const submit = async (user) => {
    try {
      const docRef = await addDoc(collection(db, "comments"), {
        author: user.email,
        comment: comment,
        post: post.id,
      });
      console.log("Document written with ID: ", docRef.id);
      setComment("");
      setCommentState(true);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const like = async (user) => {
    const [liked, likeRef] = await getLikes(post.id, user.email);
    try {
      if (likeRef) {
        updateDoc(likeRef, {
          author: user.email,
          isLiked: !isLiked,
          post: post.id,
        });
      } else {
        const docRef = await addDoc(collection(db, "likes"), {
          author: user.email,
          isLiked: !isLiked,
          post: post.id,
        });
        console.log("Document written with ID: ", docRef.id);
      }
      setLike(!isLiked);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <Fade bottom>
      <div data-wow-duration="10s" data-wow-delay="10s">
        <div className="blogpost-date">{post.date}</div>
        <div style={{ marginBottom: 8, marginTop: 20 }}>{post.badges}</div>
        <div>
          <h3>
            <a href={`feed/${post.id}`}>{post.title}</a>
          </h3>
        </div>
        {!post.hideCover && (
          <div>
            <Link to={`feed/${post.id}`}>
              <img src={post.cover} className="post-cover" />
            </Link>
          </div>
        )}
        <div className={summary && "line-clamp"}>{post.content}</div>
        <div className="divider"></div>

        {!summary && (
          <div>
            <div style={{ display: "flex", gap: 8 }}>
              <i
                class={`bi bi-heart-fill`}
                onClick={() => {
                  if (!currentUser) {
                    signInWithGoogle(like);
                    return;
                  }
                  like(currentUser);
                }}
                style={{
                  fontSize: 20,
                  cursor: "pointer",
                  color: isLiked ? "#e2264d" : "gray",
                }}
              ></i>

              <i
                class={`bi bi-chat-fill`}
                onClick={() => toggleForm(!showForm)}
                style={{
                  fontSize: 20,
                  marginRight: 8,
                  cursor: "pointer",
                  color: "gray",
                }}
              ></i>
            </div>
            {showForm && (
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Tell me what you think!</Form.Label>
                  <Form.Control
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                    as="textarea"
                    rows={3}
                  />
                  <Button
                    variant="primary"
                    disabled={commentState}
                    onClick={() => {
                      if (!currentUser) {
                        signInWithGoogle(submit);
                        return;
                      }
                      submit(currentUser);
                    }}
                  >
                    Submit
                  </Button>
                </Form.Group>
                {commentState && " Thanks :)"}
              </Form>
            )}
          </div>
        )}
      </div>
    </Fade>
  );
}

export function Feed() {
  return (
    <div style={{ marginTop: 20 }}>
      {posts
        .filter((post) => post.feed)
        .map((post) => (
          <PostSummary post={post} summary />
        ))}
    </div>
  );
}

export function Post() {
  const { postId } = useParams();
  const post = posts.find((post) => post.id === postId);

  if (!post) {
    return "No such post :-(";
  }

  return (
    <div style={{ marginTop: 20 }}>
      <PostSummary post={post} />
    </div>
  );
}
