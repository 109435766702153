import React, { Component, useState } from "react";
import "./ApiDesign.css";

export function ApiDesign() {
  return (
    <article id="e643ef94-7d57-4423-a5f3-fa330976c23c" className="page sans">
      <div className="page-body">
        <p id="0147b8f4-6e26-4304-8ee4-01c910d2aa0e" className>
          So you got a task at work to build a REST API for your customers, that
          will be comfortable. But where do you start? Don’t worry, you’re in
          the right place!
        </p>
        <h4 id="547f992a-af73-4277-a10c-317bf3a4e8fe" className>
          First thing’s first: What’s an API, anyway?
        </h4>
        <p id="8150cded-ab83-42ca-ba45-ebe276798f0c" className>
          We hear this term A LOT, usually in contexts of{" "}
          <strong>HTTP API</strong>. API is the acronym for <strong>A</strong>
          pplication <strong>P</strong>rogramming <strong>I</strong>nterface,
          and that is the language that allows two applications to talk to each
          other.
        </p>
        <p id="e4e3b9f0-a305-4d2a-ab27-44159ddf2f5f" className>
          For example, If I am an application that provides TV info, My API will
          describe the ways of communicating with me. in this case:
        </p>
        <ul id="51a2222c-0f59-4f61-a059-d38fe1b624f9" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            You can ask me<em>: Hey how’s the show “Gilmore Girls”?</em>
          </li>
        </ul>
        <ul id="626f5b3c-f733-4c4d-b821-9575e36fcdea" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            <em>
              And I’ll answer: The show is: good/bad. (Of course, the accurate
              answer here is “best show everrr!11”)
            </em>
          </li>
        </ul>
        <h4 id="9b18da2d-8f74-4c34-b0c1-75a75cb3cf2c" className>
          HTTP API
        </h4>
        <p id="5d13d660-d7d4-4ffd-8032-e676e8cec0eb" className>
          This API above is described in English, but it’s 2022 and most
          applications communicate between them in <strong>HTTP</strong>, which
          is a protocol&nbsp;for fetching resources from remote servers (such as
          HTML documents). Don’t panic! the resource is just something we
          provide to the one who requests it. For example, we use it to
          communicate between a browser to a server, between services in a
          Kubernetes cluster, etc etc.
        </p>
        <p id="52c0edff-9bf1-4a32-8472-3b9227f5475f" className>
          If we would describe our API in the HTTP language, we could expose the
          following API request:
        </p>
        <pre id="c585d10b-5929-4b22-9cc1-f7c03b478ed1" className="code">
          <code>GET how-is-the-show/TOO_HOT_TO_HANDLE</code>
        </pre>
        <p id="dca90049-836d-4107-b14a-e5471b64cafe" className>
          And we could describe the answer to this request as
        </p>
        <pre id="f293612f-ca1e-4432-b1ff-ca9762e17031" className="code">
          <code>
            200 {"{"}result: "SO Bad"{"}"}
          </code>
        </pre>
        <p id="f6f87154-47a2-4adf-9213-d03b612be539" className>
          This means the request has been processed successfully, and the show
          is bad. or, if the show is “peaky-blinders-season-9”, we will get a -
        </p>
        <pre id="daaa4dcd-8a72-4d13-b546-bd92877fe685" className="code">
          <code>
            404 {"{"}error: "Show not found"{"}"}
          </code>
        </pre>
        <p id="04e45bcb-7004-4834-be66-dfed764b101b" className>
          This tutorial assumes you already are familiar with HTTP, but if you
          need a refreshment, A short tutorial to some HTTP methods could be
          found here{" "}
          <a href="https://assertible.com/blog/7-http-methods-every-web-developer-should-know-and-how-to-test-them">
            https://assertible.com/blog/7-http-methods-every-web-developer-should-know-and-how-to-test-them
          </a>
          .
        </p>
        <h4 id="f492c025-6937-46f7-ab0a-51b155a88bde" className>
          What’s a customer-facing API?
        </h4>
        <p id="1971908c-defc-4705-9f65-f880bc8e4c11" className>
          Every API has a vendor and a consumer. If it’s an internal
          microservice’s API, the consumer may be another microservice. if it’s
          a website API, the consumer may be the frontend code that calls these
          requests and process them to render on the browser screen. But in
          various cases, the consumer of the API will be customers outside of
          your company. for example
        </p>
        <div className="indented">
          <ul
            id="aed8fe37-e61c-4b9d-a0ba-aea1352b4cf5"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Google Auth API</strong>: the vendor is Google and the
              consumers are a client who wants to use the “Login through Google”
              option.
            </li>
          </ul>
          <ul
            id="eeaaa170-bb0f-4712-8e72-c64baa42295a"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>AWS CLI / Boto3</strong>, the vendor is AWS and the
              consumers of the APIs are DevOps or developers who wish to manage
              their cloud resources.
            </li>
          </ul>
        </div>
        <p />
        <p id="79c55494-bc8d-4ff9-9e9a-d8f1e71f166d" className>
          In general, building an API has multiple technical aspects such as
          what is the technology we use to build the layer that exposes the API,
          authentication, and authorization, how do we manage different versions
          of the API, for backward compatibility, how do we manage the
          documentation. You can fill books with those subjects.{" "}
        </p>
        <p id="ce4fd52f-08d2-426f-97ea-b7691b562a03" className>
          However, today we will solely focus on the <strong>API,</strong> We
          are forming a language here, and it’s pretty cool. so let’s go!
        </p>
        <h3 id="9e784adc-1d4b-423b-9790-527b21f1aed0" className>
          Know your <del>enemy</del> customer
        </h3>
        <p id="e844ecfb-2518-457b-9e84-6c052742fbd9" className>
          Designing a customer-facing API is special. First, because those who
          use our API are people from outside your organization, that need to
          understand our language. They’re not your co-workers who have to deal
          with your internal API anyway and can ask you for clarifications. In
          addition, unlike writing a user interface, our users are mostly{" "}
          <strong>technical</strong>, like us, the developers, so we are talking
          the same language. however we must think about intuitivism for them,
          they need to understand what we do without us teaching them, if
          possible.
        </p>
        <p id="85f0c163-bcca-400b-a1d5-e89a55791627" className>
          So before we start, let’s understand who exactly are our customers are
          and what do they need from us.
        </p>
        <ul id="524a7003-3266-46b6-a8c3-cab4a422f477" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            <strong>How technical our users are</strong>? Can they write code,
            or just send HTTP requests using Postman? All of these will affect
            how complex and rich our API can be.
          </li>
        </ul>
        <ul id="a868180f-44e8-453b-bb79-af80f7e4235f" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            <strong>What are the actions the users are going to perform</strong>
            ? do they only read data, or also mutate the persisted data?
          </li>
        </ul>
        <ul id="f8eac751-9d19-4e8c-a3e9-d329201ab9b5" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            <strong>What amount of flexibility is needed</strong>? Do we need to
            provide an answer to a constant question, or do we need to answer
            several variations of the same questions, with different parameters?
            In our shows example, do we just need to provide a good/bad? maybe
            an option to provide the shows I loved before that?{" "}
          </li>
        </ul>
        <h3 id="c7b0e4d2-1720-4107-9533-d956c414bd6e" className>
          Summary
        </h3>
        <p id="28fce186-977a-4b2e-8876-98bda65d53d9" className>
          We learned what the term API really means, and identified the
          differences between internal API and customers API. In the next
          chapter, we will learn about practical methods and approaches for
          implementation.
        </p>
        <p id="bb5750dd-1f18-44a3-bf45-4ac97ebb1e21" className></p>
      </div>
    </article>
  );
}

export function ApiDesign2() {
  return (
    <article id="e643ef94-7d57-4423-a5f3-fa330976c23c" className="page sans">
      <div className="page-body">
        <p id="0147b8f4-6e26-4304-8ee4-01c910d2aa0e" className>
          In the previous chapter we learned what the term API really means, and
          identified the differences between internal API and customers API.
          Today we will learn about practical methods and approaches for
          implementation.
        </p>
        <h3 id="943de0c3-2618-4f20-951d-bc5d2ce021a3" className>
          Types of HTTP APIs methodologies
        </h3>
        <p id="c5d84acd-8a00-4749-a3d5-d444834f927d" className>
          Luckily, some good people already have thought of this question for us
          and have come with some very good solutions. Let’s go over some of
          them.
        </p>
        <h4 id="295686ed-732d-4975-a80d-2ce1f3259767" className>
          RESTful API
        </h4>
        <p id="53c2a40b-e544-4f94-bd16-38924e11e667" className>
          REST has become such a popular methodology, that it is often used as a
          synonym for HTTP API. You can hear a lot of devs and managers say
          “yeah, let’s write REST API” when they really just mean an HTTP API.
        </p>
        <p id="46bfa485-dda4-4a43-a85a-bbc340c7e0c9" className>
          REST (Representational state transfer🤢) is a set of rules and
          constraints for designing an API. It’s not a protocol per se, but more
          of rule of thumb.
        </p>
        <p id="11dd0715-9298-45ec-948e-2b84acc7b07b" className>
          The idea behind REST is to manage <strong>resources</strong>. A
          resource is everything the user is handling (creating, watching,
          editing, removing). For example in our shows API, a TV show is an
          asset, but also an actor can be an asset, a producer, a movie, etc.
        </p>
        <p id="42763bcc-48c2-49d0-89bb-e3ff9f4ff0d5" className>
          {" "}
          There are a lot of other principles on how to manage the API server
          (caching, layering and more), but since we are focusing on the
          interface, let’s examine that.{" "}
        </p>
        <p id="714bcaf0-20ec-4875-be92-7d8cd020f7bb" className>
          In REST, we always stick to the conventional http methods and http
          response codes. Like-
        </p>
        <pre id="bb975d0f-273b-43aa-88ae-daff74c18550" className="code">
          <code>
            GET /show{"\n"}200 {"{"}shows: ["gilmore-girls"]{"}"}
            {"\n"}
            {"\n"}GET /show/gilmore-girls{"\n"}200 {"{"} name: "Gilmore Girls",
            Actors: ["alexis-bledel"] {"}"}
          </code>
        </pre>
        <p id="d23c9dd5-a996-4f8e-addb-745a9957856d" className>
          And from here I can go on to get information about the actors of the
          show:
        </p>
        <pre id="9e7dbec5-64f3-4370-ae6a-fd221c622ffe" className="code">
          <code>
            GET /show/gilmore-girls/actor/alexix-bledel{"\n"}200 {"{"} name:
            "Alexix Bledel", Shows: ["A handmaid's tale", ...] {"}"}
          </code>
        </pre>
        <p id="624dc232-e043-4259-8684-9378be12852a" className></p>
        <p id="46ceb6ba-fb3a-4e54-972a-3227079344f2" className>
          <strong>Why is it good:</strong>
        </p>
        <ul id="0c8f9f91-b6e5-4c3e-92a3-afcae29a13d8" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            Our customers already saw this pattern thousands of times before
          </li>
        </ul>
        <ul id="051149eb-5a07-492b-8d7d-fee8ba3e7119" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            Supports classic CRUD operations on each asset (Create, remove,
            update, delete)
          </li>
        </ul>
        <ul id="ae422861-0b1b-4aea-8303-5d8801cd6a6b" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            Fits perfectly if the user needs to traverse the entities. we can
            change the API and it doesn’t matter them
          </li>
        </ul>
        <h4 id="83c393b6-a7db-454a-9140-894de808bcb2" className>
          GraphQL
        </h4>
        <p id="4d680d0b-6f20-41f9-8685-fb0f4abc339d" className>
          <a href="https://graphql.org/learn/">GraphQL</a> is a very powerful
          tool for building APIs, with tons of buzz around it.
        </p>
        <p id="7ab1123c-ebbb-4d3a-8d3b-8e6a401cdf0d" className>
          GraphQL (GQL) is a query language for your API. Today, each
          programming language has a GraphQL SDK that allows you to define a
          schema for your data in the database. It automatically translates GQL
          queries to the language the db understands (SQL, mostly). Pretty cool
          huh?
        </p>
        <p id="f7dc722b-b5b6-4582-b017-e0ed28d959dd" className>
          For example, if we want to get the names from Bojack Horseman TV
          shows, and the names of their characters, we will run something like
        </p>
        <pre id="313818e3-c398-4d61-a99b-0f8b8c70a23b" className="code">
          <code>
            POST /graphql {"\n"}
            {"\n"}query TvInfo($show: Show) {"{"}
            {"\n"}
            {"  "}show(show: $show) {"{"}
            {"\n"}
            {"    "}name{"\n"}
            {"    "}charachters {"{"}
            {"\n"}
            {"      "}namec{"\n"}
            {"    "}
            {"}"}
            {"\n"}
            {"  "}
            {"}"}
            {"\n"}
            {"}"}
            {"\n"}
            {"\n"}
            {"{"}
            {"\n"}
            {"  "}"show": "Bojack Horseman"{"\n"}
            {"}"}
          </code>
        </pre>
        <p id="12be54ed-cd51-439c-8b97-2669654c7a8c" className></p>
        <p id="0d1a7adf-9ef5-4fd4-86c3-390e7b6ec6b6" className>
          That’s right, the query is in the body of a POST request. Seems odd,
          but hey, so does <code>HTML</code> inside <code>JS</code>, and see
          where ReactJS is today!{" "}
        </p>
        <p id="655e9486-b7ce-470a-b814-b6ded898953b" className>
          I personally prefer not to use GraphQL for our cause specifically,
          because the learning curve is steep and it’s quite not intuitive, So I
          think it’s worth using only if your customers are heavy tech users,
          like developers.
        </p>
        <p id="9194072b-b5ac-4583-8400-580276c1a918" className>
          <strong>Why is it good:</strong>
        </p>
        <ul id="3db0e377-6ff7-42a4-b495-7416c791c0f8" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            If you need to provide complex queries and possibilities over your
            data, you get that “for free”
          </li>
        </ul>
        <ul id="7c55baa4-712f-4571-9818-d1acd089137e" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            If you don’t want to struggle with building the API and you just
            want to get it over with and go to the beach 😎 (not judging)
          </li>
        </ul>
        <ul id="5ce71c85-625e-42db-a5cf-0a6c9fbbc957" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            It has multiple SDKs for almost all databases and programming
            languages there are
          </li>
        </ul>
        <h4 id="eb8d0877-502c-49f0-b277-860e145000ca" className>
          Still not what you’re looking for? “REST-ish”, or Plain HTTP
        </h4>
        <p id="50a35629-707e-4c9b-9ad4-2a889e9b79e1" className>
          In cases you want a simple API for your customers, but you don’t
          really need to perform CRUD operations on resources, you can compose
          yourself the HTTP endpoints and responses.
        </p>
        <p id="eb5307ad-9383-433d-9687-54a9d6d85849" className>
          In this case, you have to be careful about the conventions you will be
          using. You will need to decide whether <code>POST </code>or{" "}
          <code>GET</code>, for example, for fetching data. On the one hand,{" "}
          <code>GET</code> is easier to send, because all the data is in one
          line. However it’s hard to pass complex parameters, and has a size
          limitation of 2,048 characters(!).{" "}
        </p>
        <p id="bde53cbf-ee31-49bf-86bb-b8909dcc7b47" className>
          I would suggest you in this case to still be consistent with{" "}
          <code>HTTP </code>methods and error codes, to make it easier on you
          readers. that’s why I call it REST-ish.
        </p>
        <p id="11815a3c-0e80-47b0-9912-a3acd05947c4" className>
          In addition, it’s important in this case to make sure you{" "}
          <span style={{ borderBottom: "0.05em solid" }}>
            fully understand the needs of the customers{" "}
          </span>
          and how they will be using the API. If it’s already tailor made, it
          better be good, right?
        </p>
        <p id="fe6bc6cf-966d-4603-9034-288f1d4321c3" className>
          Examples for such APIs-
        </p>
        <ul id="0206753d-619b-4c9e-bf54-d0348404eef1" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            Elastic search (TBD). Elastic search is an open source search
            solution that exposes some REST apis as well as APIs for the search,
            that like graphql sends the query inside the body.
          </li>
        </ul>
        <p id="91602081-47f9-415b-a8f6-fbd291c702e4" className></p>
        <p id="da91dddf-0ebf-46d5-ab2d-8cc9d00d950b" className>
          <strong>Why is it good:</strong>
        </p>
        <ul id="66527dcd-16a2-45a9-9781-9dd8061b102c" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            You can have special query methods that don’t fall in the category
            of any of the others, like searching in different databases and
            resources.
          </li>
        </ul>
        <ul id="cb040b4e-a3bc-4ce5-bd7b-ef5168e6a3bf" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            It fits for all customers, both technical and semi-technical
          </li>
        </ul>
        <ul id="f8aaccd0-5d9f-484c-a749-bbb3558add8e" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            You control every implementation detail of it.
          </li>
        </ul>
        <h3 id="9e684bb6-0181-4e2b-b750-38b721af983b" className>
          A case study: API for TV shows
        </h3>
        <p id="48d5d1b3-65a0-47b6-81be-932ab4f73526" className>
          Let’s say we work for some famous streaming service, and we have a job
          to export our data to analysts in another company that run some ML
          models on the data to produce insights.
        </p>
        <p id="636898c1-18ae-43cf-922d-5730946c08a5" className>
          The analysts in the company are somewhat technological, they can write
          basic scripts for example and to send CURL requests. (It doesn’t mean
          all analysts aren’t techies! chill)
        </p>
        <p id="77fcc48b-d840-4c0a-b60b-2fc21aff5d10" className>
          The basic requirements from them are:
        </p>
        <ul id="c5da71b1-6930-4997-8aae-bdf217f9a28a" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            Be able to search TV shows by dates they came out, to filter by name
            and to sort by dates.
          </li>
        </ul>
        <ul id="01c90d78-bc33-414f-8f86-d6b05bdc5114" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            Receive the name and the rate of each TV show.
          </li>
        </ul>
        <p id="25dcdf80-04eb-4d71-a83b-7eac6e255398" className>
          What method will we be using?
        </p>
        <p id="7d6193da-f7cf-4b27-b11c-074b06b02fa9" className>
          On the one hand, we have a DB resource, that is the TV show. so maybe
          we should use REST API.
        </p>
        <p id="b33de727-11ce-4267-9ffd-1a100660a095" className>
          On the other hand, the analysts need to filter data, that the proper
          model of REST don’t support. also they are not very technical so
          making them learn GraphQL won’t make my API very popular.{" "}
          <em>REST-ish </em>seems like the correct choice here.
        </p>
        <p id="7ecc1887-7953-4c0b-bd1d-0ed1cc4fcd7a" className>
          Now that we decided to use <em>REST-ish</em>, we have 2 options:
        </p>
        <p id="138ffedc-d306-42f8-8f6d-6bec541862be" className>
          The one is to have a <code>GET </code>method with <code>start</code>,{" "}
          <code>end</code> and <code>query</code> as query parameters
        </p>
        <pre id="25daf024-885b-4ef8-b5f6-2f935388872f" className="code">
          <code>
            GET
            /search?start=12-10-1994&amp;end=12-10-1994&amp;query=gambit&amp;sort=asc
          </code>
        </pre>
        <p id="35e3af8c-cab5-47fb-b07c-58d42651f5cc" className>
          The other is to have a <code>POST</code> method and to send the query
          parameters in the POST.
        </p>
        <pre id="a872d00f-a1b6-48b3-90f3-b1cb331d18de" className="code">
          <code>
            {"\n"}POST /search{"\n"}
            {"{"}
            {"\n"}
            {"  "}query: "gambit",{"\n"}
            {"\t"}start: "12-10-1994",{"\n"}
            {"\t"}end: "12-10-1994",{"\n"}
            {"  "}sort: "asc"{"\n"}
            {"}"}
          </code>
        </pre>
        <p id="7f95e3f5-a7d3-469b-a2e2-f06af9f7e29b" className>
          Both of them are fine, the <code>POST</code> one is slightly more
          comfortable to extend than the <code>GET</code>, and the{" "}
          <code>GET </code>is more comfortable to send and to play with, For our
          goal I would say we should go with the <code>GET</code> here.
        </p>
        <h3 id="c7b0e4d2-1720-4107-9533-d956c414bd6e" className>
          Summary
        </h3>
        <p id="383dacee-2da2-4c85-ba2b-a76591d6d168" className>
          We have learned how to design an API interface that is facing external
          customers, to create a comfortable, intuitive, beloved API.
        </p>
        <p id="5ef5e5af-57f9-4885-9894-bc4e7d549be9" className>
          The principles we learned are especially important with clients but
          are effective with any other human reader, whether it’s a co-worker
          like a frontend developer that uses your calls or even yourself, and
          you can even take some example to the real-world communication.
        </p>
        <p id="28fce186-977a-4b2e-8876-98bda65d53d9" className>
          After all, we are talking here about how to communicate better in
          general, and communication is the key to any relationship.
        </p>
        <p id="bb5750dd-1f18-44a3-bf45-4ac97ebb1e21" className></p>
      </div>
    </article>
  );
}
