import React, { Component, useState } from "react";
import MCAS from "./assets/mcas.png";
import MCPyramid from "./assets/pyramid.png";
export default function MCChecklist() {
  return (
    <div>
      <article id="a12f14a4-8bfb-4526-8823-0bd8bb19cd48" className="page sans">
        <div className="page-body">
          <p id="3ca8fd96-2027-4a15-9919-1acc2a57d98f" className="">
            If you're launching a B2B/B2B2C startup, your{" "}
            <strong>Management Console</strong> is a crucial part. It's the
            user-friendly interface to{" "}
            <strong>monitor and manage resources</strong> and consolidate{" "}
            <strong>information</strong>. We're focusing on{" "}
            <strong>Web Management Console</strong> apps, the go-to for SaaS
            products.
          </p>
          <p id="37387adb-386a-43cf-a65f-15a8067c67a2" className="">
            With six years in B2B/B2B2C Web Management consoles, I've noticed
            common features across all platforms. I've been involved from mature
            stages, like Microsoft Cloud App Security (Adallom post-Microsoft
            acquisition), to building almost from scratch at Island.
          </p>
          <img
            src={MCAS}
            className="modal-pic"
            style={{ width: "100%", marginBottom: 30 }}
          />
          <p id="a021ce2f-4d1f-443b-954a-f3e6ea598aa3" className="">
            (In the screenshot: Microsoft Cloud App Security. picture from
            Google)
          </p>
          <p id="fb92f248-da91-4cda-a829-f8e46c4e9fa8" className=""></p>
          <p id="1036a6f7-62dc-4ab1-9ff9-a6a313a34b87" className="">
            These experiences give me a solid grasp of essential features for
            each development phase: the <strong>essentials</strong>, features
            for <strong>Enterprise-readiness</strong> to compete with industry
            leaders, and the less glamorous ones you'd ideally exit before you
            need to deal with (💰).
          </p>
          <p id="9386184b-30c5-4231-8994-0bec618dd76c" className="">
            In this article, I've organized these insights into a pyramid. Not
            every company needs to reach the top; it depends on your product (a
            "feature" or a "platform"?), your goals (🦄?), and your clients
            (Small businesses vs. Corporations/Governments).
          </p>
          <p id="918ed776-6924-46b2-94aa-2f4444d807aa" className="">
            Let's get into it because, as you'll see, there's a lot to cover!
          </p>
          <p id="ef61a4e8-087c-4763-990f-31d6e53ac26f" className=""></p>
          <img
            src={MCPyramid}
            className="modal-pic"
            style={{ width: "100%", marginBottom: 30 }}
          />
          <h3 id="ee67f73c-9551-4c6d-acf6-bcd6094cc863" className="">
            <strong>
              <strong>The Essentials</strong>
            </strong>
          </h3>
          <p id="52795652-ccef-4040-9358-c3f673d76a34" className="">
            This lays the groundwork, shaping your product for businesses.
          </p>
          <p id="4ca690e7-b485-44ae-899b-76a6c0b65630" className="">
            <strong>Account Management</strong> - Every business using your
            product needs to <strong>register and log in</strong>, whether
            through a user/password system or alternative methods like social
            login or OTP. You can either handle logins internally or utilize a
            service like Auth0.
          </p>
          <p id="53db7a13-8b5f-4f08-9fbb-8226ebe85471" className="">
            <strong>Security (by design)</strong> - Maintaining robust security
            is critical, especially for B2B Management Consoles. Beyond
            fostering trust and ensuring continuous operation, you'll handle
            sensitive business information. If security isn't your expertise,
            consulting is wise for building features with security in mind. This
            involves validating and sanitizing all inputs and outputs,
            implementing CSP policies, and protecting against DDoS attacks.
          </p>
          <p id="c723e566-fcb9-4bf4-bd8f-a622eec7ebf3" className="">
            <strong>Multi-tenancy</strong> - Ensuring each business ("tenant")
            accesses only their data is crucial. With multiple clients sharing
            computing resources, careful consideration is needed to maintain
            optimal separation.
          </p>
          <p id="975be0e7-1da0-4076-a09c-6ef595ab656a" className="">
            <strong>Feature Flags</strong> - Implementing a feature flags system
            is vital for managing different product versions and release cycles.
            It enables testing features in production before full release.
            Numerous vendors offer feature flags mechanisms, making in-house
            implementation generally unnecessary.
          </p>
          <h3 id="b2aa713a-efdf-4d25-9fdb-a6df32e17c9d" className="">
            <strong>
              <strong>Progressing Further</strong>
            </strong>
          </h3>
          <p id="da78ca3f-0f9a-497b-8f4a-82aefbf6c3bb" className="">
            Visibility and governance become crucial as you move forward in the
            Management Console's development.
          </p>
          <p id="2c02d7e3-5419-4047-adc8-be5288a8bebf" className="">
            <strong>Queries Infrastructure</strong> - The ability to dynamically
            filter data via API from day one is essential. This prevents
            bottlenecks by avoiding the need for backend endpoints for every
            query. GraphQL is a recommended solution.
          </p>
          <p id="cc310f1a-b859-4623-a5cf-bdfd3d9c8b5b" className="">
            <strong>Visualizing Tools</strong> - Tables, grids, charts—most data
            needs representation in these forms. Choosing UI libraries that
            balance customization with features is vital. Don't overlook the
            importance of effective filters.
          </p>
          <p id="72e75eac-9d06-48f9-bcd4-5924e0f7c4c9" className="">
            <strong>Analytics Infrastructure</strong> - Create insights and
            alerts from data, either periodically or in (near) real-time. Early
            implementation enhances the value customers derive from your
            product.
          </p>
          <p id="69758b7b-98ea-4ec5-8972-cc8731fa6316" className=""></p>
          <h3 id="21d64217-8ab8-478e-a9c1-b33c913a1de0" className="">
            <strong>
              <strong>Winning Customer Appreciation</strong>
            </strong>
          </h3>
          <p id="41ffafb4-f895-4adf-9fda-c593e2fde14e" className="">
            Make your product user-friendly and enjoyable for a positive
            customer relationship.
          </p>
          <p id="620ae44a-8d27-4fbe-9e4b-f962c2925388" className="">
            <strong>Excellent Navigation System</strong> - A user-friendly
            navigation system ensures users effortlessly find what they need.
            Features like in-site search, keyboard shortcuts, and "recently
            viewed" pages contribute to a seamless experience.
          </p>
          <p id="efb080e0-5778-437f-9836-dc9301af4fcf" className="">
            <strong>Customer Service</strong> - Prioritize customer service to
            address issues, collect feedback, and handle feature requests. While
            some services like <a href="https://www.intercom.com/">Intercom</a>{" "}
            offer a built-in chat feature, it's not mandatory.
          </p>
          <p id="cc00dae1-71f8-4048-b9d0-7f709a688779" className="">
            <strong>Notifications</strong> - Communicate changes, new features,
            and updates to customers via email or within the console. This
            conveys continuous improvement.. If you’re in a rush, you can use a
            vendor like <a href="https://www.getbeamer.com/">Beamer</a>.
          </p>
          <p id="f2693a60-ed99-45da-a632-d972befebf72" className="">
            <strong>Documentation</strong> - While the product should be
            self-explanatory, user-facing documentation guides less techy users
            through basic flows. Links from each page to relevant documentation
            pages enhance user understanding.(Examples for vendors are{" "}
            <a href="https://elev.io/">Elevio</a>,{" "}
            <a href="https://readme.com/">Readme </a>which is more
            developer-oriented, and{" "}
            <a href="https://document360.com/">Document360</a>)
          </p>
          <p id="9459b234-e083-4283-879e-a355271e3efd" className=""></p>
          <h3 id="20c51965-636f-4648-a7ef-d240e5b50e40" className="">
            <strong>
              <strong>Enterprise-Ready Features</strong>
            </strong>
          </h3>
          <p id="84df1e48-1357-4c43-9636-2ffed30bd851" className="">
            Large corporations have their own established systems for various
            functions. Integration with these systems becomes crucial.
          </p>
          <p id="c5757f11-261a-4be5-acc9-96907c0248b4" className="">
            <strong>IDP SSO (Single Sign-On)</strong> - Integrate with the
            organization's existing user authorization systems, such as Azure
            AD, Okta, or Ping Identity, using protocols like SAML or OpenID.
            Have no idea what those are? good, because I have an article{" "}
            <u>
              <a href="https://omer-shlifka.com/feed/sso" target="_blank">
                specifically about that
              </a>
            </u>
            !
          </p>
          <p id="c3554246-064e-4aaf-839b-6f52e9bb32bc" className="">
            <strong>Outbound Integrations</strong> - Allow users to view data in
            their systems through outbound integrations. This involves
            mechanisms like Webhooks and data export capabilities, like REST
            APIs. Services like Zapier and n8n simplify creating such
            integrations.
          </p>
          <p id="269e0983-a307-4f8c-b9ad-5cf01aa4e677" className="">
            <strong>Inbound Integrations</strong> - Provide users with a
            convenient way to update product configurations without accessing
            the console directly. This includes user-facing REST APIs,
            terraform, and importing data using files. Another example is SCIM,
            a protocol for transferring identity information between parties.
          </p>
          <p id="6e147b4b-ad9c-491c-8282-0ffd8cb932f8" className=""></p>
          <h3 id="0578db2c-20f4-4baf-80a2-027142c44426" className="">
            <strong>
              <strong>Challenges for Giants</strong>
            </strong>
          </h3>
          <p id="4840c198-832f-46cc-8cb7-a6786cb9a6fa" className="">
            Reserved for handling complex tasks when dealing with big clients
            like governments and large corporations.
          </p>
          <p id="00161d15-853b-4f16-8153-821b13dc47d6" className="">
            <strong>RBAC (Role-Based Access Control)</strong> - Implement a
            mechanism that restricts access based on a person's role within the
            organization. This can be tedious, as it's an ongoing process with
            new endpoints and UI elements continually added.
          </p>
          <p id="4fc3752c-2120-4086-90df-576792d30c1d" className="">
            <strong>Multi-Region and Multi-Cloud</strong> - Accommodate requests
            to store data in specific regions or on preferred cloud providers as
            your startup expands.
          </p>
          <p id="210303dd-bf66-4e4c-a6a5-58b2d9122c14" className="">
            <strong>Compliance</strong> - Address compliance requirements early
            on, covering aspects like GDPR, advanced accessibility features,
            avoiding logging PII, and undergoing regular security reviews.
          </p>
          <p id="da955e31-9c11-45c0-992a-b41bd482e8d4" className=""></p>
          <h3 id="f11aa6cb-995a-451e-bb78-6197529a1055" className="">
            In conclusion
          </h3>
          <p id="6003883f-b0f4-4150-adaa-8f7dfd272575" className="">
            While the list may seem extensive, it provides a comprehensive view.
            Analyze your product, user persona, and target businesses to select
            features relevant to your Management Console. The key is Balancing
            third-party solutions for <strong>efficiency</strong> and in-house
            implementation for <strong>customization</strong>. Feel free to
            reach out for advice tailored to your specific situation.
          </p>
          <p id="e3c9ea77-29ff-4007-879f-b55419823699" className=""></p>
        </div>
      </article>
    </div>
  );
}
