import React, { Component, useState } from "react";
import MagicalMysteryTourAlbum from "./assets/tour/MagicalMysteryTourAlbum.png";
import ProductTour1 from "./assets/tour/ProductTour1.png";
import CaloriesApp from "./assets/tour/CaloriesApp.png";
import ProductTour from "./assets/tour/ProductTour.gif";
import TourStop1 from "./assets/tour/TourStop1.png";
import TourStop2 from "./assets/tour/TourStop2.png";
import TourStop3 from "./assets/tour/TourStop3.png";
import ProductTour2 from "./assets/tour/ProductTour2.png";
export default function MagicalMysteryTour() {
  return (
    <div>
      <article id="f0aa6323-619f-4a5b-bc4d-3ce0a0779252" className="page sans">
        <div className="page-body">
          <p id="96efa6ec-c0ab-4343-8d2c-bc34a5276e9b" className="">
            Have you ever encountered the need to{" "}
            <strong>introduce your app’s UI</strong> to your users?
          </p>
          <p id="1f3e1b7d-60b8-40d3-9fb2-b1978c742aff" className="">
            In this article, we will learn how to build our own “Product Tour”
            in React JS, with maximum <strong>customization </strong>and{" "}
            <strong>minimum effort</strong> <strong>and cost</strong>, using
            tools we all “have at home” (I used Zustand for state management and
            MUI for UI components).
          </p>
          <img
            src={MagicalMysteryTourAlbum}
            className="modal-pic"
            style={{ width: "100%", marginBottom: 30 }}
          />
          <h3 id="d860abc2-45c0-4e81-8186-84e979086970" className="">
            The problem
          </h3>
          <p id="3fba1d60-da60-43ca-8048-04d60fed7674" className="">
            Suppose you have an application with numerous features and
            abilities, and your application’s new users often{" "}
            <strong>need guidance</strong> on how to use it. Alternatively,
            suppose you launch a new UI version of your app, and you want to{" "}
            <strong>walk your users through the new changes</strong> in the
            system.
          </p>
          <p id="40059bdd-f317-410f-8766-7d07f76c1f7d" className="">
            Ideally, we would <strong>personally </strong>walk every one of our
            customers through our app, and guide them until they understand
            every aspect of it. However, in the real world, we don’t always know
            all of our customers, and we don’t have the time or the will to
            guide them all. Luckily, we have <strong>product tours</strong> to
            help us!
          </p>
          <h3 id="cdece4ba-4478-41a0-b3cb-e90d065ad20a" className="">
            What are product tours?
          </h3>
          <p id="5ef4a29a-bc08-4323-a4e0-01003b6794ea" className="">
            “Product Tour”, sometimes called “Product Walkthrough”, is a tool
            for <strong>showcasing an app UI</strong>, usually a web app, by
            interactively highlighting different parts of the app, one part at a
            time, resulting in an interactive<strong> in-product guide.</strong>{" "}
            Each such “highlight” <strong>familiarizes </strong>the user with
            the UI part, and <strong>encourages </strong>them to engage with it.
          </p>
          <img
            src={ProductTour2}
            className="modal-pic"
            style={{ width: "100%", marginBottom: 30 }}
          />
          <h3 id="3b7c9078-c3ce-4e3d-833a-8edf6966a81a" className="">
            Ok, I’m on board! Now, how do I get such a product tour?
          </h3>
          <p id="46f5173a-95e1-473a-9abc-3791076c64fa" className="">
            If you want to embed a product tour into your app, you have 3
            options:
          </p>
          <h4 id="5c5c3ce1-55db-4ba6-b555-0cd061b84a6a" className="">
            <strong>
              1. Use a “Digital Adoption Category” off-the-shelf product
            </strong>
          </h4>
          <p id="8cde8fef-67e5-44ed-ad98-2771c57d5565" className="">
            There are multiple great tools that will give you excellent product
            tours within minutes. For example:{" "}
            <a href="https://www.walkme.com/digital-adoption-platform/">
              Walkme
            </a>
            , <a href="https://www.pendo.io/home-b/">Pendo</a>,{" "}
            <a href="https://www.appcues.com/">Appcues, </a>and much more. The
            downside for using such a product is that <strong>it costs,</strong>{" "}
            and you probably already are paying for endless other SaaS apps, so
            think well if you are willing to pay just to have a product tour. If
            you plan to only embed one guide, for instance, it probably won’t be
            worth the effort.
          </p>
          <h4 id="5f527a69-c550-42f8-b19d-5b96ec9c4659" className="">
            <strong>2. Use a Tour library</strong>
          </h4>
          <p id="e14e4e29-8552-4348-9d25-1266dcfd2e0d" className="">
            At least in React JS, there are 2 dominant tour libraries:{" "}
            <a href="https://github.com/elrumordelaluz/reactour">reactour </a>
            and{" "}
            <a href="https://github.com/gilbarbara/react-joyride">
              react-joyride
            </a>
            . They both have a pretty similar interface: They both define a list
            of steps, where each step specifies the CSS selector of the element
            it needs to show next to.
          </p>
          <p id="cc8dcc34-8dab-4b8a-b63e-fe3f7e781a13" className="">
            For example, this is the interface of <code>react-joyride</code>:
            (taken from their docs)
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="800e897b-2109-4339-b95b-cb3a00613935" className="code">
            <code className="language-JavaScript">
              import Joyride from 'react-joyride';{"\n"}
              {"\n"}export class App extends React.Component {"{"}
              {"\n"}
              {"  "}state = {"{"}
              {"\n"}
              {"    "}steps: [{"\n"}
              {"      "}
              {"{"}
              {"\n"}
              {"        "}target: '.my-first-step',{"\n"}
              {"        "}content: 'This is my awesome feature!',{"\n"}
              {"      "}
              {"}"},{"\n"}
              {"      "}
              {"{"}
              {"\n"}
              {"        "}target: '.my-other-step',{"\n"}
              {"        "}content: 'This another awesome feature!',{"\n"}
              {"      "}
              {"}"},{"\n"}
              {"      "}...{"\n"}
              {"    "}]{"\n"}
              {"  "}
              {"}"};{"\n"}
              {"\n"}
              {"  "}render () {"{"}
              {"\n"}
              {"    "}const {"{"} steps {"}"} = this.state;{"\n"}
              {"\n"}
              {"    "}return ({"\n"}
              {"      "}&lt;div className="app"&gt;{"\n"}
              {"        "}&lt;Joyride{"\n"}
              {"          "}steps={"{"}steps{"}"}
              {"\n"}
              {"          "}...{"\n"}
              {"        "}/&gt;{"\n"}
              {"        "}...{"\n"}
              {"      "}&lt;/div&gt;{"\n"}
              {"    "});{"\n"}
              {"  "}
              {"}"}
              {"\n"}
              {"}"}
            </code>
          </pre>
          <p id="6bfa4013-91cb-467f-a6d8-e621eb3a7a25" className="">
            Although both of the libraries are <strong>open source </strong>and
            thus free, they have other underlying issues. The first one is the{" "}
            <strong>size of the packages</strong>, which goes from 123 kB in{" "}
            <a href="https://www.npmjs.com/package/reactour">reacttour </a>to
            452 kB in{" "}
            <a href="https://www.npmjs.com/package/react-joyride">
              react-joyride
            </a>
            , which is a lot for something that small (no offense), making our
            app load slower due to the bigger bundle size.
          </p>
          <p id="0bfff319-8368-4f72-aada-42ab8c89683e" className="">
            Additionally, there are <strong>customization</strong> issues.
            Although both of the libraries allow you to have your custom Tooltip
            components, you will generally need to{" "}
            <strong>work harder to adjust the components</strong> to the
            required interface. In <code>react-joyride</code> it becomes even
            more complicated if you want to customize the current step logic
            because then the tour becomes “controlled” so you need to maintain
            an inner state, so you end up working hard for the library instead
            for it to work hard for you!{" "}
          </p>
          <p id="fe9f682c-11fc-4026-be4b-10f0b9bbd8f5" className="">
            Regardless, I find <strong>relying on CSS selectors</strong> can be{" "}
            <strong>risky and flakey.</strong> CSS properties tend to change
            over time, and we don’t want our guide to ever break.
          </p>
          <h4 id="67a081fe-6b6d-47d3-95b7-af0cd7b972b1" className="">
            <strong>3. Do it yourself</strong>
          </h4>
          <p id="af94843e-c894-4594-abbc-7cc82ff9821f" className="">
            If you want to have maximum customization in your product tour,
            without spending time and money, this may be the perfect solution
            for you.{" "}
          </p>
          <h3 id="561c899b-8733-4ad7-b21c-fc7a1b85b282" className="">
            Our DIY solution
          </h3>
          <p id="059d33e8-3d80-4914-ac1c-a076538f5cb6" className="">
            Our solution is inspired by the interfaces of the 2 libraries
            mentioned above, with one significant difference:{" "}
            <strong>We don’t rely on CSS Selectors</strong>. Instead, each step
            will have its indicative and unique name. We will shortly show how
            we understand <strong>when </strong>to show each step and{" "}
            <strong>where.</strong>
          </p>
          <p id="f9003cbf-586e-42b4-b678-9fedfcd13f7a" className="">
            But first, a disclaimer: on the spectrum between a "Enterprise-grade
            product" and a “quick and dirty” solution, this solution is in the{" "}
            <strong>“quick and dirty” department</strong>. It suits best when
            you need a <strong>single </strong>interactive guide,{" "}
            <strong>custom-tailored</strong> to your app components and
            libraries. Therefore, if product tours are going to be used
            regularly in your app, in different scenarios, consider purchasing a
            product that covers everything for you.
          </p>
          <p id="79783689-adb1-4724-8c59-db2bbb2d2b19" className="">
            We will use an example of a calorie tracking app and implement our
            Tour inside. (You might find some familiar Beatles songs from the
            Magical Mystery Tour album, one of their best!)
          </p>
          <img
            src={CaloriesApp}
            className="modal-pic"
            style={{ width: "100%", marginBottom: 30 }}
          />
          <h3 id="4fde4c1e-6e14-4a40-a247-51d9e74cdf1d" className="">
            Building our Product Tour
          </h3>
          <p id="71525c00-4590-403f-8138-fba75660bd7f" className="">
            Before we start, make sure you have-
          </p>
          <ul
            id="e4327477-aa0a-4126-ae29-9b453a0ef1f5"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              A <strong>React JS app</strong>
            </li>
          </ul>
          <ul
            id="372871b1-0e96-4c7d-b9dc-9c36c5f7fce6"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              A <strong>Popover component</strong>. Every component library has
              one. In my example I used{" "}
              <a href="https://mui.com/material-ui/react-popover/">
                Material UI’s Popover
              </a>
              , which is based on Popper.js.
            </li>
          </ul>
          <ul
            id="73f704c1-2aa0-47f0-8abc-58fc80e44370"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              A <strong>state management</strong> solution. I used{" "}
              <a href="https://github.com/pmndrs/zustand">Zustand </a>because
              it’s very easy to implement and use, but you can also use React
              context with React state, or Redux (if you really must 😈).
            </li>
          </ul>
          <p id="dd0153f1-9c69-4131-8ce4-ffbe3984a1fb" className=""></p>
          <p id="e05d9a6a-da10-4a1c-b293-38b4705d1eee" className="">
            We have 3 steps for implementing our tour:
          </p>
          <ol
            type={1}
            id="e9778f2f-c1f8-4f14-a294-db447b34ef80"
            className="numbered-list"
            start={1}
          >
            <li>
              <strong>Define tour state</strong> and stops, and expose the{" "}
              <code>tour store</code>, a hook that will manage the tour state.
            </li>
          </ol>
          <ol
            type={1}
            id="c33bd308-33ad-4cc0-bc47-413de69dba0f"
            className="numbered-list"
            start={2}
          >
            <li>
              <strong>Create the tour building block</strong>: the{" "}
              <code>&lt;TourStop/&gt;</code> component
            </li>
          </ol>
          <ol
            type={1}
            id="d6802717-da49-45a5-8f41-254b1e777036"
            className="numbered-list"
            start={3}
          >
            <li>
              <strong>Adding the tour stops</strong> to our app.
            </li>
          </ol>
          <p id="6630163c-32b3-407f-86a8-982fee22494d" className=""></p>
          <h4 id="56686a99-39f3-4e86-a3d2-5faa6f7925ac" className="">
            Define tour state
          </h4>
          <p id="5bc54744-f1b0-418e-86f6-29c072efae02" className="">
            first, we will define our stops.
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="e6061b2a-c959-4c2e-aa58-259dd1586272" className="code">
            <code className="language-JavaScript">
              const stops = [{"\n"}
              {"  "}
              {"{"}
              {"\n"}
              {"    "}id: "side-menu",{"\n"}
              {"    "}content: "Click this button to see other food categories",
              {"\n"}
              {"  "}
              {"}"},{"\n"}
              {"  "}
              {"{"}
              {"\n"}
              {"    "}id: "login",{"\n"}
              {"    "}content: "Click here to login and see your preferences",
              {"\n"}
              {"  "}
              {"}"},{"\n"}
              {"  "}
              {"{"}
              {"\n"}
              {"    "}id: "protein",{"\n"}
              {"    "}content: "Here you can see the amount of protein in each
              food",{"\n"}
              {"  "}
              {"}"},{"\n"}];
            </code>
          </pre>
          <p id="b32068b9-4c71-47f4-ae5b-26daf5608fa7" className="">
            <code>id</code> is an indicative name for the stop.{" "}
            <code>content</code> is a string that contains the stop text, it can
            easily be modified to be a <code>ReactNode</code> if we want to show
            more complex objects.
          </p>
          <p id="69b6b648-f61d-4f7a-b668-7e07bf7189f2" className=""></p>
          <p id="a1f1923c-a0aa-4a54-ad3a-768ddd72fba2" className="">
            Now that we have the stops, we can add our store.{" "}
            <a href="https://github.com/pmndrs/zustand">Zustand </a>makes it
            easy for us to expose a hook for managing complex state.
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="f7f88f89-8301-45b0-bfff-96bc82ea9eb2" className="code">
            <code className="language-JavaScript">
              import {"{"} create {"}"} from "zustand";{"\n"}
              {"\n"}export const useTourStore = create((set, get) =&gt; ({"{"}
              {"\n"}
              {"  "}isShown: true,{"\n"}
              {"  "}index: 0,{"\n"}
              {"  "}isFirst: () =&gt; get().index === 0,{"\n"}
              {"  "}isLast: () =&gt; get().index === stops.length - 1,{"\n"}
              {"  "}prev: () =&gt;{"\n"}
              {"    "}set((state) =&gt; ({"{"}
              {"\n"}
              {"      "}index: Math.max(0, state.index - 1),{"\n"}
              {"    "}
              {"}"})),{"\n"}
              {"  "}next: () =&gt;{"\n"}
              {"    "}set((state) =&gt; ({"{"}
              {"\n"}
              {"      "}isShown: state.index &lt; stops.length - 1,{"\n"}
              {"      "}index: Math.min(state.index + 1, stops.length - 1),
              {"\n"}
              {"    "}
              {"}"})),{"\n"}
              {"  "}skip: () =&gt; set({"{"} isShown: false {"}"}),{"\n"}
              {"  "}getCurrent: () =&gt; stops[get().index],{"\n"}
              {"  "}isStepActive: (stepId) =&gt; get().isShown &amp;&amp; stepId
              === stops[get().index].id,{"\n"}
              {"}"}));
            </code>
          </pre>
          <p id="4050488a-9b50-4c7b-bcb1-7439951253cb" className="">
            Our store is exposing a few properties and setters-
          </p>
          <ul
            id="8e456049-3f7d-4c5f-91cf-04476355cf02"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <code>isShown</code>: Whether the guide is active now
            </li>
          </ul>
          <ul
            id="4da2c446-0924-4383-b1b3-62fa4f89cb79"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <code>index</code>: The index of the current stop
            </li>
          </ul>
          <ul
            id="8b12250f-5fbe-46f2-91e6-086a767ee551"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <code>isFirst()</code>: Whether the current stop is the first one
            </li>
          </ul>
          <ul
            id="80eaa223-b18b-44c7-908a-f73368567e4d"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <code>isLast()</code>: Whether the current stop is the last one
            </li>
          </ul>
          <ul
            id="6a6d9e08-b6d8-4e66-ba97-3423789f44c7"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <code>prev()</code>: Go to the previous stop, if possible
            </li>
          </ul>
          <ul
            id="38d0e646-5a15-44c1-8617-b3b98288cf8b"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <code>next()</code>: Go to the next stop, and hide the guide if it
              was the last stop
            </li>
          </ul>
          <ul
            id="58f4eba8-592b-4d58-ae59-f16f5937b398"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <code>skip()</code>: Hide the guide
            </li>
          </ul>
          <ul
            id="9d7d1bfd-1d58-4553-b90f-8a429d6639d5"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <code>getCurrent()</code>: Get the current stop object (id and
              content)
            </li>
          </ul>
          <ul
            id="245de51f-9107-42d7-a1f0-50acd4666404"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <code>isStepActive(stepId)</code>: Get whether the step is the one
              that is currently shown on the screen
            </li>
          </ul>
          <p id="ff1a2ea2-519d-419e-bef1-1d8759082240" className=""></p>
          <p id="c17da13b-9905-4f66-bc3d-8419e3cece21" className="">
            Our store can easily be consumed like this:
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="737bb646-69b3-4de3-a1ac-53d0fb5c032b" className="code">
            <code className="language-JavaScript">
              import {"{"} useTourStore {"}"} from "./tourStore";{"\n"}const{" "}
              {"{"} getCurrent, next, skip, prev, isLast, isFirst, isStepActive{" "}
              {"}"} ={"\n"}
              {"    "}useTourStore();
            </code>
          </pre>
          <p id="9247b1da-c99c-47a2-967e-c048e05cea3b" className="">
            Now that we have a way to control our tour stops, we’ll continue to
            build the <code>&lt;TourStop /&gt;</code> component.
          </p>
          <h4 id="fde6d880-ced9-4465-8bc5-7284271e62ca" className="">
            Create the tour building block
          </h4>
          <p id="73975bd3-8e0f-4039-ac30-6c15e4cd6142" className="">
            The <code>&lt;TourStop /&gt;</code> is the main component of the
            product tour. It’s responsible for rendering the stop Popover next
            to the child element it gets in the props, according to the tour
            state.
          </p>
          <p id="459ecb1f-2abc-40c2-b2cd-68687d9a95aa" className="">
            Let’s start by creating a component that{" "}
            <strong>consumes the state</strong> of the store.
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="11c2287a-b75d-4991-92c1-6e46fa9db1bb" className="code">
            <code className="language-JavaScript">
              import React from "react";{"\n"}import {"{"} useTourStore {"}"}{" "}
              from "./tourStore";{"\n"}
              {"\n"}export function TourStop({"{"} id, children {"}"}) {"{"}
              {"\n"}
              {"  "}const {"{"} getCurrent, next, skip, prev, isLast, isFirst,
              isStepActive {"}"} ={"\n"}
              {"    "}useTourStore();{"\n"}
              {"  "}const {"{"} content {"}"} = getCurrent();{"\n"}
              {"  "}return children;{"\n"}
              {"}"}
            </code>
          </pre>
          <p id="23a6630c-feab-4832-9c35-d5fcc1997cc8" className="">
            We now want to <strong>show a popover </strong>next to the children.
          </p>
          <p id="6583a77b-f9d2-476a-9125-58ce3c1ac717" className="">
            The Popover component requires an HTML element to stick to. We will
            use a <code>ref</code> which we will add to the{" "}
            <code>children</code> props, and attach to the Popover.
          </p>
          <p id="cd3ce735-2dd0-41b5-b5d7-7304d348a697" className="">
            It should now look something like this:
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="2b8b7931-a843-492a-a599-4873fd032a0c" className="code">
            <code className="language-JavaScript">
              import React from "react";{"\n"}import Popover from
              "@mui/material/Popover";{"\n"}import {"{"} useTourStore {"}"} from
              "./tourStore";{"\n"}import {"{"} cloneElement, useRef {"}"} from
              "react";{"\n"}
              {"\n"}export function TourStop({"{"} id, children {"}"}) {"{"}
              {"\n"}
              {"  "}const ref = useRef();{"\n"}
              {"  "}const {"{"} getCurrent, next, skip, prev, isLast, isFirst,
              isStepActive {"}"} ={"\n"}
              {"    "}useTourStore();{"\n"}
              {"  "}const {"{"} content {"}"} = getCurrent();{"\n"}
              {"\n"}
              {"  "}return ({"\n"}
              {"    "}&lt;&gt;{"\n"}
              {"      "}
              {"{"}cloneElement(children, {"{"}
              {"\n"}
              {"        "}ref,{"\n"}
              {"      "}
              {"}"}){"}"}
              {"\n"}
              {"      "}&lt;Popover{"\n"}
              {"        "}id={"{"}id{"}"}
              {"\n"}
              {"        "}anchorEl={"{"}ref.current{"}"}&gt;{"\n"}
              {"        "}The step content{"\n"}
              {"      "}&lt;/Popover&gt;{"\n"}
              {"    "}&lt;/&gt;{"\n"}
              {"  "});{"\n"}
              {"}"}
            </code>
          </pre>
          <p id="f07b8a17-c441-4179-a6c7-1771a7bd003f" className=""></p>
          <p id="d5f1b8a7-5c46-4829-9dc2-ff05c08371c4" className="">
            Now we want to show the actual stop popover, with “next”, “prev” and
            “skip” buttons, along with the content of the stop, and we only want
            to show the popover{" "}
            <strong>if the given stop is the active one</strong>! This is also
            the time to style a little our Popover so it will look the way we
            want.
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="bd5828ab-0d1c-4f31-8241-ea5bf86b5abd" className="code">
            <code className="language-JavaScript">
              &lt;Popover{"\n"}
              {"  "}id={"{"}id{"}"}
              {"\n"}
              {"  "}open={"{"}isStepActive(id{"}"}
              {"\n"}
              {"  "}anchorEl={"{"}ref.current{"}"}
              {"\n"}
              {"  "}anchorOrigin={"{"}
              {"{"}
              {"\n"}
              {"    "}vertical: "bottom",{"\n"}
              {"    "}horizontal: "left",{"\n"}
              {"  "}
              {"}"}
              {"}"}
              {"\n"}&gt;{"\n"}
              {"  "}&lt;div style={"{"}
              {"{"} padding: 12 {"}"}
              {"}"}&gt;{"\n"}
              {"    "}&lt;Typography component="div"&gt;{"{"}content{"}"}
              &lt;/Typography&gt;{"\n"}
              {"    "}&lt;div style={"{"}
              {"{"} display: "flex", justifyContent: "end" {"}"}
              {"}"}&gt;{"\n"}
              {"      "}&lt;Button color="inherit" onClick={"{"}skip{"}"}&gt;
              {"\n"}
              {"        "}Skip{"\n"}
              {"      "}&lt;/Button&gt;{"\n"}
              {"      "}
              {"{"}!isFirst() &amp;&amp; ({"\n"}
              {"        "}&lt;Button color="secondary" onClick={"{"}prev{"}"}
              &gt;
              {"\n"}
              {"          "}Previous{"\n"}
              {"        "}&lt;/Button&gt;{"\n"}
              {"      "}){"}"}
              {"\n"}
              {"      "}&lt;Button color="primary" onClick={"{"}next{"}"}&gt;
              {"\n"}
              {"        "}
              {"{"}isLast() ? "Done" : "Next"{"}"}
              {"\n"}
              {"      "}&lt;/Button&gt;{"\n"}
              {"    "}&lt;/div&gt;{"\n"}
              {"  "}&lt;/div&gt;{"\n"}&lt;/Popover&gt;
            </code>
          </pre>
          <p id="3dce4f43-f59e-4d7a-b958-de15802b983c" className="">
            We now have a working TourStop!
          </p>
          <img
            src={TourStop1}
            className="modal-pic"
            style={{ width: "100%", marginBottom: 30 }}
          />
          <img
            src={TourStop2}
            className="modal-pic"
            style={{ width: "100%", marginBottom: 30 }}
          />
          <img
            src={TourStop3}
            className="modal-pic"
            style={{ width: "100%", marginBottom: 30 }}
          />
          <h4 id="32daf290-c782-4af7-a029-0c7238aebcd5" className="">
            Adding Tour Stops to our App
          </h4>
          <p id="2a88e0f3-b9b2-45e3-8747-eb31d268e792" className="">
            Now, that we have all of our building blocks, it’s time to connect
            it all.
          </p>
          <p id="ccc6b77e-1e27-4915-971a-70900ac6d8ac" className="">
            Let’s start with an element we want to attach to the first stop.{" "}
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="8ad798c7-9520-403c-b1c6-5be2fb5e1abe" className="code">
            <code className="language-JavaScript">
              &lt;IconButton{"\n"}
              {"  "}size="large"{"\n"}
              {"  "}edge="start"{"\n"}
              {"  "}color="inherit"{"\n"}
              {"  "}aria-label="menu"{"\n"}
              {"  "}sx={"{"}
              {"{"} mr: 2 {"}"}
              {"}"}
              {"\n"}&gt;{"\n"}
              {"  "}&lt;MenuIcon /&gt;{"\n"}&lt;/IconButton&gt;
            </code>
          </pre>
          <p id="4e43bda9-3f3c-4f30-9d9c-bdf6c2cc70df" className="">
            We will wrap this element with a <code>TourStop</code> component,
            along with the <code>id</code> of the stop. This will tell the{" "}
            <code>TourStop</code> to appear if the current stop is the given id.
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="4bbae240-0478-422e-ae6e-a03f34f6c79a" className="code">
            <code className="language-JavaScript">
              &lt;TourStop id="side-menu"&gt;{"\n"}
              {"  "}&lt;IconButton{"\n"}
              {"    "}size="large"{"\n"}
              {"    "}edge="start"{"\n"}
              {"    "}color="inherit"{"\n"}
              {"    "}aria-label="menu"{"\n"}
              {"    "}sx={"{"}
              {"{"} mr: 2 {"}"}
              {"}"}
              {"\n"}
              {"  "}&gt;{"\n"}
              {"    "}&lt;MenuIcon /&gt;{"\n"}
              {"  "}&lt;/IconButton&gt;{"\n"}&lt;/TourStop&gt;
            </code>
          </pre>
          <p id="8718c5e0-3c93-49f8-9ad5-bb56be8f1769" className="">
            We can see that the stop is now appearing next to that element:
          </p>
          <img
            src={ProductTour1}
            className="modal-pic"
            style={{ width: "100%", marginBottom: 30 }}
          />
          <p id="d6f79728-d394-42fe-81a1-3b3b1b01c23e" className=""></p>
          <p id="b5ce490a-aadc-4563-8c0c-c0892bab0f24" className="">
            All we have left is to add the rest of the stops wherever we want!
          </p>
          <p id="3cf9bfcb-8e48-492f-a1e4-85ea5ff24504" className="">
            The good thing about this explicit approach is that{" "}
            <strong>the code is very straightforward.</strong> When we look at
            the element’s code, we see it’s wrapped in a <code>TourStore</code>{" "}
            so we understand where the Popover is coming from. There’s no magic
            or voodoo (Like there was if there was something else listening to
            CSS Selectors to appear and injecting the Popovers to the DOM).
          </p>
          <p id="43eeb79f-68c0-4d0a-b6c9-01ae9257f7e5" className="">
            The downside is that the app components are{" "}
            <strong>“aware” of the Tour</strong>, so it’s not “clean” as it
            could be. And if there are multiple tours going on, it can get very
            messy.
          </p>
          <p id="1b94a69d-817b-4523-b021-4d083d8b5eb5" className=""></p>
          <img
            src={ProductTour}
            className="modal-pic"
            style={{ width: "100%", marginBottom: 30 }}
          />
          <h3 id="b3e59b73-63b8-4ae0-9c5a-6182e31c6519" className="">
            Advanced topics
          </h3>
          <p id="777e1e9d-370d-4ba2-8962-25997dfea8c7" className="">
            As you see, we created a very basic Product Tour, but it can be
            easily extended. Here are some common topics you may need to deal
            with:
          </p>
          <h4 id="ab61f181-4caa-47e6-8404-339381ab4a9f" className="">
            Start the tour manually (don’t start by default)
          </h4>
          <p id="4ed40b4a-949c-4877-85a5-9058c3803197" className="">
            This is done by a very small modification to our store. All we need
            to do is to change <code>isShown </code>to <code>false</code>, and
            to add a function <code>show</code>, that will reset the{" "}
            <code>index </code>to 0 and show the tour:
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="a123db78-55c1-4df6-8a5d-6f2d3ab45584" className="code">
            <code className="language-JavaScript">
              show: () =&gt; set({"{"} isShown: true, index: 0 {"}"}),
            </code>
          </pre>
          <p id="e3671f19-1971-4cc3-b1dd-5bd6cf4a0ae7" className="">
            Then we can consume the <code>show</code> function whenever we want
            to show the tour.
          </p>
          <h4 id="094ad405-d23a-405e-828e-e0c350197ab2" className="">
            Force the user to use the guide
          </h4>
          <p id="ecfd1fad-9719-4a77-ade6-ccc1c01279ba" className="">
            If we don’t want the user to interact with anything besides our
            tour, we can add a Backdrop element. Most component libraries have a
            “Backdrop” component, so all you need to do is wrap the Popover with
            this Backdrop component!
          </p>
          <h4 id="9d00da43-7b9a-46ed-9980-2a7979ec133c" className="">
            Remember the user’s position in the tour for the next app visit
          </h4>
          <p id="c3fc62d2-a391-47b6-98e9-5b8f6bff9031" className="">
            Since we use Zustand, we get that ability in no time, by using the{" "}
            <code>persist</code> middleware.
          </p>
          <p id="95e61fe6-f9f3-48d5-9428-1a7640d44477" className="">
            This is helpful because if the user clicks “Skip”, “Done”, or leaves
            without completing the guide, we can continue just where we stopped
            the next time they visit.
          </p>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.29.0/themes/prism.min.css"
            integrity="sha512-tN7Ec6zAFaVSG3TpNAKtk4DOHNpSwKHxxrsiw4GHKESGPs5njn/0sMCUMl2svV4wo4BK/rCP7juYz+zx+l6oeQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <pre id="1bc439b1-4af0-4858-887a-11b758b32965" className="code">
            <code className="language-JavaScript">
              import {"{"} create {"}"} from 'zustand'{"\n"}import {"{"} persist{" "}
              {"}"} from 'zustand/middleware'{"\n"}
              {"\n"}const useTourStore = create({"\n"}
              {"  "}persist({"\n"}
              {"    "}(set, get) =&gt; ({"{"}
              {"\n"}
              {"      "}...{"\n"}
              {"    "}
              {"}"}),{"\n"}
              {"    "}
              {"{"}
              {"\n"}
              {"      "}name: 'tour-store'{"\n"}
              {"    "}
              {"}"},{"\n"}
              {"  "}),{"\n"})
            </code>
          </pre>
          <p id="d5be290d-2a17-46f9-bab1-79778a034799" className="">
            Pretty easy to modify things when you do it all yourself, isn’t it?
          </p>
          <h3 id="9f181b99-3fe7-4f00-b550-fe1113f7b296" className="">
            Summary
          </h3>
          <p id="add1f75a-0a93-4fa2-ad61-dbceef09be63" className="">
            Today we learned how to build a Product Tour within minutes, while
            having maximum customization to use our components and styles.
          </p>
          <p id="fe0ecccb-0e46-4732-9377-06586806fe8f" className="">
            I hope this is going to be useful for you and to save you time and
            money.
          </p>
          <p id="259a3bd4-e4ae-405e-ae7b-32e58a56c63c" className="">
            You can find the complete code here -{" "}
            <a href="https://github.com/omers4/react-product-tour-example">
              https://github.com/omers4/react-product-tour-example
            </a>
          </p>
          <p id="0842d175-a836-4d2a-8f26-b5bbd8f675ec" className="">
            Bon Voyage!
          </p>
          <p id="c8069dd7-2114-4cd5-ba70-2ab329710dab" className=""></p>
          <p id="f2de1996-69ef-4256-b7f6-eb47b64666b0" className=""></p>
        </div>
      </article>
    </div>
  );
}
