import React, { Component, useState } from "react";
export default function MustReads() {
  return (
    <div>
      <article id="f0aa6323-619f-4a5b-bc4d-3ce0a0779252" className="page sans">
        <div className="page-body">
          <h4 id="8aac2b55-4d14-4d48-8f4b-6fdd7b4f4ada" className="">
            OMG, I’m a manager. Now what?
          </h4>
          <p id="58ec07f0-48a5-4ce7-a267-2a8b79845e55" className="">
            Starting a new engineering management position for the first time
            can be overwhelming - I would know! Suddenly, you have many things
            to consider, such as keeping employees happy and satisfied, reaching
            business goals, and completing your own development tasks.
          </p>
          <p id="09b318d0-f138-4607-97f8-bb69ee10983e" className="">
            It's important to adapt quickly to your new role, but don't get too
            stressed. Fortunately, the field of business management has been
            extensively researched, and there are many experienced individuals
            and books that offer valuable insights.
          </p>
          <p id="85c9f5df-c5f2-4781-bf36-4b88f61b31d7" className="">
            In this article, I recommend three books that I read during my first
            management position in the industry. These books cover various
            aspects of management, from the significance of{" "}
            <strong>empathy</strong> and personal growth and the importance of{" "}
            <strong>teamwork</strong> to "cold" topics like{" "}
            <strong>delivery</strong>, goals, roadmaps.
          </p>
          <p id="b8b00b9c-ff10-4e57-8cca-ae004691f78b" className="">
            What's interesting about these books is that none of them directly
            discuss engineering or software management. However, that's the
            beauty of it. Unlike learning a specific tech domain, which may
            become irrelevant in a few years, management is all about people.
            People are the same all over the world, making management a{" "}
            <strong>universal skill</strong>.
          </p>
          <p id="1cf149c6-0b37-454a-b694-9eb38c7eb519" className="">
            So let's learn from the best in the field from all around the world
            🙂
          </p>
          <h4 id="11ee5c64-76a4-4d58-a68b-a571ba2592f0" className="">
            Mindset - Carol S. Dweck, 2006
          </h4>
          <p id="e65c4c17-0b55-4802-8b73-752cbaf31599" className="">
            The first book on this list is not one you would typically find in
            the "Management" section of a bookstore, but rather in the
            "Self-help" or "Psychology" categories. However, its inclusion here
            is intentional.
          </p>
          <p id="bff558d9-9fdc-4c5c-97cf-fb367c13ac78" className="">
            "Mindset" presents a simple yet powerful idea. Our mindset, or how
            we think about our abilities and skills, greatly influences us.
            People with a "Fixed mindset" believe that their skills are fixed
            and that they are inherently good at some things and bad at others.
            On the other hand, people with a "Growth mindset" believe that they
            can continuously evolve and improve in all areas.
          </p>
          <p id="8b13e778-fa84-4a87-b9c3-77bbf258f8e7" className="">
            While this may sound "New Age" or naive, it is worth noting that the
            author, Carol S. Dweck, holds a Ph.D. in Psychology and has
            conducted extensive research on this subject for decades. Her
            research shows that individuals with a Growth mindset tend to
            achieve greater success, regardless of their starting point. As the
            book states, "It's not always the people who start out the smartest
            who end up the smartest."
          </p>
          <p id="ab6217ac-c82d-4d01-9732-6a5674da9807" className="">
            I believe this book is essential for you managers, because in order
            to foster personal growth and help individuals reach their full
            potential, managers must first recognize that everyone has potential
            and can learn and grow in various ways, including yourselves. Along
            the way, this mindset shift can also have a positive impact on your
            own lives, as they discover new possibilities for personal
            improvement.
          </p>
          <p id="c792265b-8d6e-48fa-91db-1652b34102c6" className="">
            Imagine how your team might change if you assign a backend task to a
            developer who claims to be only skilled in frontend. Consider how
            you could improve by placing yourself in challenging situations,
            such as speaking in front of an audience. The possibilities are
            limitless.
          </p>
          <p id="8c395c83-a3c7-4b12-ba22-322cb1bc6d05" className="">
            By the way, all the books I have read are in Hebrew, my mother
            tongue. One thing to note about translated Hebrew book titles is
            that they are often loosely related to the original name and can
            sometimes be ridiculous. For example, the Hebrew title for "Mindset"
            is "The Power of Determination" (🤦🏻‍♀️).
          </p>
          <p id="8d9f97f1-3a01-4087-9468-95574a85dac9" className=""></p>
          <h4 id="98e0c053-1c76-45bf-9442-388598a46972" className="">
            The First 90 Days - Michael Watkins, 2003{" "}
          </h4>
          <p id="be40f40b-762f-40f4-a190-162b97e0d3c9" className="">
            Hebrew name: “90 days of grace” 🤦🏻‍♀️🤦🏻‍♀️
          </p>
          <p id="baa90f41-0a82-41dc-8df0-773e2c67cd8a" className="">
            This book takes a different approach compared to the previous one.
            Instead of focusing on effort and improvement, it emphasizes the
            importance of achieving business results. It highlights the
            significance of the first 90 days in a new position, as they can
            determine the success or failure of the entire role. The book
            provides insights on various factors that require close attention
            during this critical period, along with multiple examples from
            individuals who have started new management positions.
          </p>
          <p id="f3bf8a4c-e14b-41fa-9cb1-8345355c93d9" className="">
            The book addresses cleverly common challenges that arise when
            starting a new role, such as the temptation to make immediate
            changes, lack of familiarity with the company's history, and
            neglecting relationships with colleagues.
          </p>
          <p id="a3618c50-eb53-4688-aed4-484900b22b7f" className="">
            At times, the book can be intimidating as it urges readers to start
            thinking about early quick wins from day one. These wins will grow
            over time and establish one's reputation as a successful manager.
            However, the reality <strong>can </strong>be daunting. In a new job,
            you are responsible for making it work without much guidance or
            support, so it’s good that the book doesn’t sugar-coat it.
          </p>
          <p id="58c35f82-50b8-4cf9-9545-1d50ea7036ec" className="">
            One drawback of the book is that most of the examples focus on
            individuals hired from outside the company. It lacks guidance on how
            to navigate a promotion from within, where there is already a
            history and established relationships.
          </p>
          <p id="b5e833fd-18dd-453b-a918-ef1212340d38" className="">
            Personally, I find it beneficial to revisit this book once a year.
            It offers valuable self-assessment exercises that help identify
            strengths, weaknesses, and guide future development.
          </p>
          <p id="98f7faae-3a0c-4c0a-bcbe-55b7c088bad4" className=""></p>
          <h4 id="f450f650-55f8-419c-b85d-c8e18097d60a" className="">
            The Five Dysfunctions of a Team - Patrick Lencioni, 2002
          </h4>
          <p id="37f0827d-9961-44ac-a057-242bb48f4315" className="">
            Hebrew name: "Light up the fire" 🤦🏻‍♀️🤦🏻‍♀️🤦🏻‍♀️
          </p>
          <p id="5296724b-bbb7-4ae8-a8ed-7a56bdcb11a0" className="">
            "Not finance. Not strategy. Not technology. It is teamwork that
            remains the ultimate competitive advantage, both because it is so
            powerful and so rare."
          </p>
          <p id="a77fc6d9-3169-4889-a9e0-b3fc3764694e" className="">
            While the previous book focuses on the manager, the individual,
            "Five dysfunctions of a team" focuses on the team as the main entity
            that should improve as one, as the main way to achieve business
            goals.
          </p>
          <p id="39636a7c-6692-4bf4-9ffc-6e362db475f1" className="">
            It is also the only one in the list that is somehow related to the
            software industry, as it tells a story about a start-up company in
            Silicon Valley. It's a story that many of us can relate to and smile
            about when one of the characters is too similar to someone we know
            from work.
          </p>
          <p id="10e70e8f-a460-4fe3-b5d2-6ef5ce0026d5" className="">
            In the book, Lencioni describes 5 common pitfalls that teams
            experience, with one leading to another. He claims that even if the
            individuals in the team are highly skilled, the team will not be
            able to function in the long term if there is no trust between
            teammates, if teammates prioritize their own personal goals over the
            team's goals, and more.
          </p>
          <p id="f20d897c-5250-4399-bc0d-9dc35578deaa" className="">
            This is a very interesting and refreshing approach, especially for
            engineering teams, where each individual often works on a different
            feature (in many companies there is even a degree of “Individual
            Contributor”!). It's easy to forget that it's a team effort and not
            just a collection of individuals. I believe we have all been in
            situations where someone updates us on what they're doing in the
            daily or planning, but no one else listens because it doesn't seem
            relevant to them.
          </p>
          <p id="e93c09d7-b2ff-437d-b980-abc2f3e3c79e" className="">
            As it is the most recent book I've read out of the three, I'm
            curious to see how it will influence my leadership style. It
            definitely sheds light on areas that go against my nature, such as
            embracing conflicts and arguments as main tools of discussion (I
            dislike arguing!), the importance of being vulnerable (I tend to
            keep a distance from those I manage), and the ability to hold
            colleagues accountable if they have made mistakes.
          </p>
          <p id="2520ac2e-cad6-4664-bd08-37096b15e6c3" className=""></p>
          <h4 id="2b087079-5685-4b30-9f95-fae267da645c" className="">
            Bonus: Atomic habits - James Clear, 2018
          </h4>
          <p id="90d25f19-8a26-4144-a304-9e44be6d79e2" className="">
            Hebrew name: "Atomic Habits" (Thank you!!!)
          </p>
          <p id="aa8cea77-d878-4c46-99b9-0cb12a5b94a3" className="">
            Although not directly a management book, I highly recommend reading
            "Atomic Habits." When you become a manager, your life suddenly
            becomes much busier, and you need to equip yourself with methods and
            practices because what has worked for you in the past may not
            continue to do so.
          </p>
          <p id="e533895e-bfc2-46d2-b22b-c77e8e6746b1" className="">
            For instance, if you used to rely on your memory to remember things,
            now you have six times the amount of information, tasks, birthday
            dates, 1:1 summaries, and personal information of your employees to
            remember. Can you still rely solely on your memory?
          </p>
          <p id="30c81fe9-c998-429e-a3ef-d22e4e1ef96f" className="">
            Another example is your free time. Your day used to be dedicated to
            coding, but now it is filled with meetings. How will you make
            progress on your coding tasks with the limited 30 minutes of free
            time you have each day? And how will you find time for the things
            you love in order to, well, not lose it?
          </p>
          <p id="dd593ac3-d2f0-4ef6-834a-ec0518debd82" className="">
            "Atomic Habits" proposes a simple idea: our good habits tend to be
            the ones that give us immediate positive feedback (like smiling at
            each other), while the bad ones are comfortable in the moment but
            have negative consequences that we often don't realize until it's
            too late (like eating a pizza every night). Take a moment to think
            about your habits and see if this rings true.
          </p>
          <p id="df0f56ab-0c00-4edd-97af-7c1a177162a3" className="">
            When it comes to habits, our bodies are programmed in a specific way
            (based on the release of dopamine hormone), but we can still have a
            significant impact on our habits by understanding what motivates us.
            And that is precisely what this book offers. It provides simple
            tricks and methods to help us create new, positive habits that lead
            to happier, healthier, and more meaningful lives.
          </p>
          <p id="6951ca80-6d08-442c-8f70-4cfd2a38d4a7" className="">
            The book emphasizes taking small steps and making slow progress
            until the habit becomes ingrained. As the author says, "Every action
            you take is a vote for the person you wish to become."
          </p>
          <h4 id="5d8209b1-27db-4020-89d6-e0b12378b48b" className="">
            Conclusion
          </h4>
          <p id="ef5671da-3ecf-4405-80fe-604a9929cae2" className="">
            In conclusion, continuous learning is crucial in everything we do,
            especially when starting a new role. As new managers, there is a lot
            for us to learn, so it's best to start early.
          </p>
          <p id="4fb82d7e-6bc0-4532-9a95-ff6414f1a3e0" className="">
            Books are not the only source of learning, of course. It is also
            beneficial to find a mentor or someone to tutor you, either from
            within or outside the company.
          </p>
          <p id="2276f07a-a7c6-4b03-a018-8cdf5d8be4a9" className="">
            I hope you enjoy reading these books and that they have the same
            transformative impact on your perspective as they did on mine.
          </p>
          <p id="658bda45-f573-4fcc-ac65-1ad8df981e72" className="">
            Oh, and if you come across any other good books, please let me know!
          </p>
          <p id="e4e3defa-af0d-4ff0-af6e-752870382e5c" className=""></p>
          <p id="aefa1f30-989d-417d-a902-763d65305c30" className=""></p>
        </div>
      </article>
    </div>
  );
}
