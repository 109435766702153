import React, { Component, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import Untitled from "./assets/feed/s3/Untitled.png";
import Untitled1 from "./assets/feed/s3/Untitled 1.png";
import Untitled2 from "./assets/feed/s3/Untitled 2.png";
import Untitled3 from "./assets/feed/s3/Untitled 3.png";
import Untitled4 from "./assets/feed/s3/Untitled 4.png";
import Untitled5 from "./assets/feed/s3/Untitled 5.png";
import Untitled6 from "./assets/feed/s3/Untitled 6.png";
import Untitled7 from "./assets/feed/s3/Untitled 7.png";
import Untitled8 from "./assets/feed/s3/Untitled 8.png";

export function ReactAWSS3() {
  return (
    <div>
      <article id="48acdf30-7e1d-4f11-92b4-432054673a08" className="page sans">
        <div className="page-body">
          <h4 id="6c922c4f-cd40-45dd-9960-9336cffa6a78" className>
            Intro
          </h4>
          <p id="72425625-fb39-4e23-a1b5-eb2a45916cae" className>
            It’s 2022, web frameworks have developed so much, and still,
            whenever we need to upload files from our UI to a server, we panic a
            little bit.
          </p>
          <p id="22f2e63a-6070-4ab4-8af2-507759898802" className>
            This tutorial will learn how to upload files from ReactJS in no time
            to AWS S3, using a library called react-aws-s3.
          </p>
          <p id="29e1adfa-83bb-4489-bfd8-f01d5f4bc3c1" className>
            <strong>Disclaimer</strong>: I recommend using this tutorial only
            when you are developing an internal tool, or for yourself. For
            “Real-world” systems, we usually want to monitor the uploaded files,
            block unauthorized users, etc. For that cause, we would use AWS API
            Gateway to get a signed URL. Read more about it{" "}
            <a href="https://aws.amazon.com/blogs/compute/uploading-to-amazon-s3-directly-from-a-web-or-mobile-application/">
              here
            </a>
            .
          </p>
          <h4 id="9dbb7054-c3a4-4713-9c45-8fb3c6c91e70" className>
            Requirements
          </h4>
          <p id="48542ded-60a0-40ad-bd26-ba542ed98d0f" className>
            For this tutorial, you will need -
          </p>
          <ul
            id="ffbc4a5f-44a9-46b0-b7ca-16ed4e932f00"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>An AWS account</li>
          </ul>
          <ul
            id="5d338002-c038-443d-a0d2-ceef58357fb1"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Familiarity with ReactJS and a ReactJS app
            </li>
          </ul>
          <h4 id="b69f5b09-6c94-4a9d-8f8c-5bc34f5bb32a" className>
            Before we start
          </h4>
          <p id="ff7a864b-c786-4d7d-9b09-fa9b326d07d7" className>
            First, let’s answer the questions that keep our minds busy.
          </p>
          <p id="1feb3d29-f873-418a-8b66-e87e9dc7a05e" className>
            <span style={{ borderBottom: "0.05em solid" }}>
              When should I upload the file?
            </span>{" "}
            immediately after selection, or when submitting the form?
            <mark className="highlight-gray"> </mark>In my opinion, when
            selecting the file in the files browser and clicking “open”. This
            allows us to display validation errors before the form is sent,
            therefore giving a better user experience, compared to the other
            option where the user gets validation errors when they are sure they
            finished filling the form.
          </p>
          <p id="e89d7e41-91dc-4c88-ad52-06d00dea9ad8" className>
            <span style={{ borderBottom: "0.05em solid" }}>
              Where do I store the file?{" "}
            </span>{" "}
            The natural thought is “in the DB, with my other stored stuff”. The
            rule of thumb says that filed under 256K can be stored in DB,
            whether as a b64 encoded string, or using blob options of the DB.
            However, it is considered a bad practice for heavier files because
            of performance issues (slower queries and more RAM consumed by the
            DB) and cost issues. In addition, most DBs limit the size of each
            record, therefore limiting file size. for example, MongoDB limits
            document size to 16MB, leaving us no choice but to store the file
            elsewhere, if they’re bigger than that.
          </p>
          <p id="d5fe2956-0d73-47f1-90b4-6aedf5772406" className>
            <span style={{ borderBottom: "0.05em solid" }}>
              How do I send an HTTP request with a file, for god’s sake?!?
            </span>{" "}
            Looks like it even needs a special content type and stuff! By
            uploading the file to a cloud storage service. We all know some
            storage services (Google Drive, Dropbox, Box) but I chose to use S3
            of AWS thanks to the many SDKs it has for every platform ever (Hi
            Boto3!), plus it is considered relatively cheap (
            <a href="https://aws.amazon.com/free/">
              check out their free tier plan
            </a>
            ).{" "}
          </p>
          <p id="5d4994e0-4577-480b-9ba1-2342de1e82db" className>
            Naturally, there is already a library in ReactJS for interacting
            with S3, called{" "}
            <a href="https://github.com/Developer-Amit/react-aws-s3">
              https://github.com/Developer-Amit/react-aws-s3
            </a>
            , so we don’t have to be aware of sending the request itself.
          </p>
          <p id="02128f71-4c2b-4790-91d4-324cee91981e" className></p>
          <h4 id="7b771136-7a27-4164-b997-43ca92168603" className>
            1. Create an AWS S3 bucket
          </h4>
          <p id="c5d650dc-c094-4c59-8cc8-d989507193f5" className>
            In S3, files are called objects and are divided into buckets, each
            bucket has its own URL.
          </p>
          <p id="c5000730-664c-4104-a120-643efa787ce7" className>
            In the AWS management console, go to S3
          </p>
          <p id="ba1811f1-a5b1-43f6-99c1-0465a03b3711" className>
            Click on “Create bucket”, and fill in the details of your bucket:
          </p>
          <img src={Untitled1} style={{ width: "100%" }} />
          <p id="1a1c3e2f-1e70-4c9d-acc4-15ea652f5ad9" className>
            In Object Ownership, select “ACLs enabled”.
          </p>
          <p id="862c9d0c-8c45-4315-a956-8ae1c756fe98" className>
            Check or uncheck the “Block all public access” according to whether
            your files can be public or not (in my example they can be public)
          </p>
          <img src={Untitled3} style={{ width: "100%" }} />
          <p id="acbc94b4-50cd-4960-8fb7-93c8eb6b8932" className>
            Leave other settings as they are, and click “Create bucket”.
          </p>
          <p id="bd840e49-ea2b-43cc-bfa1-f9a097518f11" className>
            Now, navigate to your new bucket, go to “Permissions”, and edit
            “Cross-origin resource sharing (CORS)”. This is important in order
            for the ReactJS app to access the bucket.
          </p>
          <p id="c757391e-b2ba-4c85-8404-3e2d2986b127" className>
            Paste these settings, where <code>AllowedOrigins</code> should
            contain only your actual domain, we will keep “*” just while
            developing.{" "}
          </p>
          <pre id="ccbf8139-679a-42bb-80cd-a1384b7d3ff1" className="code">
            <code>
              [{"\n"}
              {"    "}
              {"{"}
              {"\n"}
              {"        "}"AllowedHeaders": [{"\n"}
              {"            "}"*"{"\n"}
              {"        "}],{"\n"}
              {"        "}"AllowedMethods": [{"\n"}
              {"            "}"GET",{"\n"}
              {"            "}"POST",{"\n"}
              {"            "}"PUT",{"\n"}
              {"            "}"HEAD"{"\n"}
              {"        "}],{"\n"}
              {"        "}"AllowedOrigins": [{"\n"}
              {"            "}"*"{"\n"}
              {"        "}],{"\n"}
              {"        "}"ExposeHeaders": [{"\n"}
              {"            "}"ETag",{"\n"}
              {"            "}"Accept-Ranges",{"\n"}
              {"            "}"Content-Encoding",{"\n"}
              {"            "}"Content-Length ",{"\n"}
              {"            "}"Content-Range"{"\n"}
              {"        "}],{"\n"}
              {"        "}"MaxAgeSeconds": 3000{"\n"}
              {"    "}
              {"}"}
              {"\n"}]
            </code>
          </pre>
          <p id="4c6bce1d-af9c-4783-9639-d6c0a4d4c025" className>
            click Save.
          </p>
          <img src={Untitled4} style={{ width: "100%" }} />
          <p id="48700c9d-64a0-4811-9b98-1a44763231cb" className>
            Our bucket is now ready to use.
          </p>
          <h4 id="612640f6-6366-4839-9664-ca821d817a79" className>
            2. Create an AWS API key
          </h4>
          <p id="3a4b44af-3112-4167-bd90-7ecb14f2f48d" className>
            In the top-right corner, click on your name, and then on “Security
            Credentials”. Then choose “Create New Access Key”.
          </p>
          <img src={Untitled5} style={{ width: "100%" }} />
          <img src={Untitled6} style={{ width: "100%" }} />
          <p id="fd85bd75-af67-4c08-acf5-ef4a75f08771" className>
            Make sure to save the Access Key ID and the Secret Access Key in a
            safe place. Preferably, in a place where your app can use them, for
            example Kubernetes Secret, Github Secret, Heroku Config - depends on
            your CD environment.
          </p>
          <p id="8e45abf0-ad57-4072-8a36-a7822030159e" className>
            Then in my app, I will store their values in Heroku. I will use the
            Access Key ID and the Secret Access Key as{" "}
            <code>process.env.S3_KEY</code> and{" "}
            <code>process.env.S3_SECRET</code> .{" "}
          </p>
          <h4 id="7b82eead-1010-47f2-ac4d-99a2f09461ab" className>
            3. Upload a file from ReactJS
          </h4>
          <p id="31b24e4e-3a8d-4a6c-b47c-302b500f4ef2" className>
            The moment we’ve all been waiting for!{" "}
          </p>
          <p id="96d64a48-e623-496d-99ea-aab62e114f6f" className>
            Let’s install react-aws-s3, by running
          </p>
          <pre id="5e7fa6e2-b483-4eb8-bdcb-c57f3d37197e" className="code">
            <code>
              npm install --save react-aws-s3{"\n"}npm install --save buffer
            </code>
          </pre>
          <p id="5167d1e8-f305-4c42-b6db-05b6de77e7d9" className>
            Let’s add a component called <code>FileUploader</code>. This
            component contains a file input and a state for holding the file
            path and file name.
          </p>
          <pre id="260997d3-c80e-442b-bb6c-8b4ba36084a9" className="code">
            <code>
              function FileUploader() {"{"}
              {"\n"}
              {"\t"}
              {"\t"}const [picName, setPicName] = useState(null);{"\n"}
              {"    "}const [picPath, setPicPath] = useState(null);{"\n"}
              {"    "}const [errors, setErrors] = useState(null);{"\n"}
              {"\n"}
              {"    "}const upload = (file) =&gt; {"{"}
              {"\n"}
              {"        "}// I do nothing!{"\n"}
              {"    "}
              {"}"}
              {"\n"}
              {"\n"}
              {"    "}return ({"\n"}
              {"        "}&lt;div&gt;{"\n"}
              {"            "}&lt;input type="file" onChange={"{"}(e) =&gt;
              upload(e.target.files[0]){"}"}&gt;&lt;/input&gt;{"\n"}
              {"            "}&lt;div&gt;File name: {"{"}picName{"}"}
              &lt;/div&gt;{"\n"}
              {"            "}&lt;div&gt;File path: {"{"}picPath{"}"}
              &lt;/div&gt;{"\n"}
              {"            "}&lt;div&gt;Errors: {"{"}errors{"}"}&lt;/div&gt;
              {"\n"}
              {"        "}&lt;/div&gt;{"\n"}
              {"    "});{"\n"}
              {"}"}
              {"\n"}
              {"\n"}export default FileUploader;
            </code>
          </pre>
          <p id="5d680a03-9a11-4ef0-b562-8b803f6da01a" className>
            Currently, nothing happens when we select a file:
          </p>
          <img src={Untitled7} />
          <p id="1db5ce7c-bb2e-45cf-8a45-233c59623218" className>
            The next step is to add the S3 configuration and initialize the S3
            client:
          </p>
          <pre id="4d302c25-6342-4fd7-bc56-9046605e98d0" className="code">
            <code>
              import S3 from 'react-aws-s3';{"\n"}import {"{"} Buffer {"}"} from
              'buffer'; // This is due to a bug in the library itself{"\n"}
              window.Buffer = Buffer{"\n"}
              {"\n"}const config = {"{"}
              {"\n"}
              {"    "}bucketName: 'good-forest-static',{"\n"}
              {"    "}dirName: 'imgs',{"\n"}
              {"    "}region: 'us-east-2',{"\n"}
              {"    "}accessKeyId: process.env.S3_KEY || '',{"\n"}
              {"    "}secretAccessKey: process.env.S3_SECRET || ''{"\n"}
              {"}"}
              {"\n"}
              {"\n"}const ReactS3Client = new S3(config);
            </code>
          </pre>
          <p id="d1e31932-b27b-4354-a0fe-48a065d863fe" className>
            Add a callback for file uploading - notice you can use the result
            file after it is already stored and have an absolute path.
          </p>
          <pre id="98983ab8-0cbd-49f7-9cd2-d814763c0751" className="code">
            <code>
              const upload = async (file) =&gt; {"{"}
              {"\n"}
              {"    "}// here you can get file.name and file.size to add some
              validations{"\n"}
              {"    "}setPicName(file.name){"\n"}
              {"\n"}
              {"    "}try {"{"}
              {"\n"}
              {"        "}var result = await ReactS3Client.uploadFile(file);
              {"\n"}
              {"        "}setPicPath(result.location){"\n"}
              {"    "}
              {"}"} catch (error) {"{"}
              {"\n"}
              {"        "}setErrors(error.message){"\n"}
              {"    "}
              {"}"}
              {"\n"}
              {"}"}
            </code>
          </pre>
          <p id="bd547365-425f-401f-aac9-4b65cd0a3823" className>
            The next step is... oh wait, there is no next step. That simple.
          </p>
          <img src={Untitled8} />
          <p id="2781b50d-5074-44af-965d-812679ba9256" className>
            Now, you can store in DB only the path to the file instead of the
            file itself.
          </p>
          <h4 id="78dd25ec-47ed-4dce-afac-733ff15ab628" className>
            Summary
          </h4>
          <p id="d3b8c60e-2d42-4d76-a6c1-173509a2b44b" className>
            We learned how to easily upload files from ReactJS to our cloud
            storage.
          </p>
          <p id="52802aa1-953c-490c-bee5-b9751724e74a" className>
            Happy uploading!
          </p>
          <p id="ba26f7d5-cfd1-4097-abb7-f8d539e58e0b" className></p>
          <p id="76916161-b002-4757-a3a6-dbc107ade162" className>
            The full example can be viewed here:
          </p>
          <p id="a4d633ec-3f81-42cd-89ba-74538ef21c40" className>
            <a href="https://github.com/omers4/react-aws-s3-example">
              https://github.com/omers4/react-aws-s3-example
            </a>
          </p>
          <p id="275fbfc9-9332-477c-b566-78a7ac45ba63" className></p>
        </div>
      </article>
    </div>
  );
}
