import React, { Component, useState } from "react";
import { Navbar, Container, Nav, Badge, Button, Modal } from "react-bootstrap";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore/lite";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const firebaseConfig = {
  apiKey: "AIzaSyB6aFZN-H3PUy_udLQchME7zy59b2-O9ko",
  authDomain: "omer-portfolio.firebaseapp.com",
  projectId: "omer-portfolio",
  storageBucket: "omer-portfolio.appspot.com",
  messagingSenderId: "311691513689",
  appId: "1:311691513689:web:e20625be9567bf27b50648",
};

const fbApp = initializeApp(firebaseConfig);
export const auth = getAuth(fbApp);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(fbApp);
