import React, { Component, useState } from "react";
import PamGif from "./assets/pam.gif";
export default function Scrum() {
  return (
    <div>
      <article id="7981b58e-fa3e-4e1d-a906-1635222d15d1" className="page sans">
        <div className="page-body">
          <p id="2313fe6c-963c-46fd-a690-06cc27821f71" className="">
            When I was a developer, I remember watching my team lead running a
            planning or a retro meeting and thinking “that actually doesn’t seem
            this difficult - we just move Jira tickets from one place to
            another”. But when you sit in their chair, you understand that
            managing these meetings is a whole new world.
          </p>
          <p id="ca070b6b-2da9-40a4-ac2b-0566c078e9d8" className="">
            Suddenly, there are so many aspects to consider and you’re
            constantly trying to balance between business, people, and
            infrastructure: you juggle between contributing to the business
            needs, fulfilling everyone’s ambitions and abilities, and building a
            good product that is robust and easy to extend.
          </p>
          <p id="9c6e74e4-892b-492d-919d-4cc2fb810801" className="">
            I collected some of the lessons I learned along the way, and hope it
            will help you too:
          </p>
          <ol
            type={1}
            id="16a1663b-3c6b-41d0-bac4-81bf5e09286e"
            className="numbered-list"
            start={1}
          >
            <li>
              <strong>XL features are the devil</strong>
            </li>
          </ol>
          <ol
            type={1}
            id="c10d68df-b0e0-4a0a-8c28-1a97248dc4ee"
            className="numbered-list"
            start={2}
          >
            <li>
              <strong>
                There’s never a good time for tech debt in the sprint, nor is
                there for bug fixes
              </strong>
            </li>
          </ol>
          <ol
            type={1}
            id="a42d26d7-397a-4a63-a208-211a3b647d9d"
            className="numbered-list"
            start={3}
          >
            <li>
              <strong>
                Distributing feature expertise is the best thing you can do, but
                also one of the hardest things to achieve
              </strong>
            </li>
          </ol>
          <ol
            type={1}
            id="8efd328e-2924-4016-9c65-00c85e8d42c9"
            className="numbered-list"
            start={4}
          >
            <li>
              <strong>
                Retrospectives are useless unless you keep getting back to them
              </strong>
            </li>
          </ol>
          <ol
            type={1}
            id="965ffc9d-5eb9-45ec-ab28-cc14001ebc89"
            className="numbered-list"
            start={5}
          >
            <li>
              <strong>
                Ceremonies are the anchor of each developer’s day - keep them
                fun, short, and stable
              </strong>
            </li>
          </ol>
          <p id="32b9e126-0f41-4e60-8850-f54bacc25717" className=""></p>
          <div style={{ textAlign: "center", margin: 30 }}>
            <a href="https://tenor.com/view/pam-beesly-conference-room-everybody-now-conference-room-meeting-jenna-fischer-gif-19670212">
              <img src={PamGif} />
            </a>
          </div>
          <h5 id="7749ddba-3358-4734-9cb3-e5113e811fbb" className="">
            1. <strong>XL features are the devil</strong>
          </h5>
          <p id="eecae012-50f7-477a-9936-fc91590a04b3" className="">
            XL features (features that take more than 1 sprint to complete) can
            look very attractive at a first glance: they can give a lot of
            business value at once, and are most likely to be challenging and
            interesting for the developers.
          </p>
          <p id="6764f22d-5ebd-4c65-a0e6-b2ff082a5ded" className="">
            But, as the title may imply, <strong>they are the devil</strong>.
            And why is that?
          </p>
          <p id="4a9efb6c-441e-4a08-8c09-ccb8cf90ba74" className="">
            First of all, it is hard to estimate them, which makes them quickly
            get out of control, and generally hard to manage. Such features
            usually consist of multiple technical tasks, each one having its own
            estimation. While it’s very natural to under-estimate the
            development of one task, when it comes to big features,{" "}
            <strong>the total estimation errors can sum up </strong>so the
            feature will take much more than we thought (XL → XXL!)
          </p>
          <p id="bef747f5-421c-472e-8e3b-f4e5c237b003" className="">
            It is also not very agile to develop a whole feature without the
            crucial part of the customer feedback loop.
          </p>
          <p id="f3638407-d196-4c7a-b87d-93781c91eaad" className="">
            In addition, the developer actually feels frustrated that they don’t
            get their feature released, and they don’t see the end of it coming.
          </p>
          <p id="3a80cf00-83b2-474b-9fc1-402064224e94" className="">
            As long as a developer is assigned to an XL task, you don’t want to
            interrupt them with small tasks that will cause them to
            context-switch. However, <strong>you have to </strong>have the
            ability to do so, in order to fix urgent bugs, for quick wins, etc.
          </p>
          <p id="ece1ac48-04ad-489f-a005-bff1b96fcac4" className="">
            So what can we do? Insist to{" "}
            <strong>divide the XL features into distinct phases </strong>of 1-2
            sprints at most, and release the feature at the end of each phase.
            This way, the feature will be easy to digest, even if this division
            is only psychological.
          </p>
          <p id="f288dfcd-b757-44c3-9932-7cde20afb139" className=""></p>
          <h5 id="53e46728-eb6a-4a66-a1e5-cab7fcf7bc4f" className="">
            2.{" "}
            <strong>
              There’s never a good time for tech debt in the sprint, nor is
              there for bug fixes
            </strong>
          </h5>
          <p id="520c017c-c7cb-4110-b044-27afc570da5c" className="">
            I’ve noticed that unless you decide that x% of the sprint goes to
            tech debt issues and y% goes to bug fixes, they just don’t make it
            into the sprint, making them pile up for weeks in the backlog, and
            we don’t want that. Especially in start-ups, there are always urgent
            tasks that can help with the actual business, and impact clients
            (and that’s a great thing!).{" "}
          </p>
          <p id="83670d2a-597f-4740-b252-3e8370e5b5f2" className="">
            Therefore, you have to insist to{" "}
            <strong>
              have a prefixed amount for these so important tasks{" "}
            </strong>
            in the sprint, even if a small amount. (for example, 25% a sprint
            for bug fixes, 10% for infrastructure and tech debts)
          </p>
          <p id="4af6443e-6858-41af-a592-aa1265a729c5" className=""></p>
          <h5 id="8f387590-c559-402f-b916-ff48cd8200f4" className="">
            3.{" "}
            <strong>
              Distributing feature expertise is the best thing you can do, but
              also one of the hardest things to achieve
            </strong>
          </h5>
          <p id="1d49bfca-61bf-44d2-b20d-fd73d4165bfd" className="">
            I noticed that the natural state that agile teams end up being in is
            that each developer is the complete authority for some features. It
            means that for every question one has, one will ask that developer.
            Each bug or feature request will automatically be redirected to that
            person etc.
          </p>
          <p id="531b7a29-4207-4d99-ba25-1a361dee32c1" className="">
            While it sometimes makes it easier to manage the tasks this way, and
            it helps to create motivation and ownership feeling within the
            developers,{" "}
            <strong>we must have at least 2-3 additional experts </strong>in
            each feature, unless you are also putting impossible pressure on the
            developer.
          </p>
          <p id="773672a5-e809-4594-a226-a4006e23deaa" className="">
            It especially tends to happen if you have the “Feature owner” role
            in your team and increase in a full-stack team, where every
            developer comes with a little different background and professional
            experience (One is a backend only, the other never run SQL in their
            lives, and the other is 50-50).
          </p>
          <p id="1fbfddfc-726a-4799-8094-33c77d4eef35" className="">
            So what I suggest to you is (I do the same) to make a table of all
            the big features in your team. Near each feature, write in one
            column the name of the owner, and in the other column, who else
            knows this feature? Your goal is to fill that table. Next time tasks
            arrive, think twice before you auto-forward it to the dev who knows
            it the most.
          </p>
          <p id="938e0ade-98a1-4519-b60c-412f7c98e0bd" className="">
            <span style={{ borderBottom: "0.05em solid" }}>For example-</span>
          </p>
          <table
            id="1f6a9e7c-8bc1-4d6e-9300-67226e3bf014"
            className="simple-table"
          >
            <tbody>
              <tr id="40fb2cae-da61-4f25-aedd-96a63e09489f">
                <td id="PrAY" className="">
                  Feature
                </td>
                <td id=">MhK" className="">
                  Owner
                </td>
                <td id="bKpl" className="">
                  Who else knows?
                </td>
              </tr>
              <tr id="07fe4949-3081-404a-a1bb-e7988dfd1283">
                <td id="PrAY" className="">
                  Alerts component
                </td>
                <td id=">MhK" className="">
                  Dwight
                </td>
                <td id="bKpl" className="">
                  Jim, Andy
                </td>
              </tr>
              <tr id="711c257f-f24d-4489-a653-756aec81e4c5">
                <td id="PrAY" className="">
                  Login service
                </td>
                <td id=">MhK" className="">
                  Jim
                </td>
                <td id="bKpl" className="">
                  -{" "}
                </td>
              </tr>
              <tr id="75e6a31c-14fb-4993-8d5f-5e14635ce685">
                <td id="PrAY" className="">
                  Web scraper
                </td>
                <td id=">MhK" className="">
                  Phillis
                </td>
                <td id="bKpl" className="">
                  Bob
                </td>
              </tr>
            </tbody>
          </table>
          <p id="1622349f-5ef9-4e7a-bfb2-eb2f95e4c365" className="">
            It’s hard, but I believe it creates healthier teams.
          </p>
          <p id="f97f480c-17eb-4792-a799-d17c2bcf3b27" className=""></p>
          <h5 id="cd0db69f-e660-455b-a2e2-17e3ceb1e871" className="">
            4.{" "}
            <strong>
              Retrospectives are useless unless you keep getting back to them
            </strong>
          </h5>
          <p id="d09e708b-a2a7-47ce-ba53-84c63e4c1451" className="">
            Next time you do a retrospective, just before you start taking
            notes, go over the previous retro notes with the team. What things
            have you already improved from this time? What things still need
            some more attention this week?{" "}
          </p>
          <p id="4a17d349-24ee-40c2-be4e-d6079efac086" className="">
            While taking notes of this sprint, always remember the past, and
            reflect that to the team: “It’s good that this sprint we did X well,
            because we brought up this subject in the retros a few times
            before”. This helps to motivate the team as they see the progress
            they make every time. Don’t rely on your memory, use written notes!
            (This is true for everything in life, regardless).
          </p>
          <p id="3a4067d9-7020-48ae-b780-a2be275181af" className=""></p>
          <h5 id="67a338de-859b-4b1d-8a9d-b9689ea39c7d" className="">
            5.{" "}
            <strong>
              Ceremonies are the anchor of each developer’s day - keep them fun,
              short, and stable
            </strong>
          </h5>
          <p id="9247feb0-e4cc-4c99-8b83-23f5e5ffd845" className="">
            In the unstable and fast environment of Agile, it’s good to have
            constant events in our calendar. they give us security and are the
            anchors of our day.
          </p>
          <p id="8af64182-012b-4f10-ae36-58da6331bdbe" className="">
            Remember to keep it light and fun (as much as possible), and most
            importantly - make them short (especially the dailies).
          </p>
          <p id="0f60951f-cdf5-4b40-9541-f51e1bfce776" className=""></p>
          <h5 id="d9a2684d-3f0d-4187-9bdc-2e8b00466f78" className="">
            <strong>
              And a bonus one: make sure everyone understands the purpose of the
              Scrum Ceremonies.
            </strong>
          </h5>
          <p id="6ddf37a4-8e77-41e2-a293-2fed5267936c" className="">
            It’s easy to forget why it is so crucial to have a daily and what
            it’s purpose. Have a session with the team explaining the target of
            each occurrence, what you expect from it and how is it helpful. Who
            knows, maybe you are growing the next scrum master right beneath
            your eyes.
          </p>
          <p id="6f883f04-87b5-4050-8eca-a6a33c7f43de" className=""></p>
        </div>
      </article>
    </div>
  );
}
