import React, { Component, useState } from "react";
export default function CleanCode() {
  return (
    <div>
      <article id="f269caea-fa8b-4ce5-b09f-4b9fb1b12382" className="page sans">
        <div className="page-body">
          <p id="75876af3-5ef1-43f6-b17f-4afda6576a27" className=""></p>
          <h4 id="922a0c52-2036-4bc5-9eb8-3d067c523ad4" className="">
            Seriously? Another Clean Code article?
          </h4>
          <figure id="88dca7bd-4a4d-4305-a843-a3fe9105d7a5" className="image">
            <iframe
              src="https://giphy.com/embed/hyyV7pnbE0FqLNBAzs"
              width="480"
              height="400"
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </figure>
          <p id="15f845d3-a37b-4e0a-a887-c26777388d94" className="">
            Well, yes and no.
          </p>
          <p id="0bae8466-ca15-4762-ab8e-e5107e468cb6" className="">
            There are some Clean Code principles that we all know by heart. In
            every code review, we take pride in comments like "reuse that code",
            "give a better name to that variable", and "split this function into
            two".
          </p>
          <p id="5291e2f8-1278-4330-8f8f-83cff5077683" className="">
            However, knowing these principles doesn't guarantee we write good
            code.
          </p>
          <p id="891075bb-d6e4-4bd6-aea7-9b4e2a37a6b4" className="">
            Often, especially in junior developers' code (but not exclusively),
            some Clean Code principles are overlooked and forgotten. These are
            the principles that are more subtle and may be harder to spot in a
            code review or in your own code, but they are the ones that can make
            your code truly good.
          </p>
          <p id="a20f7c8f-15c3-414d-80ff-ae9446873010" className="">
            While today's development tools, such as linters and Resharper, make
            it easier to detect "code smells" (although these are still
            important to understand), most Clean Code articles tend to focus on
            these principles.
          </p>
          <p id="6b5c55c2-3ac6-4b80-9c05-463a0cf2e5da" className="">
            Therefore, this article focuses on the Clean Code principles that
            cannot be automatically detected by a linter or Resharper. These
            principles are crucial for writing truly good code.
          </p>
          <h4 id="b342fb6f-880c-404c-90dd-55448a8ec3a2" className="">
            First things first, what is clean code and why do I need it?
          </h4>
          <p id="6dfc4c6c-5ebf-4499-8a6d-2ef611700a02" className="">
            Clean code is a philosophy that focuses on writing code that is easy
            to read, understand, and maintain.
          </p>
          <p id="f094bf1b-88d7-4e02-b7d4-08f9055f2245" className="">
            It is a set of coding style guidelines that are widely popular
            within the development community. The philosophy is based on the
            idea that not only the computer should be able to understand your
            code, but also those who will need to read and modify it in the
            future. Additionally, the goal is to create more robust code that
            functions more effectively.
          </p>
          <h4 id="4d84be61-5302-426d-bf5b-172ff30cd495" className="">
            The principles
          </h4>
          <h5 id="dd1c9892-c419-46e9-bb42-5b2890b4e005" className="">
            Always try to explain yourself in code
          </h5>
          <p id="028a0b3c-4b80-4933-9aac-068aa8b11c06" className="">
            I'm not sure why, but when I studied software development, it was
            somewhat trendy to document everything and was considered a good
            practice. Every method, every variable had to be documented.
          </p>
          <pre id="b4735b1b-16a9-49bf-a3b3-b9dd5133e805" className="code">
            <code>
              // This function makes the sound of a woof{"\n"}function Bark(Dog
              dog) {"{"}
              {"\n"}
              {"    "}// dog is a Dog object{"\n"}
              {"    "}print("woof"){"\n"}
              {"}"}
            </code>
          </pre>
          <p id="c3cb7a8b-c60e-46df-9324-92e0b91584d9" className="">
            It took me years to understand that good code explains itself with
            clear variable and function names, making comments unnecessary.
          </p>
          <p id="a29370bb-5e07-4083-b919-a3d46214c3a4" className="">
            Why is that?
          </p>
          <p id="9e4a2955-17e3-4ce6-a2fb-27550890e602" className="">
            First, comments make it easy to write unclear code. You rely on your
            English skills to emphasize what you meant to code, so whoever reads
            your code has to read the comments first, making every interaction
            with the code time-consuming.
          </p>
          <p id="c98a89ac-1dfd-4eac-a802-6b9cffd450f5" className="">
            In addition, comments tend to become stale as the code changes over
            time, but we don't always remember to update the documentation.
          </p>
          <p id="9b9f7429-f757-4629-8439-5f0b98878bec" className="">
            If that's not enough, comments also take up a lot of vertical space
            in our code, which is already limited.
          </p>
          <p id="d0cbe4e9-1273-43ae-9ff4-f45da1d2e68e" className="">
            Of course, there are some exceptions. If there's a very unique
            functionality, an unusual solution to a problem, or an unexpected
            result, it's better to explain it than to leave your reader
            wondering. In general, you could say documents are good to explain
            “why”s, not “what”s. e.g, why we chose a certain data structure and
            not what the function does.
          </p>
          <pre id="28a81146-1cf6-412d-b4fa-a5fa43c7200d" className="code">
            <code>
              // this is calculating the space between the container to the end
              of the screen{"\n"}
              {"\n"}// This is a temporal solution until gets fixed next sprint
              - ticket OS-12345{"\n"}
              {"\n"}// If you remove the next line, the world will collapse,
              because x, y, z
            </code>
          </pre>
          <p id="f009a5f7-9cd3-4c1c-a1b7-c68c37fdc7ac" className="">
            Additionally, if we are discussing generated documentation, for
            example for public API, comments are necessary.
          </p>
          <p id="a8f7ba88-9896-4df3-b217-c713533cb05a" className=""></p>
          <h5 id="0bb93e53-5106-4d2f-bbe9-5031963768be" className="">
            Prefer polymorphism over conditionals: if, else, switch
          </h5>
          <p id="f9d34b53-7242-492f-b415-56ae4468459b" className="">
            If you encounter a situation where you need to act differently{" "}
            <strong>multiple times</strong> based on the value of a variable,
            such as in a Zoo app where you need to treat each animal
            differently, you may be tempted to handle it quickly and easily-
          </p>
          <pre id="f136e509-f099-49a9-a035-43309a3142c2" className="code">
            <code>
              getAnimalFood(string animal) {"{"}
              {"\n"}
              {"   "}animal == 'dog' ? 'dogfood' : 'fish'{"\n"}
              {"}"}
              {"\n"}
              {"\n"}getAnimalSound(string animal) {"{"}
              {"\n"}
              {"   "}animal == 'dog' ? 'ruf ruf' : 'meee'{"\n"}
              {"}"}
            </code>
          </pre>
          <p id="9810e368-3adf-4df2-8a0c-bf1c6cd04339" className="">
            One issue with this approach is that every time we add a new animal
            to the system, we will need to change <strong>all </strong>of these
            functions. This means we are not reusing the{" "}
            <code>"animal == 'dog'"</code> condition, which makes it difficult
            to maintain.
          </p>
          <p id="69593dd2-8431-4970-9e62-1b6c26062487" className="">
            To solve this problem, we can apply the "Tell, don't ask" rule.
            Instead of asking an object about its state (e.g. "are you a dog?"),
            we tell it what to do (e.g. "give me your food").
          </p>
          <p id="3d43136c-0721-4c61-bcff-959bb45d4f89" className="">
            To implement this, we can use a factory and interfaces.
          </p>
          <pre id="75492641-52f7-4c19-9edc-42eb5d8b6a39" className="code">
            <code>
              interface IAnimal {"{"}
              {"\n"}
              {"   "}string getAnimalFood(){"\n"}
              {"   "}string getAnimalSound(){"\n"}
              {"}"}
              {"\n"}
              {"\n"}class Dog : IAnimal {"{"}...{"}"}
              {"\n"}class Cat : IAnimal {"{"}...{"}"}
              {"\n"}
              {"\n"}getAnimalFood(IAnimal animal) {"{"}
              {"\n"}
              {"   "}animal.getAnimalFood(){"\n"}
              {"}"}
              {"\n"}
              {"\n"}getAnimalSound(string animal) {"{"}
              {"\n"}
              {"   "}animal.getAnimalSound(){"\n"}
              {"}"}
              {"\n"}
              {"\n"}class AnimalFactory() {"{"}
              {"\n"}
              {"  "}getAnimalByName(string name) {"{"}
              {"\n"}
              {"\t"}
              {"    "}name == 'dog' ? new Dog() : new Cat(){"\n"}
              {"   "}
              {"}"}
              {"   "}
              {"\n"}
              {"}"}
            </code>
          </pre>
          <p id="0c1de41f-2daf-4cae-915f-f59d6d61d59c" className="">
            Notice that even if we still have one condition in the factory, we
            will only need to change it <strong>once </strong>for every new
            animal!
          </p>
          <p id="67efb7eb-2e97-44d3-87a4-ad4c506ad0a4" className=""></p>
          <h5 id="7f937c47-a075-436b-8986-e8d6d6472239" className="">
            Base class should know nothing about their derivatives, A.K.A
            Principle of Least Knowledge
          </h5>
          <p id="4c72a15d-eb22-4347-acf5-09c2440eacd7" className="">
            This is one of the most common mistakes made by junior coders.
          </p>
          <p id="03849f78-2645-4e35-bc6e-ff5d3de51d2d" className="">
            Consider a variation of the previous code. We now have a React
            component that represents an animal. Although it's not discussing
            inheritance, the concept is similar.
          </p>
          <pre id="3c0ea4ea-a7e8-40d8-b70c-6651f93b3f19" className="code">
            <code>
              function Animal({"{"} name {"}"}) {"{"}
              {"\n"}
              {"\t"}const sound = name === 'dog' ? 'Woof': 'Unknown'{"\n"}
              {"\n"}
              {"  "}return &lt;button&gt;Click me to hear {"{"}sound{"}"}
              !&lt;/button&gt;{"\n"}
              {"}"}
              {"\n"}
              {"\n"}...{"\n"}&lt;Animal name='dog' /&gt;
            </code>
          </pre>
          <p id="a94b3437-6e3c-4d21-a871-59a6362844dc" className="">
            This pattern is one of many that creates <strong>coupling </strong>
            between modules. In this case, the implementation of the{" "}
            <code>Animal </code>
            component is coupled to the implementation of <code>Dog</code>. That
            means if we change <code>Dog</code>'s implementation or add a new
            animal, we will always have to keep Animal's implementation in mind.
          </p>
          <p id="6e226188-5f86-4681-b53d-819058c1adc9" className="">
            With just two modules, it's not that bad, but as the number of
            modules increases, it becomes difficult to remember what is coupled
            with what, making it error-prone.
          </p>
          <p id="bce6b303-f2b6-4f63-82fa-96478d006368" className="">
            Moreover, it's harder to test the code like this because it doesn't
            have a <strong>single responsibility</strong>. If we want to test
            the <code>Animal</code> component, we don't want to guess what name
            to give it in order to render the correct sound. We don't want to
            test its ability to determine the sound from an animal; we just want
            to ensure it's rendered correctly based on the props it's given.
          </p>
          <p id="dfe1ae8e-676e-4b7e-8f8d-fc7819cfe0f7" className="">
            Therefore, it's better to tell the object what to do instead of
            asking about its state.
          </p>
          <pre id="3e02982f-8736-4cc3-983d-c7180b9e2253" className="code">
            <code>
              function Animal({"{"} name, sound = 'unknown' {"}"}) {"{"}
              {"\n"}
              {"  "}return &lt;button&gt;Click me to hear {"{"}sound{"}"}
              !&lt;/button&gt;{"\n"}
              {"}"}
              {"\n"}
              {"\n"}...{"\n"}&lt;Animal name='dog' sound='woof' /&gt;
            </code>
          </pre>
          <p id="ac59b5c3-2a36-4c25-97ec-d32be7bc8eea" className="">
            So much cleaner, right?
          </p>
          <p id="a5ae3092-8b4b-49b7-8ce1-10a6fdcca119" className="">
            And whenever a new animal is added, we won’t need to add code in the{" "}
            <code>Animal </code>component itself.
          </p>
          <p id="0eb32433-6078-42d3-af60-cc0149dec26a" className=""></p>
          <h5 id="8cee8429-7917-4c96-8823-99550d6ccf5b" className="">
            Bonus: Always look for the root cause of a problem
            <strong />
          </h5>
          <p id="0d2c025f-ec0f-43e7-98da-b2aea8cd630f" className="">
            This is more of an abstract concept and doesn’t really provide code
            guidelines, so there are no code examples here.
          </p>
          <p id="ee7c1b87-33a7-468a-a75c-5f4d2bf8a802" className="">
            When investigating a bug, don't just put a Band-Aid on it without
            really understanding what the root issue is. This applies both
            during the investigation process and when writing the solution.
          </p>
          <p id="6de9680f-497d-45c4-9f1c-b0791095f5ea" className="">
            Red flags that indicate you're not looking for the root cause
            include leaving comments like "I don't know why, but this solves the
            bug”, “Removing this line has unexpected results" or adding a
            too-wide try-catch to solve a problem.
          </p>
          <p id="f456c5bd-f520-440f-ba8b-ea6a27593dbf" className="">
            If you don't delve into the details of an issue, it will come back
            to haunt you like a boomerang when the issue inevitably arises
            again. So it's better to put more effort into understanding the root
            cause from the beginning and getting things right.
          </p>
          <p id="13284e2a-bbc0-4f07-838a-55e33b77c93a" className=""></p>
          <h5 id="a633917f-838c-43f6-8e6b-25f1c00bb6ab" className="">
            Conclusion
          </h5>
          <p id="7a0b09a8-1975-45b1-8c52-39583738fa94" className="">
            I hope that you have learned some new clean code principles, or have
            been reminded of them. I believe that applying these principles can
            significantly improve your coding and reviewing skills.
          </p>
          <p id="3df5978d-4d7b-4a63-8ac7-00b23e851aa8" className="">
            There are many other principles that I like, such as "else-less
            code" and the importance of dependency injection. I encourage you to
            look for more as the internet is full of information about this
            topic. I specifically recommend{" "}
            <a href="https://refactoring.guru/">https://refactoring.guru/</a>,
            which provides simple explanations and examples for every "code
            smell."
          </p>
          <p id="91a0aecf-8c9d-4bf6-8e77-43c6b747094e" className="">
            The next time you review code, don't settle for a simple "split this
            function" comment. Take the time to clean and tidy up the code. I'm
            sure it will pay off in the future, for both you and your team.
          </p>
        </div>
      </article>
    </div>
  );
}
